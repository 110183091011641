.DataLoadingError__container {
    padding: 150px 0;
    display: flex;
    align-items: center;
    font-weight: 800;
    color: #db544c;
    flex-direction: column;
    background-color: #fff;
}
.DataLoadingError__img {
    height: 247px;
    width: 103.14px;
    margin-bottom: 14px;
    object-fit: contain;
}
