.notification_list{
    list-style: none;
}
.notification_list li{
    height: 48px;
    background: #f8f8f8;
    margin-bottom: 6px;
}
.notification_list li a{
    display: block;
    height: 100%;
}
.notification_list .tag{
    background-color: #f3565d;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 49px;
    height: 48px;
    margin-right: 15px;
    float: left;

}
.notification_list li div {
    line-height: 48px;
}
.notification_list li div > span {
    display: block;
    width: 159px;
    background: #f0f0f0;
    height: 48px;
    color: #525252;
    line-height: 48px;
    text-align: center;
    font-size: 12px;
    float: right;
}
.notification-item{
    cursor: pointer;
}
.notification_list li div > .Notification__is-seen{
    display: block;
    width: 90px;
    background: #69c69c;
    height: fit-content;
    color: #fff;
    line-height: 48px;
    text-align: center;
    font-size: 12px;
    float: right;
    margin-right: 10px;
}