.active li span{
    padding: 5px 8px 5px 8px;
    background-color: #00a89b;
    border-radius: 3px;
    color: #fff;
    transition: all ease-in-out .2s;
    margin: 0 2px;
    line-height: 50px;
    font-weight: 900;
    outline: 0;
    text-decoration: none;
}

.active li span:hover{
    color: #fff;
}