.DemandDetails__comment-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    border: 1px solid #cddce0;
    border-bottom: 0;
    border-right-width: 0;
    border-left-width: 0;
    min-height: 100px;
    background-color: #02b6d5;
}
.last-comment-container {
    border-bottom: 0px;
    border-color: #cddce0;
    border-style: solid;
}
.DemandDetails__comment-col-1 {
    min-height: 100px;
    height: 100%;
    width: 30%;
    background-color: #02b6d5;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 18px;
}
.comment-avatar-container {
    height: 51px;
    width: 51px;
    border-radius: 510px;
    background-color: #025e72;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    margin-right: 12px;
}
.comment-avatar-text{
    color: #fff;
    font-size: 14px;
    font-weight: bold;
}
.comment-avatar-container img {
    height: 51px;
    width: 51px;
    border-radius: 510px;
    object-fit: contain;
}
.DemandDetails__comment-col-1 div:first-child {
    display: flex;
    align-items: center;
    margin-bottom: 4px;
}
.DemandDetails__comment-col-1:first-child strong {
    color: #fff;
    font-size: 12px;
    font-weight: bold;
}

.DemandDetails__comment-col-1 div:last-child {
    display: flex;
    align-items: center;
}

.DemandDetails__comment-col-1 div:last-child span {
    color: #fff;
    font-size: 9px;
    margin-left: 3px;
}

.DemandDetails__comment-col-2 {
    min-height: 100px;
    height: 100%;
    width: 70%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    padding-left: 18px;
    padding-right: 18px;
}
.DemandDetails__comment-col-2 p {
    font-weight: 500;
    padding-bottom: 10px;
    padding-top: 10px;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
}
.comment-text-area-content-container{
    width: 100%;
    border: 1px solid #cddce0;
    border-radius: 4px;
    position: relative;
    padding-bottom: 19px;
    margin-bottom: 20px;
}
.comment-input-options-first-part{
    display: flex;
    align-items: center;
}
.comment-input-options-second-part{
    display: flex;
    align-items: center;
}
.subscriber-text{
    color: #6f7782;
    font-size: 12px;
    margin-right: 10px;
    font-weight: 600;
}
.subscriber-avatar{
    height: 25px;
    width: 25px;
    border-radius: 250px;
    background-color: #ff78ff;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 600;
    font-size: 10px;
    margin-right: 5px;
    cursor: pointer;
}
.insubscriber-btn{
    border-width: 0;
    border-radius: 5px;
    outline: none;
    background-color: transparent;
    cursor: pointer;
    transition: .3s;
    display: flex;
    align-items: center;
    margin-right: 10px;
    padding:5px 15px
}
.insubscriber-btn:hover{
    background-color: rgba(21,27,38,.04);
}
.insubscriber-btn:hover span{
    color: #151b26;
}
.insubscriber-btn span{
    margin-left: 5px;
    color: #6f7782;
    font-size: 12px;
    font-weight: 600;
    transition: .3s;
}
.add-subscriber-btn{
    height: 25px;
    width: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-width: 0;
    border-radius: 5px;
    outline: none;
    background-color: transparent;
    cursor: pointer;
    transition: .3s;
}
.add-subscriber-btn:hover{
    background-color: rgba(21,27,38,.04);
}
.subscriber-avatar-empty{
    height: 25px;
    width: 25px;
    border-radius: 250px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 5px;
    border: dashed 1px #494949;
    cursor: pointer;
    transition: .4s;
}
.subscriber-avatar-empty:hover{
    border: solid 1px #494949;
}
.Mention__item-container{
    display: flex;
    align-items: center;
}
.users-receive-notifications{
  position: absolute;
  right: 5px;
  bottom: 5px;
  color: #6f7782;
  font-weight: 500;
  font-size: 13px;
}
.Mention__item{
    background-color: red;
    color: #fff;
}
.comment-text-area__suggestions__item__display{
    color: red !important;
    background-color: green;
}
.comment-text-area {
    /* height: 100%;
    width: 100%; */
    height: 100px;
    width: 100%;
    /* background-color: red; */
    padding-left: 7px;
    padding-top: 7px;
    padding-right: 7px;
    border-radius: 4px;
}
.comment-text-area-container {
    padding-top: 12px;
    padding-bottom: 20px;
    position: relative;
}
.comment-input-options-container{
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* background-color: red; */
    width: 100%;
}
.comment-btn {
    width: 100px;
    height: 25px;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    /* position: absolute;
    right: 18px;
    bottom: 8px; */
    color: #fff;
    font-weight: 600;
    font-size: 12px;
    border: 0;
    background-color: #ffa418;
    cursor: pointer;
}
.traceability-status-container {
    width: 100%;
    background: #fff;
    margin-bottom: 10px;
    -moz-border-radius: 2px;
    -webkit-border-radius: 2px;
    border-radius: 2px;
    -moz-box-shadow: 0 1px 3px 0 #bcbcbc;
    -webkit-box-shadow: 0 1px 3px 0 #bcbcbc;
    box-shadow: 0 1px 3px 0 #bcbcbc;
    padding: 0 5px 5px 5px;
}
.traceability-status-li {
    padding: 5px;
    cursor: pointer;
    background-color: #f6f6f6;
    display: flex;
    flex-direction: column;
    border-radius: 4px;
    margin-bottom: 3px;
}
.traceability-status-li span:first-child {
    margin-bottom: 5px;
    font-weight: 600;
}

.traceability-status-li span:last-child {
    font-size: 10px;
}

.tag-in-comment{
    color: #14aaf5;
    font-weight: 500;
}