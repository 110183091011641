.Login {
    height: 100%;
    background-color: #fff;
    position: relative;
    padding-top: 80px;
}

.Login header {
    width: 100%;
    height: 80px;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    background-color: #fff;
    -webkit-box-shadow: 0px 3px 5px 0px rgba(62, 146, 140, 0.5);
    -moz-box-shadow: 0px 3px 5px 0px rgba(62, 146, 140, 0.5);
    box-shadow: 0px 3px 5px 0px rgba(62, 146, 140, 0.5);
}

.help_lien {
    font-family: "Open Sans", sans-serif;
    font-weight: normal;
    color: #7b97aa;
    font-size: 12px;
    margin-top: 18px;
    text-decoration: none;
    margin-right: 8px;
    transition: all ease-in-out 200ms;
}
.Login__btn_green {
    border-radius: 3px;
    color: #fff;
    font-family: "gotham", "Open Sans", sans-serif;
    text-transform: uppercase;
    padding: 9px 9px 9px 41px;
    font-size: 12px;
    text-decoration: none;
    font-weight: bold;
    background-color: #1bbaaf;
    border: 2px solid #1bbaaf;
    transition: all ease-in-out 200ms;
}
.icon_support {
    display: inline-block;
    background-image: url(../../images/support_icon.png);
    background-repeat: no-repeat;
    float: left;
    width: 23px;
    height: 18px;
    position: absolute;
    margin-left: -32px;
}
.Login__container {
    margin-right: auto;
    margin-left: auto;
    padding-left: 5px;
    padding-right: 5px;
    width: 960px;
}

.header_btns {
    float: right;
    margin-top: 12px;
}
.header_btns li {
    display: inline-block;
    margin-top: 16px;
}

.logo img {
    float: left;
    margin-top: 8px !important;
}
.Login__wrapper {
    background-color: #1bbbaf;
    height: 100%;
    width: 100%;
    background-image: url(../../images/wrapper_login.png);
    background-repeat: no-repeat;
}
.Login__wrapper .Login__container {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-right: auto;
    margin-left: auto;
    padding-left: 5px;
    padding-right: 5px;
    width: 960px;
}
.Login__container .slider_bloc {
    float: left;
    display: inline-block;
    width: 45%;
    margin-top: 185px;
}
.Login__container .wrapper_right {
    float: right;
    margin-top: 185px;
    width: 50%;
}
.login_bloc {
    width: 395px;
    padding-bottom: 30px;
    border-radius: 3px;
    background-color: #fff;
    -webkit-box-shadow: 0px 3px 5px 0px rgba(62, 146, 140, 0.5);
    -moz-box-shadow: 0px 3px 5px 0px rgba(62, 146, 140, 0.5);
    box-shadow: 0px 3px 5px 0px rgba(62, 146, 140, 0.5);
    margin-top: -90px;
}
.login_bloc_header {
    height: 62px;
    background-color: #f3fdfc;
    border-bottom: 1px solid #cfd9e8;
    border-radius: 3px 3px 0px 0px;
    padding-top: 22px;
    cursor: pointer;
}
.login_bloc_header h3 {
    font-family: "Open Sans", sans-serif;
    font-weight: normal;
    font-size: 14px;
    color: #8291a9;
    text-align: center;
}

.Login__btn {
    width: 100%;
    height: 42px;
    border-radius: 3px;
    background-color: #1bbbaf;
    border: 2px solid #15a89e;
    border: none;
    color: #fff;
    font-family: "gotham", "Open Sans", sans-serif;
    font-weight: bold;
    font-size: 14px;
    text-transform: uppercase;
    transition: all ease-in-out 200ms;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    text-transform: uppercase;
}
.form_login {
    padding-left: 40px;
    padding-right: 40px;
    margin-top: 30px;
}

.Login__footer {
    width: 100%;
    height: 70px;
    background-color: #fff;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
}
.Login__footer .container {
    margin-right: auto;
    margin-left: auto;
    padding-left: 5px;
    padding-right: 5px;
    width: 960px;
}
.Login__footer .copyright {
    float: left;
    padding-top: 16px;
}

.heart_icon {
    display: inline-block;
    width: 16px;
    height: 12px;
    background-image: url(../../images/sprite_login.png);
    background-repeat: no-repeat;
    background-position: -25px 0px;
    margin-top: -36px;
    margin-left: 5px;
    margin-right: 5px;
}
.copyright p {
    font-family: "Open Sans", sans-serif;
    font-weight: normal;
    color: #7b97aa;
    font-size: 14px;
    display: inline-block;
}
.contact_footer {
    float: right;
    margin-top: 14px;
}
.contact_footer li {
    display: inline-block;
}
.icon_tel {
    display: inline-block;
    width: 26px;
    height: 24px;
    background-image: url(../../images/sprite_login.png);
    background-repeat: no-repeat;
    background-position: -45px 0px;
    margin-top: 0px;
    margin-left: 0px;
    margin-right: 5px;
}
.contact_footer p {
    font-family: "Open Sans", sans-serif;
    font-weight: normal;
    color: #7b97aa;
    font-size: 14px;
    display: inline-block;
    margin-top: 4px;
    float: right;
    margin-right: 10px;
}
.icon_msg {
    display: inline-block;
    width: 26px;
    height: 24px;
    background-image: url(../../images/sprite_login.png);
    background-repeat: no-repeat;
    background-position: -45px -24px;
    margin-top: 0px;
    margin-left: 0px;
    margin-right: 5px;
}
.icon_facebook {
    display: inline-block;
    width: 26px;
    height: 24px;
    background-image: url(../../images/sprite_login.png);
    background-repeat: no-repeat;
    background-position: -45px -49px;
    margin-top: 0px;
    margin-left: 0px;
    margin-right: 5px;
}
.form_login {
    align-items: center;
    display: flex;
    flex-direction: column;
}
.form_login img {
    width: 120px;
    margin-bottom: 14px;
}
.row_form {
    width: 100%;
}
