.Headband {
    height: 50px;
    background: #f6f6f6;
    display: block;
    border: 0;

    box-sizing: border-box;
    position: relative;
    padding: 13px;
}

.Headband p {
    padding: 0;
    font: 12px "Open Sans", sans-serif;
    font-weight: 900;
    font-size: 16px;
    color: #494949;
    text-align: center;
}
