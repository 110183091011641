.ErrorPage {
    display: flex;
    height: 100%;
    justify-content: center;
    align-items: center;
    background-color: #00beb0;
    flex-direction: column;
}
.ErrorPage__btn {
    background: #fff;
    padding: 12px 54px;
    border-radius: 10px;
    color: #00beb0;
    font-weight: 800;
    font-size: 12px;
    outline: none;
    border: 0;
    cursor: pointer;
}
.ErrorPage__msg-text {
    font-size: 16px;
    font-weight: bold;
    font-family: "Open Sans", sans-serif;
    color: #fff;
    display: inline-block;
    vertical-align: top;
    float: left;
    margin-top: 7px;
    margin-bottom: 13px;
}

.ErrorPage__img {
    height: 247px;
    width: 103.14px;
    margin-bottom: 14px;
    object-fit: contain;
}
