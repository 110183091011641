.loading-container {
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.sobrus-logo {
    width: 150px;
    height: auto;
    padding: 0;
}
.loader-container {
    width: 100%;
    height: 6px;
    border-radius: 4px;
    background-color: #ededed;
    margin-top: 20px;
    position: relative;
}

.loader-item {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #e04052;
    width: 0%;
    height: 100%;
    border-radius: 4px;
    animation: loading 3s linear 1 normal forwards;
}

@keyframes loading {
    0% {
        width: 30%;
    }
    50% {
        width: 50%;
    }
    70% {
        width: 70%;
    }
    90%,
    100% {
        width: 100%;
    }
}
