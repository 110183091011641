.MobileVersion__container {
    height: 100%;
    width: 100 vw !important;
    background-color: #e13d4d;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.MobileVersion__container > p {
    padding: 4px 10px;
    font: 20px "Open sans", sans-serif;
    display: inline-block;
    width: 100vw;
    text-align: center;
    color: #fff;
    font-weight: bold;
}
.MobileVersion__container > img {
    margin-bottom: 20px;
}
.MobileVersion__playStore-img {
    width: 34px;
    margin-bottom: 0;
    margin-left: 0;
}

.MobileVersion__btn {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #000;
    padding: 12px 0;
    border-radius: 10px;
    width: 234px;
}

.MobileVersion__btn div span {
    display: block;
    color: #fff;
    font-weight: 600;
    font-size: 16px;
    text-align: center;
}
.MobileVersion__btn > div > span:nth-child(2) {
    font-weight: bold !important;
    font-size: 22px !important;
}

.MobileVersion__btn-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 15px;
}
.MobileVersion__btn > :first-child {
    margin-right: 8px;
}

.MobileVersion__btn-container > :first-child {
    margin-bottom: 17px;
}
