@charset "UTF-8";
.ui-timepicker-div .ui-widget-header {
    margin-bottom: 8px;
}
.ui-timepicker-div dl {
    text-align: left;
}
.ui-timepicker-div dl dt {
    height: 25px;
    margin-bottom: -25px;
}
.ui-timepicker-div dl dd {
    margin: 0 10px 10px 65px;
}
.ui-timepicker-div td {
    font-size: 90%;
}
.ui-tpicker-grid-label {
    background: 0;
    border: 0;
    margin: 0;
    padding: 0;
}
.ui-timepicker-rtl {
    direction: rtl;
}
.ui-timepicker-rtl dl {
    text-align: right;
}
.ui-timepicker-rtl dl dd {
    margin: 0 65px 10px 10px;
}
.ui-timepicker2-inline {
    display: inline;
}
#ui-timepicker2-div {
    padding: 0.2em;
}
.ui-timepicker2-table {
    display: inline-table;
    width: 0;
}
.ui-timepicker2-table table {
    margin: 0.15em 0 0 0;
    border-collapse: collapse;
}
.ui-timepicker2-hours,
.ui-timepicker2-minutes {
    padding: 0.2em;
}
.ui-timepicker2-table .ui-timepicker2-title {
    line-height: 1.8em;
    text-align: center;
}
.ui-timepicker2-table td {
    padding: 0.1em;
    width: 2.2em;
}
.ui-timepicker2-table th.periods {
    padding: 0.1em;
    width: 2.2em;
}
.ui-timepicker2-table td span {
    display: block;
    padding: 0.2em 0.3em 0.2em 0.5em;
    width: 1.2em;
    text-align: right;
    text-decoration: none;
}
.ui-timepicker2-table td a {
    display: block;
    padding: 0.2em 0.3em 0.2em 0.5em;
    width: 1.2em;
    cursor: pointer;
    text-align: right;
    text-decoration: none;
}
.ui-timepicker2 .ui-timepicker2-buttonpane {
    background-image: none;
    margin: 0.7em 0 0 0;
    padding: 0 0.2em;
    border-left: 0;
    border-right: 0;
    border-bottom: 0;
}
.ui-timepicker2 .ui-timepicker2-buttonpane button {
    margin: 0.5em 0.2em 0.4em;
    cursor: pointer;
    padding: 0.2em 0.6em 0.3em 0.6em;
    width: auto;
    overflow: visible;
}
.ui-timepicker2 .ui-timepicker2-close {
    float: right;
}
.ui-timepicker2 .ui-timepicker2-now {
    float: left;
}
.ui-timepicker2 .ui-timepicker2-deselect {
    float: left;
}
.formError {
    z-index: 990;
}
.formError .formErrorContent {
    z-index: 991;
}
.formError .formErrorArrow {
    z-index: 996;
}
.ui-dialog .formError {
    z-index: 5000;
}
.ui-dialog .formError .formErrorContent {
    z-index: 5001;
}
.ui-dialog .formError .formErrorArrow {
    z-index: 5006;
}
.inputContainer {
    position: relative;
    float: left;
}
.formError {
    position: absolute;
    top: 300px;
    left: 300px;
    display: block;
    cursor: pointer;
    text-align: left;
}
.formError.inline {
    position: relative;
    top: 0;
    left: 0;
    display: inline-block;
}
.ajaxSubmit {
    padding: 20px;
    background: #55ea55;
    border: 1px solid #999;
    display: none;
}
.formError .formErrorContent {
    width: 100%;
    background: #fff;
    position: relative;
    color: #ff000e;
    min-width: 120px;
    font-size: 11px;
    font-weight: 400;
    border: 2px solid #ff000e;
    box-shadow: 0 0 6px #000;
    -moz-box-shadow: 0 0 6px #000;
    -webkit-box-shadow: 0 0 6px #000;
    -o-box-shadow: 0 0 6px #000;
    padding: 4px 10px 4px 10px;
    border-radius: 6px;
    -moz-border-radius: 6px;
    -webkit-border-radius: 6px;
    -o-border-radius: 6px;
}
.formError.inline .formErrorContent {
    box-shadow: none;
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
    -o-box-shadow: none;
    border: 0;
    border-radius: 0;
    -moz-border-radius: 0;
    -webkit-border-radius: 0;
    -o-border-radius: 0;
}
.greenPopup .formErrorContent {
    background: #33be40;
}
.blackPopup .formErrorContent {
    background: #393939;
    color: #fff;
}
.formError .formErrorArrow {
    width: 15px;
    margin: -2px 0 0 13px;
    position: relative;
}
body.rtl .formError .formErrorArrow,
body[dir="rtl"] .formError .formErrorArrow {
    margin: -2px 13px 0 0;
}
.formError .formErrorArrowBottom {
    box-shadow: none;
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
    -o-box-shadow: none;
    margin: 0 0 0 12px;
    top: 2px;
}
.formError .formErrorArrow div {
    border-left: 2px solid #ff000e;
    border-right: 2px solid #ff000e;
    box-shadow: 0 2px 3px #444;
    -moz-box-shadow: 0 2px 3px #444;
    -webkit-box-shadow: 0 2px 3px #444;
    -o-box-shadow: 0 2px 3px #444;
    font-size: 0;
    height: 1px;
    background: #fff;
    margin: 0 auto;
    line-height: 0;
    font-size: 0;
    display: block;
}
.formError .formErrorArrowBottom div {
    box-shadow: none;
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
    -o-box-shadow: none;
}
.greenPopup .formErrorArrow div {
    background: #33be40;
}
.blackPopup .formErrorArrow div {
    background: #393939;
    color: #fff;
}
.formError .formErrorArrow .line10 {
    width: 15px;
    border: 0;
    border-left: 3px solid #ff000e;
    border-right: 3px solid #ff000e;
}
.formError .formErrorArrow .line9 {
    width: 13px;
    border: 0;
    border-left: 2px solid #ff000e;
    border-right: 2px solid #ff000e;
}
.formError .formErrorArrow .line8 {
    width: 11px;
}
.formError .formErrorArrow .line7 {
    width: 9px;
}
.formError .formErrorArrow .line6 {
    width: 7px;
}
.formError .formErrorArrow .line5 {
    width: 5px;
}
.formError .formErrorArrow .line4 {
    width: 3px;
}
.formError .formErrorArrow .line3 {
    width: 1px;
    border-left: 2px solid #ff000e;
    border-right: 2px solid #ff000e;
    border-bottom: 0 solid #ff000e;
}
.formError .formErrorArrow .line2 {
    width: 2px;
    border: 0;
    background: #ff000e;
}
.formError .formErrorArrow .line1 {
    width: 1px;
    border: 0;
    background: #ff000e;
}
#tiptip_holder {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 99999;
}
#tiptip_holder.tip_top {
    padding-bottom: 5px;
}
#tiptip_holder.tip_bottom {
    padding-top: 5px;
}
#tiptip_holder.tip_right {
    padding-left: 5px;
}
#tiptip_holder.tip_left {
    padding-right: 5px;
}
#tiptip_content {
    font-size: 11px;
    color: #fff;
    text-shadow: 0 0 2px #000;
    padding: 4px 8px;
    border: 1px solid rgba(255, 255, 255, 0.25);
    background-color: #191919;
    background-color: rgba(25, 25, 25, 0.92);
    background-image: -webkit-gradient(linear, 0 0, 0 100%, from(transparent), to(#000));
    border-radius: 3px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    box-shadow: 0 0 3px #555;
    -webkit-box-shadow: 0 0 3px #555;
    -moz-box-shadow: 0 0 3px #555;
}
#tiptip_arrow,
#tiptip_arrow_inner {
    position: absolute;
    border-color: transparent;
    border-style: solid;
    border-width: 6px;
    height: 0;
    width: 0;
}
#tiptip_holder.tip_top #tiptip_arrow {
    border-top-color: #fff;
    border-top-color: rgba(255, 255, 255, 0.35);
}
#tiptip_holder.tip_bottom #tiptip_arrow {
    border-bottom-color: #fff;
    border-bottom-color: rgba(255, 255, 255, 0.35);
}
#tiptip_holder.tip_right #tiptip_arrow {
    border-right-color: #fff;
    border-right-color: rgba(255, 255, 255, 0.35);
}
#tiptip_holder.tip_left #tiptip_arrow {
    border-left-color: #fff;
    border-left-color: rgba(255, 255, 255, 0.35);
}
#tiptip_holder.tip_top #tiptip_arrow_inner {
    margin-top: -7px;
    margin-left: -6px;
    border-top-color: #191919;
    border-top-color: rgba(25, 25, 25, 0.92);
}
#tiptip_holder.tip_bottom #tiptip_arrow_inner {
    margin-top: -5px;
    margin-left: -6px;
    border-bottom-color: #191919;
    border-bottom-color: rgba(25, 25, 25, 0.92);
}
#tiptip_holder.tip_right #tiptip_arrow_inner {
    margin-top: -6px;
    margin-left: -5px;
    border-right-color: #191919;
    border-right-color: rgba(25, 25, 25, 0.92);
}
#tiptip_holder.tip_left #tiptip_arrow_inner {
    margin-top: -6px;
    margin-left: -7px;
    border-left-color: #191919;
    border-left-color: rgba(25, 25, 25, 0.92);
}
@media screen and (-webkit-min-device-pixel-ratio: 0) {
    #tiptip_content {
        padding: 4px 8px 5px 8px;
        background-color: rgba(45, 45, 45, 0.88);
    }
    #tiptip_holder.tip_bottom #tiptip_arrow_inner {
        border-bottom-color: rgba(45, 45, 45, 0.88);
    }
    #tiptip_holder.tip_top #tiptip_arrow_inner {
        border-top-color: rgba(20, 20, 20, 0.92);
    }
}
* {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}
a,
abbr,
acronym,
address,
applet,
article,
aside,
audio,
b,
big,
blockquote,
body,
canvas,
caption,
center,
cite,
code,
dd,
del,
details,
dfn,
div,
dl,
dt,
em,
embed,
fieldset,
figcaption,
figure,
footer,
form,
h1,
h2,
h3,
h4,
h5,
h6,
header,
hgroup,
html,
i,
iframe,
img,
input,
ins,
kbd,
label,
legend,
li,
mark,
menu,
nav,
object,
ol,
option,
output,
p,
pre,
q,
ruby,
s,
samp,
section,
select,
small,
span,
strike,
strong,
sub,
summary,
sup,
table,
tbody,
td,
textarea,
tfoot,
th,
thead,
time,
tr,
tt,
u,
ul,
var,
video {
    border: 0;
    margin: 0;
    padding: 0;
    font: 12px "Open Sans", sans-serif;
    font-weight: 400;
    color: #494949;
}
html {
    position: relative;
    -webkit-font-smoothing: antialiased;
}
body,
html {
    width: 100%;
    height: 100%;
}
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
    display: block;
}
ol,
ul {
    list-style: none;
}
img,
input {
    border: none;
    margin: 0;
}
a {
    outline: 0;
    text-decoration: none;
}
button,
input,
select,
textarea {
    outline: 0;
}
textarea {
    resize: none;
}
table {
    border-collapse: collapse;
    border-spacing: 0;
    width: 100%;
}
body {
    line-height: 1;
    /* min-width: 960px; */
    position: relative;
    background: #e5e5e5;
}
.clear {
    clear: both;
}
strong {
    font-weight: 700;
}
[class^="sprite-"] {
    background-image: url(../images/sprite_5.png);
    background-repeat: no-repeat;
    display: inline-block;
}
.sprite-404 {
    width: 153px;
    height: 133px;
    background-position: -5px -5px;
}
.sprite-accordion-arrow-down {
    width: 8px;
    height: 7px;
    background-position: -168px -5px;
}
.sprite-accordion-arrow-up {
    width: 8px;
    height: 7px;
    background-position: -186px -5px;
}
.sprite-accordion-arrow-up.active {
    background-position: -168px -5px;
}
.sprite-apercu {
    width: 81px;
    height: 83px;
    background-position: -204px -5px;
}
.sprite-appel {
    width: 70px;
    height: 54px;
    background-position: -295px -5px;
}
.sprite-bloc-reload {
    width: 18px;
    height: 16px;
    background-position: -168px -69px;
}
.sprite-bloc-reload:hover {
    background-position: -323px -69px;
}
.sprite-bloc-reload:active {
    background-position: -295px -69px;
}
.sprite-bloc-reload-active {
    width: 18px;
    height: 16px;
    background-position: -295px -69px;
}
.sprite-bloc-reload-hover {
    width: 18px;
    height: 16px;
    background-position: -323px -69px;
}
.sprite-bloc-search {
    width: 17px;
    height: 17px;
    background-position: -351px -69px;
}
.sprite-bloc-search:hover {
    background-position: -295px -96px;
}
.sprite-bloc-search:active {
    background-position: -168px -96px;
}
.sprite-bloc-search-active {
    width: 17px;
    height: 17px;
    background-position: -168px -96px;
}
.sprite-bloc-search-hover {
    width: 17px;
    height: 17px;
    background-position: -295px -96px;
}
.sprite-checkbox-checked {
    width: 13px;
    height: 9px;
    background-position: -322px -96px;
}
.sprite-checkbox-dropdown {
    width: 14px;
    height: 16px;
    background-position: -345px -96px;
}
.sprite-close-btn {
    width: 11px;
    height: 12px;
    background-position: -375px -5px;
}
.sprite-close-btn-hover {
    width: 11px;
    height: 11px;
    background-position: -168px -26px;
}
.sprite-close-btn-red {
    width: 12px;
    height: 12px;
    background-position: -369px -96px;
}
.sprite-commercial-brown {
    width: 74px;
    height: 42px;
    background-position: -195px -118px;
}
.sprite-compte {
    width: 67px;
    height: 66px;
    background-position: -279px -123px;
}
.sprite-comtabilite-bleu {
    width: 44px;
    height: 75px;
    background-position: -5px -199px;
}
.sprite-contact {
    width: 65px;
    height: 70px;
    background-position: -59px -199px;
}
.sprite-drag-here {
    width: 51px;
    height: 67px;
    background-position: -134px -199px;
}
.sprite-filedariane {
    width: 6px;
    height: 11px;
    background-position: -375px -26px;
}
.sprite-grh-maroon {
    width: 83px;
    height: 41px;
    background-position: -195px -199px;
}
.sprite-locked {
    width: 16px;
    height: 22px;
    background-position: -369px -118px;
}
.sprite-nextpage-btn {
    width: 8px;
    height: 16px;
    background-position: -378px -47px;
}
.sprite-opportunite {
    width: 70px;
    height: 70px;
    background-position: -288px -199px;
}
.sprite-option-btn {
    width: 18px;
    height: 13px;
    background-position: -5px -150px;
}
.sprite-option-btn:hover {
    background-position: -61px -150px;
}
.sprite-option-btn-active {
    width: 18px;
    height: 13px;
    background-position: -33px -150px;
}
.sprite-option-btn-hover {
    width: 18px;
    height: 13px;
    background-position: -61px -150px;
}
.sprite-option-btn-white {
    width: 18px;
    height: 13px;
    background-position: -89px -150px;
}
.sprite-option-btn-white-active {
    width: 18px;
    height: 13px;
    background-position: -117px -150px;
}
.sprite-option-btn-white-hover {
    width: 18px;
    height: 13px;
    background-position: -145px -150px;
}
.sprite-param-checkbox {
    width: 17px;
    height: 16px;
    background-position: -356px -150px;
    top: 8px;
}
.sprite-param-date {
    width: 18px;
    height: 19px;
    background-position: -368px -176px;
    top: 6px;
}
.sprite-param-decimale {
    width: 30px;
    height: 10px;
    background-position: -5px -176px;
    top: 12px;
}
.sprite-param-devis {
    width: 13px;
    height: 19px;
    background-position: -368px -205px;
    top: 7px;
}
.sprite-param-heur {
    width: 18px;
    height: 17px;
    background-position: -368px -234px;
    top: 8px;
}
.sprite-param-list {
    width: 12px;
    height: 11px;
    background-position: -173px -150px;
    top: 10px;
}
.sprite-param-mail {
    width: 15px;
    height: 15px;
    background-position: -195px -261px;
    top: 9px;
}
.sprite-param-number {
    width: 10px;
    height: 13px;
    background-position: -45px -176px;
    top: 10px;
}
.sprite-param-percent {
    width: 13px;
    height: 13px;
    background-position: -65px -176px;
    top: 10px;
}
.sprite-param-tel {
    width: 13px;
    height: 17px;
    background-position: -220px -261px;
    top: 7px;
}
.sprite-param-text {
    width: 12px;
    height: 13px;
    background-position: -88px -176px;
    top: 10px;
}
.sprite-param-textarea {
    width: 16px;
    height: 15px;
    background-position: -243px -261px;
    top: 9px;
}
.sprite-planning {
    width: 67px;
    height: 65px;
    background-position: -59px -279px;
}
.sprite-prevpage-btn {
    width: 8px;
    height: 16px;
    background-position: -269px -261px;
}
.sprite-prospect {
    width: 66px;
    height: 45px;
    background-position: -287px -279px;
}
.sprite-search-btn {
    width: 13px;
    height: 14px;
    background-position: -368px -261px;
}
.sprite-search-btn-hover {
    width: 13px;
    height: 14px;
    background-position: -5px -285px;
}
.sprite-search-btn-white {
    width: 13px;
    height: 14px;
    background-position: -28px -285px;
}
.sprite-selectbox-btn {
    width: 25px;
    height: 25px;
    background-position: -136px -279px;
}
.sprite-selectbox-btn-hover {
    width: 25px;
    height: 25px;
    background-position: -5px -334px;
}
.sprite-small-drag {
    width: 15px;
    height: 19px;
    background-position: -363px -285px;
}
.sprite-stock-green {
    width: 73px;
    height: 83px;
    background-position: -396px -5px;
}
.sprite-succes-password {
    width: 130px;
    height: 129px;
    background-position: -136px -334px;
}
.sprite-table-delete-btn {
    width: 20px;
    height: 20px;
    background-position: -395px -98px;
}
.sprite-table-delete-btn:hover {
    background-position: -425px -98px;
}
.sprite-table-delete-btn-hover {
    width: 20px;
    height: 20px;
    background-position: -425px -98px;
}
.sprite-table-down-order {
    width: 8px;
    height: 5px;
    background-position: -195px -98px;
}
.sprite-table-edit-btn {
    width: 20px;
    height: 20px;
    background-position: -395px -128px;
}
.sprite-table-edit-btn:hover {
    background-position: -425px -128px;
}
.sprite-table-edit-btn-hover {
    width: 20px;
    height: 20px;
    background-position: -425px -128px;
}
.sprite-table-next-btn {
    width: 25px;
    height: 24px;
    background-position: -396px -158px;
}
.sprite-table-next-btn:hover {
    background-position: -431px -158px;
}
.sprite-table-next-btn-hover {
    width: 25px;
    height: 24px;
    background-position: -431px -158px;
}
.sprite-table-prev-btn {
    width: 24px;
    height: 24px;
    background-position: -396px -192px;
}
.sprite-table-prev-btn:hover {
    background-position: -430px -192px;
}
.sprite-table-prev-btn-hover {
    width: 24px;
    height: 24px;
    background-position: -430px -192px;
}
.sprite-table-ruban {
    width: 9px;
    height: 14px;
    background-position: -455px -98px;
}
.sprite-table-up-order {
    width: 6px;
    height: 4px;
    background-position: -213px -98px;
}
.sprite-taches {
    width: 67px;
    height: 78px;
    background-position: -396px -226px;
}
.sprite-table-validate-btn {
    width: 20px;
    height: 20px;
    background-position: -279px -336px;
}
.sprite-table-validate-btn:hover {
    background-position: -309px -336px;
}
.sprite-table-pdf-btn {
    width: 20px;
    height: 20px;
    background-position: -279px -366px;
}
.sprite-table-pdf-btn:hover {
    background-position: -309px -366px;
}
.sprite-table-recover-btn {
    width: 20px;
    height: 20px;
    background-position: -279px -426px;
}
.sprite-table-recover-btn:hover {
    background-position: -309px -426px;
}
.sprite-table-favorite-btn {
    width: 20px;
    height: 20px;
    background-position: -279px -396px;
}
.sprite-table-favorite-btn:hover {
    background-position: -309px -396px;
}
.sprite-table-plus-btn {
    width: 23px;
    height: 20px;
    background-position: -337px -366px;
}
.sprite-table-plus-btn:hover {
    background-position: -337px -396px;
}
.sprite-table-upload-btn {
    width: 20px;
    height: 20px;
    background-position: -369px -336px;
}
.sprite-table-upload-btn:hover {
    background-position: -339px -336px;
}
.sprite-date-icon {
    width: 13px;
    height: 13px;
    background-position: -41px -366px;
}
.sprite-date-icon-hover {
    width: 13px;
    height: 13px;
    background-position: -23px -366px;
}
.sprite-douchette-icon {
    width: 13px;
    height: 13px;
    background-position: -41px -383px;
}
.sprite-douchette-icon-hover {
    width: 13px;
    height: 13px;
    background-position: -23px -383px;
}
.sprite-table-valid {
    width: 18px;
    height: 18px;
    background-position: -5px -402px;
}
.sprite-table-nonvalid {
    width: 18px;
    height: 18px;
    background-position: -27px -402px;
}
.sprite-invoice {
    width: 54px;
    height: 66px;
    background-position: -3px -470px;
}
.sprite-quotes {
    width: 53px;
    height: 62px;
    background-position: -77px -470px;
}
.sprite-customers {
    width: 66px;
    height: 66px;
    background-position: -140px -470px;
}
.sprite-contacts {
    width: 65px;
    height: 71px;
    background-position: -216px -470px;
}
.sprite-credit {
    width: 87px;
    height: 49px;
    background-position: -291px -470px;
}
.sprite-emails {
    width: 87px;
    height: 64px;
    background-position: -388px -470px;
}
.sprite-products {
    width: 85px;
    height: 95px;
    background-position: -5px -554px;
}
.sprite-suppliers {
    width: 67px;
    height: 66px;
    background-position: -100px -554px;
}
.sprite-ventes {
    width: 93px;
    height: 70px;
    background-position: -177px -554px;
}
.sprite-achats {
    width: 93px;
    height: 70px;
    background-position: -280px -554px;
}
.sprite-payments {
    width: 107px;
    height: 63px;
    background-position: -341px -337px;
}
.sprite-stocks {
    width: 106px;
    height: 102px;
    background-position: -5px -664px;
}
.sprite-warehouse {
    width: 131px;
    height: 73px;
    background-position: -122px -664px;
}
.sprite-default-customer {
    width: 111px;
    height: 111px;
    background-position: -490px -5px;
}
.sprite-default-product {
    width: 111px;
    height: 111px;
    background-position: -490px -126px;
}
.sprite-default-supplier {
    width: 111px;
    height: 111px;
    background-position: -490px -247px;
}
.sprite-default-contact {
    width: 111px;
    height: 111px;
    background-position: -490px -368px;
}
.sprite-quick-create {
    width: 17px;
    height: 17px;
    background-position: -341px -426px;
}
.sprite-mail-sent {
    width: 33px;
    height: 27px;
    background-position: -490px -491px;
}
.alert-thumb,
.autre,
.avoir,
.caret,
.cb,
.change,
.cheque,
.cog,
.cog2,
.comment-envelope,
.dash-envelope,
.dash-head,
.dash-left1,
.dash-left2,
.dash-site,
.down-arrow,
.dsh-comment,
.empty-search-ico,
.equal,
.espece,
.facture-ico,
.for-pop,
.for-pop2,
.for-pop3,
.help-ico,
.help:hover .help-ico,
.imgs,
.list-ico,
.livre,
.minus,
.pen,
.pen2,
.person,
.picture,
.play-ico,
.plus-ico,
.print-ico,
.remove-ico,
.remove-ico2,
.share,
.sprite-bloc-star,
.sprite2-sprite,
.task,
.user2,
.users,
.valide-ico {
    background-image: url(../images/sprite2-seb3f04b129.png);
    background-repeat: no-repeat;
}
.sprite3-sprite {
    background-image: url(../images/sprite3-s54ca589f8b.png);
    background-repeat: no-repeat;
}
.container4_in {
    width: 100%;
}
.grid1,
.grid2,
.grid2-5,
.grid2-5 .grid0,
.grid3,
.grid35,
.grid4,
.grid65 {
    display: inline;
    float: left;
    position: relative;
    margin-left: 0.5%;
    margin-right: 0.5%;
    margin-bottom: 1%;
}
.container4 .grid0,
.container4_in .grid0 {
    width: 19%;
}
.container4 .grid1,
.container4_in .grid1 {
    width: 24%;
}
.container4 .grid1-5,
.container4_in .grid1-5 {
    width: 35%;
}
.container4 .grid2,
.container4_in .grid2 {
    width: 49%;
}
.container4 .grid2-5,
.container4_in .grid2-5 {
    width: 63%;
}
.container4 .grid3,
.container4_in .grid3 {
    width: 74%;
}
.container4 .grid4,
.container4_in .grid4 {
    width: 99%;
}
.container4 .grid35,
.container4_in .grid35 {
    width: 34%;
}
.container4 .grid65,
.container4_in .grid65 {
    width: 64%;
}
.top_options_wrapper .grid1,
.top_options_wrapper .grid2,
.top_options_wrapper .grid3,
.top_options_wrapper .grid4,
header .grid1,
header .grid2,
header .grid3,
header .grid4 {
    margin-bottom: 0;
}
.container4_param .grid2 {
    width: 49.5% !important;
}
.alpha {
    margin-left: 0 !important;
}
.omega {
    margin-right: 0 !important;
}
.text-right {
    text-align: right;
}
.grid_third {
    margin-right: -10px;
    margin-left: -10px;
}
.grid_1_3 {
    width: 33.33333333%;
    float: left;
    padding-right: 10px;
    padding-left: 10px;
}
.wrapper {
    margin: 0 20px;
}
.left {
    float: left;
}
.right {
    float: right;
}
h1 {
    font-size: 16px;
    font-weight: 700;
}
h2 {
    font-size: 14px;
    font-weight: 600;
}
h2.white {
    color: #fff;
}
.text-center {
    text-align: center;
}
.pull-left {
    float: left;
}
.pull-right {
    float: right;
}
.dropdown_btn {
    position: relative;
}
.danger {
    color: #e43846;
    font-weight: 700;
}
header {
    height: 50px;
    background: #00beb0;
}
.logo {
    font-size: 18px;
    font-weight: 300;
    color: #fff;
    text-transform: uppercase;
    line-height: 50px;
    float: left;
}
.logo strong {
    font-size: 18px;
    color: #fff;
    text-transform: uppercase;
}
.top_quick_create {
    float: left;
    position: relative;
    margin: 16px 16px 0 16px;
}
.quick_create_btn {
    width: 17px;
    height: 17px;
    line-height: 15px;
    display: block;
    color: #fff;
    text-align: center;
    border: 1px solid #fff;
    -moz-border-radius: 8px;
    -webkit-border-radius: 8px;
    border-radius: 8px;
}
.header_wrraper {
    margin-left: 180px;
    height: 50px;
}
#topnav_wrraper {
    float: left;
    height: 50px;
}
#topnav {
    height: 50px;
}
#topnav > li {
    float: left;
    display: inline-block;
    height: 50px;
}
#topnav > li > a {
    color: #f0f0f0;
    margin: 0 10px;
    line-height: 50px;
    font-weight: 900;
}
#topnav > li > a:hover {
    color: #fff;
}
#topnav > li > a.selected {
    padding: 5px 8px 5px 8px;
    background-color: #00a89b;
    border-radius: 3px;
    color: #fff;
}
#topnav > li > a.selected:hover {
    padding: 5px 8px 5px 8px;
    background-color: #00a89b;
    border-radius: 3px;
    color: #fff;
}
#topnav > li .submenu {
    position: absolute;
    top: 36px;
    margin-left: 10px;
}
#topnav > li:hover .submenu {
    display: block;
}
.profil_wrraper {
    float: right;
    padding: 10px 10px 0 20px;
    cursor: pointer;
    border-left: 1px solid #0ba89c;
    height: 50px;
}
.profil_wrraper .dropdown,
.profil_wrraper .submenu {
    min-width: 162px;
    right: 0;
}
.header_profil_thumb {
    width: 30px;
    height: 30px;
    -moz-border-radius: 3px;
    -webkit-border-radius: 3px;
    border-radius: 3px;
    background: #fff;
    display: block;
    float: left;
}
.header_profil_infos {
    float: left;
    margin: 6px 10px 0 10px;
}
.header_profil_infos p,
.header_profil_infos span {
    color: #fff;
}
.header_profil_infos span {
    font-size: 11px;
    margin-top: -6px;
    display: block;
}
.profil_arrow {
    width: 8px;
    height: 5px;
    position: relative;
}
.profil_arrow:after {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 5px 4px 0 4px;
    border-color: #fff transparent transparent transparent;
    margin-top: 10px;
}
.top_options_wrapper {
    height: 40px;
    background: #fff;
    -moz-box-shadow: 0 0 1px 1px #bcbcbc;
    -webkit-box-shadow: 0 0 1px 1px #bcbcbc;
    box-shadow: 0 0 1px 1px #bcbcbc;
}
.top_options_wrapper h1 {
    float: left;
    line-height: 40px;
}
.top_options_select {
    float: left;
    width: 200px;
    margin: 7px 0 0 20px;
}
.top_options_btns {
    float: right;
    padding-top: 7px;
}
.top_options_btns a,
.top_options_btns button,
.top_options_btns > div {
    float: left;
    margin-left: 5px;
}
.tob_page a {
    margin-left: 2px !important;
}
#content {
    margin-top: 10px;
    padding-bottom: 60px;
}
#content:after {
    visibility: hidden;
    display: block;
    font-size: 0;
    content: " ";
    clear: both;
    height: 0;
}
.bloc {
    background: #fff;
    margin-bottom: 10px;
    -moz-border-radius: 2px;
    -webkit-border-radius: 2px;
    border-radius: 2px;
    -moz-box-shadow: 0 1px 3px 0 #bcbcbc;
    -webkit-box-shadow: 0 1px 3px 0 #bcbcbc;
    box-shadow: 0 1px 3px 0 #bcbcbc;
}
.bloc_header {
    position: relative;
    padding: 10px;
    background: #f0f0f0;
    -moz-border-radius-topleft: 2px;
    -webkit-border-top-left-radius: 2px;
    border-top-left-radius: 2px;
    -moz-border-radius-topright: 2px;
    -webkit-border-top-right-radius: 2px;
    border-top-right-radius: 2px;
}
.bloc_header h2 {
    float: left;
}
.bloc_header:after {
    visibility: hidden;
    display: block;
    font-size: 0;
    content: " ";
    clear: both;
    height: 0;
}
.bloc_header_btns {
    float: right;
}
.bloc_header_btns [class^="sprite"] {
    vertical-align: middle;
    float: right;
    margin-left: 12px;
    cursor: pointer;
}
.bloc_header_btns .sprite-option-btn {
    margin-top: 2px;
    position: relative;
}
.bloc_header_btns .sprite-option-btn.active {
    background-position: -33px -150px;
}
.bloc_header_btns .sprite-option-btn .dropdown,
.bloc_header_btns .sprite-option-btn .submenu {
    right: 0;
}
.bloc_content {
    padding: 10px;
    background: #fff;
    position: relative;
}
.bloc_content:after {
    visibility: hidden;
    display: block;
    font-size: 0;
    content: " ";
    clear: both;
    height: 0;
}
.bloc_content.pdn {
    padding: 0;
}
.bloc_content.table_wrraper {
    padding-top: 0;
}
.bloc_content.bt {
    -moz-border-radius-topleft: 2px;
    -webkit-border-top-left-radius: 2px;
    border-top-left-radius: 2px;
    -moz-border-radius-topright: 2px;
    -webkit-border-top-right-radius: 2px;
    border-top-right-radius: 2px;
}
.bloc_content.tdb {
    min-height: 235px;
}
.bloc_content.setup_turquoise {
    background: #22b2cc;
}
.bloc_content.setup_rose {
    background: #e7055a;
}
.bloc_content.setup_bleuviolet {
    background: #af05e7;
}
.bloc_content.setup_greenspring {
    background: #05e7aa;
}
.bloc_content.setup_orange {
    background: #e7aa05;
}
.bloc_content.setup_orangered {
    background: #e75005;
}
.bloc_content.setup_sandybrown {
    background: #d98f2e;
}
.bloc_content.setup_sienna {
    background: #da883e;
}
.bloc_content_header {
    margin-bottom: 10px;
}
.bloc_param {
    -moz-border-radius: 2px;
    -webkit-border-radius: 2px;
    border-radius: 2px;
}
.empty_content {
    padding: 10px;
    background: #fff;
    position: relative;
    -moz-border-radius-bottomleft: 2px;
    -webkit-border-bottom-left-radius: 2px;
    border-bottom-left-radius: 2px;
    -moz-border-radius-bottomright: 2px;
    -webkit-border-bottom-right-radius: 2px;
    border-bottom-right-radius: 2px;
    overflow: hidden;
}
.row {
    margin: 5px 0;
}
#popup_wrapper {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    text-align: center;
    z-index: 999;
    background: #484848;
    opacity: 0.5;
}
.popup_cnt {
    position: absolute;
    top: 90px;
    left: 50%;
    margin-left: -315px;
    z-index: 9999;
    width: 630px;
    background: #fff;
    border: 1px solid #ddd;
    -moz-box-shadow: 0 0 6px 2px #8a8a8a;
    -webkit-box-shadow: 0 0 6px 2px #8a8a8a;
    box-shadow: 0 0 6px 2px #8a8a8a;
    -moz-border-radius: 2px;
    -webkit-border-radius: 2px;
    border-radius: 2px;
}
.popup_cnt .sprite-close-btn {
    position: absolute;
    top: 11px;
    right: 10px;
}
.popup_cnt.colord-popup {
    border: 5px solid #00beb0;
    width: 500px;
    margin-left: -250px;
}
.popup_cnt.colord-popup .popup_header {
    height: 50px;
    line-height: 50px;
    background: #00beb0;
    color: #fff;
    font-weight: 800;
}
.popup_cnt.colord-popup .popup_content {
    padding: 15px 25px;
}
.popup_header {
    height: 32px;
    line-height: 32px;
    font-size: 14px;
    font-weight: 600;
    padding: 0 10px;
    background: #f0f0f0;
    -moz-border-radius-topleft: 2px;
    -webkit-border-top-left-radius: 2px;
    border-top-left-radius: 2px;
    -moz-border-radius-topright: 2px;
    -webkit-border-top-right-radius: 2px;
    border-top-right-radius: 2px;
}
.popup_content {
    padding: 10px;
    -moz-border-radius-bottomleft: 2px;
    -webkit-border-bottom-left-radius: 2px;
    border-bottom-left-radius: 2px;
    -moz-border-radius-bottomright: 2px;
    -webkit-border-bottom-right-radius: 2px;
    border-bottom-right-radius: 2px;
    position: relative;
}
.popup_content .input {
    width: 305px;
}
.popup_content .task {
    float: right;
    margin-right: -25px;
    margin-top: -68px;
}
.popup_content p {
    margin-bottom: 10px;
}
.popup-close {
    display: inline-block;
    background: #08a296;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    line-height: 41px;
    text-align: center;
    cursor: pointer;
    position: absolute;
    top: 9px;
    right: 0;
}
.popup-close i {
    font-size: 20px;
    color: #037b72;
}
.popup_form_submit {
    padding-left: 130px;
}
.popup_codebar {
    padding: 54px 0;
}
.popup_codebar p {
    font-size: 18px;
    font-weight: 600;
    text-align: center;
}
.popup_codebar p span {
    font-size: 16px;
    font-weight: 300;
    margin: 0;
}
.popup_codebar span {
    font-weight: 600;
    display: block;
    text-align: center;
    margin-top: 15px;
}
.popup_codebar .input_douchette {
    margin: 15px auto 0;
    width: 360px;
}
.rating-cnt {
    padding-right: 76px;
}
.rating {
    text-align: center;
    padding: 20px;
}
.rating a {
    cursor: pointer;
}
.rating a i {
    font-size: 20px;
    color: #dddcdc;
}
.rating a.rated i {
    color: #f0c65b;
}
.task {
    display: inline-block;
    width: 146px;
    height: 125px;
    background-position: 0 -2483px;
}
.popup-form .form_row {
    margin-bottom: 5px;
}
.popup-form .form_row .btn_red {
    float: right;
    padding: 0 45px;
}
.for-pop {
    display: inline-block;
    width: 121px;
    height: 141px;
    background-position: 0 -1189px;
}
.for-pop2 {
    display: inline-block;
    width: 283px;
    height: 224px;
    background-position: 0 -1344px;
}
.for-pop3 {
    display: inline-block;
    width: 308px;
    height: 323px;
    background-position: 0 -1582px;
}
.msg {
    position: relative;
}
.pop-up-msg p {
    width: 203px;
    background: #00beb0;
    color: #fff;
    font-weight: 700;
    text-align: justify;
    padding: 4px 10px;
    position: absolute;
    right: 32px;
    top: 21px;
    display: inline-block;
    font-size: 13px;
    line-height: 15px;
    border-radius: 4px;
}
.pop-up-msg .for-pop {
    position: relative;
    left: 100px;
    margin-bottom: -19px;
}
.update-fig1 {
    display: inline-block;
    margin-top: 20px;
}
.update-fig1 i {
    border: 14px solid #00beb0;
    border-radius: 26px;
    box-sizing: content-box;
}
.update-pop {
    width: 600px !important;
    margin-left: -300px !important;
}
.update-cnt {
    text-align: center;
}
.update-cnt img {
    display: block;
    width: 100%;
    margin-bottom: 20px;
}
.update-cnt h2 {
    color: #00beb0;
    font-size: 15px;
    font-weight: 700;
    text-align: left;
}
.update-cnt p {
    text-align: left;
}
.update-cnt p.p1 {
    padding: 25px 0 15px;
}
.update-cnt .btn_red {
    width: 245px;
    font-size: 14px;
    height: 38px;
    line-height: 38px;
}
.pop-btn {
    overflow: hidden;
}
.pop-btn a {
    font-size: 14px;
    display: inline-block;
    line-height: 38px;
}
.pop-btn .btn_bleu {
    font-size: 14px;
    height: 38px;
    line-height: 38px;
}
.pop-msg2 {
    font-size: 14px;
    background: #f05650;
    color: #fff;
    padding: 10px;
    width: 173px;
    border-radius: 3px;
    position: absolute;
    right: 30px;
    top: 119px;
}
.loader_wrapper {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    text-align: center;
    z-index: 999;
    background: #fff;
    opacity: 0.9;
}
.loader_cnt {
    width: 100%;
    height: 194px;
    padding: 42px 0;
    background: #fff;
    position: absolute;
    top: 50%;
    left: 0;
    margin-top: -97px;
    z-index: 9999;
}
.loader_cnt p {
    font-size: 20px;
    font-weight: 600;
    text-align: center;
}
.loader {
    width: 184px;
    height: 142px;
    display: block;
    background: transparent url(../images/loader.gif) left top no-repeat;
    margin: 0 auto;
}
.bloc_loader {
    min-height: 194px;
    position: relative;
}
.btn_bleu {
    display: inline-block;
    padding: 0 22px;
    height: 25px;
    color: #fff;
    text-align: center;
    line-height: 24px;
    font-weight: 600;
    cursor: pointer;
    background: #00beb0;
    -moz-border-radius: 2px;
    -webkit-border-radius: 2px;
    border-radius: 2px;
    border: 1px solid #00beb0;
}
.btn_bleu:hover {
    background: #05ab9f;
    border: 1px solid #05ab9f;
}
.btn_bleu:active {
    background: #059187;
    border: 1px solid #059187;
}
.btn_bleu.large {
    width: 100%;
}
.btn_red {
    display: inline-block;
    padding: 0 22px;
    height: 25px;
    color: #fff;
    text-align: center;
    line-height: 24px;
    font-weight: 600;
    cursor: pointer;
    background: #ffa902;
    -moz-border-radius: 2px;
    -webkit-border-radius: 2px;
    border-radius: 2px;
    border: 1px solid #ffa902;
}
.btn_red:hover {
    background: #f69600;
    border: 1px solid #f69600;
}
.btn_red:active {
    background: #c68609;
    border: 1px solid #c68609;
}
.btn_red.large {
    width: 100%;
}
.btn_white {
    display: inline-block;
    padding: 0 22px;
    height: 25px;
    color: #fff;
    text-align: center;
    line-height: 24px;
    font-weight: 600;
    cursor: pointer;
    background: #fff;
    -moz-border-radius: 2px;
    -webkit-border-radius: 2px;
    border-radius: 2px;
    color: #494949;
    border: 1px solid #f0f0f0;
}
.btn_white:hover {
    border: 1px solid #b2b2b2;
}
.btn_white:active {
    border: 1px solid #b2b2b2;
    background: #f6f6f6;
}
.btn_white.large {
    width: 100%;
}
.btn_red2 {
    display: inline-block;
    padding: 0 22px;
    height: 25px;
    color: #fff;
    text-align: center;
    line-height: 24px;
    font-weight: 600;
    cursor: pointer;
    background: #f95e5e;
    -moz-border-radius: 2px;
    -webkit-border-radius: 2px;
    border-radius: 2px;
    border: 1px solid #f95e5e;
}
.btn_red2:hover {
    background: #f72d2d;
    border: 1px solid #f72d2d;
}
.search_bleu_btn {
    display: inline-block;
    height: 25px;
    width: 25px;
    background: #00beb0;
    cursor: pointer;
    -moz-border-radius: 2px;
    -webkit-border-radius: 2px;
    border-radius: 2px;
    position: relative;
}
.search_bleu_btn:hover {
    background: #05ab9f;
}
.search_bleu_btn:active {
    background: #0873ad;
}
.search_bleu_btn i {
    position: absolute;
    top: 6px;
    left: 6px;
}
.quick_create a {
    text-align: left;
    margin-bottom: 5px;
    padding: 0 10px;
}
.input,
.input-disabled,
.input_date,
.input_douchette,
.input_search,
.input_search_delete,
.textarea {
    height: 25px;
    width: 100%;
    color: #5a5a5a;
    font-family: "Open Sans", sans-serif;
    border: 1px solid #bbb;
    padding: 1px 5px;
    margin: 0;
    -moz-border-radius: 3px;
    -webkit-border-radius: 3px;
    border-radius: 3px;
}
.input-disabled:focus,
.input-disabled:hover,
.input:focus,
.input:hover,
.input_date:focus,
.input_date:hover,
.input_douchette:focus,
.input_douchette:hover,
.input_search:focus,
.input_search:hover,
.input_search_delete:focus,
.input_search_delete:hover,
.textarea:focus,
.textarea:hover {
    border-color: #00beb0;
}
.input_date,
.input_douchette,
.input_search {
    padding-right: 20px;
    position: relative;
    background: #fff;
}
.input_date input[type="text"],
.input_douchette input[type="text"],
.input_search input[type="text"] {
    width: 100%;
    margin-top: 1px;
    height: 19px;
}
.input_date.input-disabled .disabled,
.input_douchette.input-disabled .disabled,
.input_search.input-disabled .disabled {
    margin-top: 1px;
    height: 19px;
    background: #d9d9d9;
}
.input_date .sprite-param-percent,
.input_date .sprite-search-btn,
.input_date .sprite-search-btn-hover,
.input_douchette .sprite-param-percent,
.input_douchette .sprite-search-btn,
.input_douchette .sprite-search-btn-hover,
.input_search .sprite-param-percent,
.input_search .sprite-search-btn,
.input_search .sprite-search-btn-hover {
    position: absolute;
    top: 4px;
    right: 5px;
    cursor: pointer;
}
.input_date .sprite-close-btn,
.input_date .sprite-close-btn-hover,
.input_douchette .sprite-close-btn,
.input_douchette .sprite-close-btn-hover,
.input_search .sprite-close-btn,
.input_search .sprite-close-btn-hover {
    position: absolute;
    top: 5px;
    right: 22px;
    cursor: pointer;
}
.input_date .sprite-date-icon,
.input_date .sprite-date-icon-hover,
.input_douchette .sprite-date-icon,
.input_douchette .sprite-date-icon-hover,
.input_search .sprite-date-icon,
.input_search .sprite-date-icon-hover {
    position: absolute;
    top: 5px;
    right: 4px;
    cursor: pointer;
}
.input_date .sprite-douchette-icon,
.input_date .sprite-douchette-icon-hover,
.input_douchette .sprite-douchette-icon,
.input_douchette .sprite-douchette-icon-hover,
.input_search .sprite-douchette-icon,
.input_search .sprite-douchette-icon-hover {
    position: absolute;
    top: 5px;
    right: 3px;
    cursor: pointer;
}
.input_date.disabled,
.input_douchette.disabled,
.input_search.disabled {
    border: 1px solid #d9d9d9;
    background: #d9d9d9;
}
.input_date.disabled input,
.input_douchette.disabled input,
.input_search.disabled input {
    background: #d9d9d9;
}
.textarea {
    height: 80px;
    padding: 2px 5px;
}
.input.disabled,
.textarea.disabled {
    border: 1px solid #d9d9d9;
    background: #d9d9d9;
}
.input.disabled:hover,
.textarea.disabled:hover {
    border-color: #d9d9d9 !important;
}
.focus {
    border-color: #00beb0;
}
.input-disabled {
    background: #d9d9d9;
    border-color: #d9d9d9;
}
.input-disabled:hover {
    border-color: #d9d9d9;
}
.checkbox input {
    display: none;
}
.checkbox .checked {
    width: 20px;
    height: 20px;
    display: inline-block;
    background: #dedede;
    border: 1px solid #dedede;
    vertical-align: middle;
    -moz-border-radius: 2px;
    -webkit-border-radius: 2px;
    border-radius: 2px;
}
.checkbox input:checked + .checked {
    background: #00beb0;
    border: 1px solid #00beb0;
    position: relative;
}
.checkbox input:checked + .checked:after {
    content: "";
    display: block;
    width: 13px;
    height: 9px;
    background: transparent url(../images/sprite_5.png) -322px -96px no-repeat;
    position: absolute;
    top: 4px;
    left: 2px;
}
.checkbox.disabled .checked {
    background: #dedede !important;
    border: 1px solid #dedede !important;
    position: relative;
}
.checkbox.disabled .checked:after {
    content: "";
    display: block;
    width: 13px;
    height: 9px;
    background: transparent url(../images/sprite_5.png) -322px -96px no-repeat;
    position: absolute;
    top: 4px;
    left: 2px;
}
.checkbox:hover .checked {
    border: 1px solid #b2b2b2;
}
.checkbox_dd {
    width: 41px;
    height: 20px;
    position: relative;
}
.checkbox_dd .checkbox .checked {
    width: 41px;
}
.checkbox_dd_btn {
    width: 20px;
    height: 20px;
    position: absolute;
    top: 0;
    right: 0;
    z-index: 999;
}
.checkbox_dd_btn i {
    margin-top: 2px;
}
.checkbox_dd_btn .dropdown,
.checkbox_dd_btn .submenu {
    left: -20px;
}
.radio input {
    display: none;
}
.radio .checked {
    width: 20px;
    height: 20px;
    display: inline-block;
    background: #fff;
    border: 2px solid #00beb0;
    vertical-align: middle;
    -moz-border-radius: 13px;
    -webkit-border-radius: 13px;
    border-radius: 13px;
}
.radio input:checked + .checked {
    border: 2px solid #00beb0;
    position: relative;
}
.radio input:checked + .checked:after {
    content: "";
    display: block;
    width: 10px;
    height: 10px;
    background: #00beb0;
    -moz-border-radius: 5px;
    -webkit-border-radius: 5px;
    border-radius: 5px;
    position: absolute;
    top: 3px;
    left: 3px;
}
.radio.disabled .checked {
    border: 1px solid #dedede !important;
}
.radio:hover .checked {
    border: 2px solid #3fea4c;
}
.checkbox,
.radio {
    margin-right: 10px;
}
.selectbox {
    width: 100%;
    height: 25px;
    overflow: hidden;
    border: 1px solid #bbb;
    -moz-border-radius: 3px;
    -webkit-border-radius: 3px;
    border-radius: 3px;
    background: #fff url(../images/selectbox.png) 100% -1px no-repeat;
    position: relative;
}
.selectbox .sprite-selectbox-btn {
    position: absolute;
    top: -1px;
    right: -1px;
}
.selectbox:hover {
    border-color: #00beb0;
}
.selectbox select {
    background: 0 0;
    width: 100%;
    height: 22px;
    margin-top: 1px;
    font-size: 12px;
    line-height: 1;
    border: 0;
    -webkit-appearance: none;
}
.selectbox.disabled {
    border: 1px solid #d9d9d9;
    background: #d9d9d9 url(../images/selectbox_disabled.png) 100% -1px no-repeat;
}
.input_file_cnt {
    position: relative;
}
.input_file {
    width: 100%;
    height: 25px;
    border: 1px solid #bbb;
    -moz-border-radius: 3px;
    -webkit-border-radius: 3px;
    border-radius: 3px;
    background: #fff;
    position: relative;
}
.input_file a {
    display: inline-block;
    padding: 0 22px;
    height: 25px;
    color: #fff;
    text-align: center;
    line-height: 24px;
    font-weight: 600;
    cursor: pointer;
    background: #00beb0;
    -moz-border-radius: 2px;
    -webkit-border-radius: 2px;
    border-radius: 2px;
    padding: 0 20px;
    position: absolute;
    top: -1px;
    right: -1px;
}
.input_file:hover {
    border: 1px solid #00beb0;
}
.input_file:hover a {
    background: #3fea4c;
}
.file {
    position: absolute;
    left: -9999px;
}
.tag {
    width: 100%;
    height: 20px;
    line-height: 20px;
    font-weight: 600;
    color: #00beb0;
    padding: 0 10px;
    background: #efefef;
    position: relative;
    -moz-border-radius: 3px;
    -webkit-border-radius: 3px;
    border-radius: 3px;
}
.tag:after {
    content: "";
    display: block;
    width: 12px;
    height: 12px;
    -moz-border-radius: 6px;
    -webkit-border-radius: 6px;
    border-radius: 6px;
    position: absolute;
    top: 3px;
    left: 5px;
}
.tag.red {
    border: 1px solid #e7055a;
    color: #494949;
    padding: 0 10px 0 25px;
}
.tag.red:after {
    background: #e7055a;
}
.tag.green {
    border: 1px solid #10cc83;
    color: #494949;
    padding: 0 10px 0 25px;
}
.tag.green:after {
    background: #10cc83;
}
.tag.orange {
    border: 1px solid #e7aa05;
    color: #494949;
    padding: 0 10px 0 25px;
}
.tag.orange:after {
    background: #e7aa05;
}
[class^="alert_"] {
    padding: 10px;
    border: 1px solid;
    margin-bottom: 10px;
    -moz-border-radius: 2px;
    -webkit-border-radius: 2px;
    border-radius: 2px;
    position: relative;
}
[class^="alert_"] .sprite-close-btn {
    position: absolute;
    top: 50%;
    margin-top: -6px;
    right: 10px;
}
[class^="alert_"] p {
    padding: 10px 0;
    font-size: 14px;
}
[class^="alert_"] p.alrt_txt {
    font-size: 16px;
    font-weight: 700;
    padding: 0;
}
.alert_info {
    border-color: #b2b2b2;
    background: #f6f6f6;
}
.alert_info .link {
    font-size: 16px;
    font-weight: 700;
    color: #00beb0;
}
.alert_success {
    border-color: #10cc83;
    background: #eefff8;
}
.alert_success p {
    color: #10cc83;
}
.alert_warning {
    border-color: #dc0930;
    background: #ffedf0;
}
.alert_warning p {
    color: #dc0930;
}
.alert {
    padding: 16px;
    background: #36adee;
    min-height: 120px;
    position: relative;
}
.alert .alert-thumb {
    position: absolute;
    bottom: 0;
    left: 48px;
}
.alert * {
    color: #fff;
}
.alert h2 {
    font-weight: 800;
    font-size: 18px;
    margin-bottom: 8px;
}
.alert p {
    font-size: 13px;
}
.alert-thumb {
    display: inline-block;
    width: 127px;
    height: 115px;
    background-position: 0 0;
}
.play-ico {
    display: inline-block;
    width: 53px;
    height: 55px;
    background-position: 0 -2245px;
}
.remove-ico {
    display: inline-block;
    width: 12px;
    height: 13px;
    background-position: 0 -2370px;
}
.remove-ico2 {
    display: inline-block;
    width: 12px;
    height: 13px;
    background-position: 0 -2397px;
}
.help-ico {
    display: inline-block;
    width: 26px;
    height: 26px;
    background-position: 0 -1959px;
}
.alert-ct {
    position: relative;
    padding: 0 300px 0 200px;
}
.alert-right {
    position: absolute;
    right: 10px;
    top: 6px;
}
.alert-right a {
    display: block;
    text-align: center;
    margin-bottom: 7px;
}
.alert-right a i {
    margin-bottom: -2px;
    margin-left: 6px;
}
.popup-video {
    border-radius: 0 !important;
    padding: 32px 15px 15px;
}
.popup-video .popup_content {
    border-radius: 0 !important;
}
.popup-video .remove-ico2 {
    position: absolute;
    top: 11px;
    right: 10px;
    cursor: pointer;
}
.help {
    display: inline-block;
    margin: 6px 25px;
}
.help:hover .help-ico {
    background-position: 0 -1919px;
}
.embed-responsive {
    position: relative;
    display: block;
    height: 0;
    padding: 0;
    overflow: hidden;
}
.embed-responsive .embed-responsive-item,
.embed-responsive embed,
.embed-responsive iframe,
.embed-responsive object,
.embed-responsive video {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
}
.embed-responsive-16by9 {
    padding-bottom: 56.25%;
}
.embed-responsive-4by3 {
    padding-bottom: 75%;
}
.dropdown,
.submenu {
    min-width: 125px;
    background: #fff;
    border: 1px solid #b2b2b2;
    padding: 8px 0;
    -moz-border-radius: 2px;
    -webkit-border-radius: 2px;
    border-radius: 2px;
    position: absolute;
    z-index: 999999;
    display: none;
}
.dropdown a {
    width: 100%;
    display: inline-block;
}
.submenu a {
    width: 100%;
    display: inline-block;
}
.dropdown li,
.submenu li {
    padding: 0 10px;
    white-space: nowrap;
}
.dropdown li:hover,
.submenu li:hover {
    background: #f0f0f0;
}
.add_comment {
    border: 1px solid #8d8d8d;
    background: #fff;
    height: 31px;
    overflow: hidden;
    -moz-border-radius: 2px;
    -webkit-border-radius: 2px;
    border-radius: 2px;
    cursor: pointer;
    margin-bottom: 10px;
}
.add_comment textarea {
    width: 100%;
    height: 31px;
    padding: 6px;
    -moz-border-radius: 2px;
    -webkit-border-radius: 2px;
    border-radius: 2px;
}
.add_comment div {
    overflow: hidden;
    padding: 6px;
}
.add_comment div input {
    float: right;
}
.comment_item {
    background: #f6f6f6;
    padding: 6px;
    margin-bottom: 3px;
    -moz-border-radius: 2px;
    -webkit-border-radius: 2px;
    border-radius: 2px;
}
.comment_item a {
    font-weight: 600;
}
.comment_item p {
    font-size: 11px;
    padding-top: 7px;
    line-height: 11px;
}
.comment_item small {
    font-size: 10px;
    padding-top: 7px;
}
.comment_btn {
    margin-top: 10px;
}
.table {
    width: 100%;
    background: #fff;
}
.table tr:nth-child(even) {
    background: #f8f8f8;
}

.table td.table_action,
.table td.table_pagination {
    text-align: right;
}
.table td.table_pagination a {
    vertical-align: middle;
}
.table thead tr.table_filter td {
    padding: 10px 5px;
    vertical-align: top;
}
.table thead tr.table_filter td .selectbox {
    margin-bottom: 5px;
}
.table thead th {
    text-align: left;
    font-weight: 700;
    padding: 10px 5px;
    background: #f8f8f8;
    position: relative;
}
.table thead th .sprite-table-down-order,
.table thead th .sprite-table-up-order {
    position: absolute;
}
.table thead th .sprite-table-up-order {
    right: 6px;
    top: 50%;
    margin-top: -4px;
}
.table thead th .sprite-table-down-order {
    right: 5px;
    top: 50%;
    margin-top: 4px;
}
.table thead th.selected {
    border-bottom: 2px solid #00beb0;
}
.table tbody tr:hover {
    background: #00beb0;
}
.table tbody tr:hover td,
.table tbody tr:hover td strong {
    color: #fff !important;
}
.table tbody tr:hover td,
.table tbody tr:hover td strong {
    color: #fff;
}
.table tbody tr:hover .peremp_icon {
    width: 20px;
    height: 18px;
    background-image: url(../images/sprite_5.png);
    background-repeat: no-repeat;
    background-position: -265px -757px;
    float: left;
}
.table tbody tr:hover .peremp_icon_black {
    width: 20px;
    height: 18px;
    background-image: url(../images/sprite_5.png);
    background-repeat: no-repeat;
    background-position: -265px -757px;
    float: left;
}
.table tbody tr:hover .multiprix_icon {
    width: 20px;
    height: 18px;
    background-image: url(../images/sprite_5.png);
    background-repeat: no-repeat;
    background-position: -285px -757px;
    float: left;
    margin-top: 2px;
}
.table tbody tr:hover .table_action .sprite-table-delete-btn {
    background-position: -425px -98px;
}
.table tbody tr:hover .table_action .sprite-table-edit-btn {
    background-position: -425px -128px;
}
.table tbody tr:hover .table_action .sprite-table-validate-btn {
    background-position: -309px -336px;
}
.table tbody tr:hover .table_action .sprite-table-pdf-btn {
    background-position: -309px -366px;
}
.table tbody tr:hover .table_action .sprite-table-recover-btn {
    background-position: -309px -426px;
}
.table tbody tr:hover .table_action .sprite-table-favorite-btn {
    background-position: -309px -396px;
}
.table tbody tr:hover .table_action .sprite-table-plus-btn {
    background-position: -337px -396px;
}
.table tbody tr:hover .table_action .sprite-table-upload-btn {
    background-position: -339px -336px;
}

.table tbody tr.hidden-tr {
    background: #f8f8f8;
    display: none;
}
.table-strong {
    font-weight: 900;
    font-size: 14px;
}
.table-hidden-tr {
    cursor: pointer;
}
.table-hidden-tr.bordered {
    border: 2px solid #00beb0 !important;
}
.table-hidden-tr.bordered .tht-no-border {
    border-top: none;
}
.table-hidden-tr tbody tr {
    cursor: pointer;
}
.tht-cnt .has-hidden-tr {
    background: #fff !important;
}
.tht-cnt .has-hidden-tr:hover {
    background: #fff !important;
}
.tht-cnt .has-hidden-tr td,
.tht-cnt .has-hidden-tr td strong {
    color: #494949 !important;
}
.hidden-tr tr {
    background: #f8f8f8 !important;
    border-color: #f8f8f8 !important;
}
.hidden-tr tr:hover {
    background: #f8f8f8 !important;
}
.tht-td {
    padding: 0 !important;
    border-top: 0 !important;
}
.table_page {
    height: 24px;
    line-height: 24px;
    border: 1px solid #f0f0f0;
    display: inline-block;
    padding: 0 10px;
    -moz-border-radius: 18px;
    -webkit-border-radius: 18px;
    border-radius: 18px;
    vertical-align: middle;
}
.facture_table {
    padding: 10px;
    border-bottom: 1px solid #5a5a5a;
    margin-bottom: 20px;
}
.facture_table table {
    width: 100%;
    background: #fff;
}
.facture_table table th {
    text-align: left;
    padding: 10px;
    font-size: 18px;
    font-weight: 600;
    border-bottom: 2px solid #5a5a5a;
}
.facture_table table td {
    padding: 0 10px;
    border-bottom: 3px solid transparent;
}
.facture_table table td a {
    margin-top: 3px;
}
.facture_table table td.empty {
    width: 10px;
    color: transparent;
}
.facture_table table td.ft_add {
    border-top: 15px solid transparent;
    border-bottom: 15px solid transparent;
}
.facture_apercu_table {
    padding: 10px;
}
.facture_apercu_table table {
    width: 100%;
}
.facture_apercu_table table td,
.facture_apercu_table table th {
    padding: 10px 0;
    font-size: 18px;
}
.facture_apercu_table table th {
    border-bottom: 1px solid #5a5a5a;
    font-weight: 600;
    text-align: left;
}
.tf_select {
    float: left;
    width: 155px;
}
.tf_search {
    float: right;
    width: 155px;
}
.tache_colored {
    width: 20px;
    height: 20px;
    display: inline-block;
    -moz-border-radius: 10px;
    -webkit-border-radius: 10px;
    border-radius: 10px;
}
.tache_colored.rose {
    background: #e7055a;
}
.tache_colored.turquoise {
    background: #05e7da;
}
.tache_colored.yellow {
    background: #e7aa05;
}
.tache_colored.grey {
    background: #444;
}
.taches_name {
    font-weight: 700;
}
.taches_name.rose {
    color: #e7055a;
}
.taches_name.done {
    text-decoration: line-through;
}
.table-badge {
    background: #f6ac32;
    color: #fff;
    padding: 3px 5px;
    text-align: center;
    border-radius: 2px;
    display: inline-block;
}
.fixed {
    top: 0;
    position: fixed;
    width: auto;
    display: none;
    border: none;
}
.sprite-bloc-star {
    display: inline-block;
    width: 17px;
    height: 16px;
    background-position: 0 -2453px;
}
.bloc_h3 {
    position: relative;
    margin-bottom: 15px;
}
.bloc_h3:after {
    visibility: hidden;
    display: block;
    font-size: 0;
    content: " ";
    clear: both;
    height: 0;
}
.bloc_h3 h3 {
    float: left;
    font-size: 14px;
    font-weight: 600;
    padding-right: 10px;
    background: #fff;
    position: relative;
    z-index: 1;
}
.bloc_h3 span {
    width: 100%;
    position: absolute;
    height: 1px;
    background: #8d8d8d;
    left: 0;
    top: 12px;
}
.facture_elem_wrraper,
.form_elem_wrraper {
    padding: 10px;
}
.form_row {
    margin-bottom: 15px;
}
.form_row:after {
    visibility: hidden;
    display: block;
    font-size: 0;
    content: " ";
    clear: both;
    height: 0;
}
.form_row label {
    color: grey;
    font-weight: 600;
}
.form_row label.inline_label {
    width: 130px;
    display: block;
    float: left;
}
.form_row .link {
    font-size: 14px;
    color: #00beb0;
    margin-left: 130px;
}
.inline_input {
    margin-left: 130px;
}
.form_labels label {
    margin-right: 22px;
}
.facture_extra_price:after,
.facture_form:after,
.facture_header:after,
.facture_note_signature:after,
.facture_table:after {
    visibility: hidden;
    display: block;
    font-size: 0;
    content: " ";
    clear: both;
    height: 0;
}
.facture_status {
    float: right;
    width: 300px;
}
.facture_status p {
    margin-bottom: 5px;
}
.facture_status div .tag {
    width: auto;
    float: left;
    margin-right: 5px;
}
.mail-sent {
    font-size: 14px;
    height: 33px;
    line-height: 33px;
}
.mail-sent i {
    margin-bottom: -4px;
}
.fh_title {
    font-size: 66px;
    font-weight: 300;
    color: #00beb0;
    line-height: 48px;
    padding: 10px;
}
.fh_title span {
    display: block;
    font-size: 38px;
    font-weight: 300;
}
.fh_logo {
    width: 274px;
    min-height: 105px;
    line-height: 105px;
    text-align: center;
    font-size: 16px;
    background: #fefefe;
    border: 1px dashed #f0f0f0;
    float: right;
    margin: 10px;
}
.fh_logo img {
    width: 100%;
}
.ff_title {
    font-size: 18px;
    font-weight: 700;
    color: #9b9b9b;
    margin-bottom: 10px;
}
.facture_extra,
.facture_price {
    padding: 10px;
}
.facture_extra_price {
    margin-bottom: 60px;
}
.fe_accordion {
    border-left: 1px solid #bbb;
    border-right: 1px solid #bbb;
    border-bottom: 1px solid #bbb;
    -moz-border-radius: 3px;
    -webkit-border-radius: 3px;
    border-radius: 3px;
    margin-top: 2px;
}
.fe_accordion h3 {
    height: 34px;
    line-height: 32px;
    font-size: 16px;
    background: #f0f0f0;
    padding: 0 10px;
    border-top: 1px solid #bbb;
    -moz-border-radius-topleft: 3px;
    -webkit-border-top-left-radius: 3px;
    border-top-left-radius: 3px;
    -moz-border-radius-topright: 3px;
    -webkit-border-top-right-radius: 3px;
    border-top-right-radius: 3px;
    margin-top: -2px;
}
.fe_accordion .accordion_content {
    display: none;
    border-top: 1px solid #bbb;
}
.fe_accordion .accordion_content textarea {
    width: 100%;
    height: 115px;
    padding: 3px 10px;
}
.facture_price {
    width: 315px;
    float: right;
}
.facture_price table {
    width: 100%;
}
.facture_price table td {
    text-align: right;
    font-size: 18px;
    padding: 7px 10px;
}
.facture_price table td:first-child {
    text-align: left;
    font-weight: 600;
}
.facture_price table .fp_total td {
    background: #e9e9e9;
}
.facture_apercu_wrraper {
    padding: 10px;
    background: #f8f8f8;
}
.facture_row {
    font-size: 18px;
    font-weight: 600;
}
.facture_note {
    width: 360px;
    padding: 10px;
    position: relative;
    margin-top: 5px;
}
.facture_note h3 {
    font-size: 18px;
    font-weight: 600;
}
.facture_note p {
    font-size: 14px;
}
.facture_note span {
    position: absolute;
    width: 36px;
    height: 14px;
}
.facture_note span.top_left {
    border-top: 2px solid #5a5a5a;
    border-left: 2px solid #5a5a5a;
    top: 0;
    left: 0;
}
.facture_note span.top_right {
    border-top: 2px solid #5a5a5a;
    border-right: 2px solid #5a5a5a;
    top: 0;
    right: 0;
}
.facture_note span.bottom_left {
    border-bottom: 2px solid #5a5a5a;
    border-left: 2px solid #5a5a5a;
    bottom: 0;
    left: 0;
}
.facture_note span.bottom_right {
    border-bottom: 2px solid #5a5a5a;
    border-right: 2px solid #5a5a5a;
    bottom: 0;
    right: 0;
}
.facture_signature {
    margin-bottom: 60px;
}
.facture_signature h3 {
    font-size: 18px;
    font-weight: 600;
}
.facture_signature div {
    width: 295px;
    height: 106px;
    background: #e9e9e9;
    margin-top: 10px;
}
.facture_mention_legal {
    padding: 10px;
}
.facture_mention_legal h3 {
    font-size: 18px;
    font-weight: 600;
}
.facture_footer p {
    height: 40px;
    line-height: 40px;
    width: 682px;
    float: left;
    padding: 0 10px;
}
.facture_footer span {
    display: block;
    width: 220px;
    height: 40px;
    line-height: 40px;
    float: right;
    background: #ececec;
    text-align: center;
}
.param_nav > li {
    border-top: 1px solid #d8d8d8;
}
.param_nav > li:first-child {
    border-top: none;
}
.param_nav > li > a {
    display: block;
    padding: 9px;
    overflow: hidden;
    color: #00beb0;
    border: 1px solid transparent;
}
.param_nav > li > a i {
    float: right;
    margin-top: 5px;
}
.param_nav > li > a.selected,
.param_nav > li > a:hover {
    background: #f6f6f6;
}
.param_nav > li ul {
    margin: 5px 0;
    display: none;
}
.param_nav > li ul li {
    margin-bottom: 3px;
}
.param_nav > li ul li a {
    display: block;
    padding: 2px 9px;
    color: #00beb0;
    -moz-border-radius: 2px;
    -webkit-border-radius: 2px;
    border-radius: 2px;
}
.param_nav > li ul li a.selected,
.param_nav > li ul li a:hover {
    color: #494949;
}
.fil_dariane li {
    display: inline;
    float: left;
    font-size: 14px;
    font-weight: 600;
}
.fil_dariane li a {
    font-size: 14px;
    font-weight: 600;
    margin-right: 12px;
}
.fil_dariane li span {
    margin-right: 12px;
}
.param_user_guide {
    padding: 10px 0;
    background: #f6f6f6;
    border: 2px solid #b2b2b2;
    margin-bottom: 20px;
    -moz-border-radius: 2px;
    -webkit-border-radius: 2px;
    border-radius: 2px;
}
.param_user_guide p {
    font-size: 14px;
    font-weight: 600;
    text-align: center;
}
.param_user_guide p a {
    font-size: 14px;
    font-weight: 600;
    color: #00beb0;
}
.param_fields {
    min-height: 243px;
}
.param_fields li {
    height: 36px;
    line-height: 32px;
    font-size: 14px;
    border: 2px solid #f0f0f0;
    margin-bottom: 4px;
    padding: 0 10px;
    position: relative;
    -moz-border-radius: 2px;
    -webkit-border-radius: 2px;
    border-radius: 2px;
}
.param_fields li i {
    position: absolute;
    top: 5px;
    right: 10px;
}
.param_fields li i.sprite-small-drag {
    top: 7px;
    display: none;
}
.param_fields li i.sprite-close-btn-red {
    top: 10px;
}
.param_fields li:hover {
    cursor: pointer;
    border: 2px solid #cbcbcb;
}
.param_fields li:hover .sprite-small-drag {
    display: block;
}
.param_fields li.param_field_locked {
    border: 2px solid #dc0930;
    color: #dc0930;
}
.param_fields li.param_field_empty {
    border: 2px dashed #f0f0f0;
    color: #f0f0f0;
}
.param_fields li.param_field_empty_locked {
    border: 2px dashed #dc0930;
    color: #dc0930;
}
.param_drag_here {
    border: 1px dashed #f0f0f0;
    padding: 60px 0;
    min-height: 247px;
}
.param_drag_here i {
    display: block;
    margin: 0 auto;
}
.param_drag_here p {
    font-size: 16px;
    font-weight: 700;
    text-align: center;
    margin-top: 10px;
}
.param_cf_bloc {
    float: left;
    width: 33.3%;
    padding: 0 20px;
}
.param_cf_header {
    position: relative;
    height: 66px;
    background: #00beb0;
    margin-bottom: 10px;
}
.param_cf_header p {
    font-size: 15px;
    color: #fff;
    padding: 10px 55px 10px 10px;
}
.param_cf_header span {
    font-size: 38px;
    font-weight: 700;
    color: #fff;
    position: absolute;
    bottom: 8px;
    right: 5px;
}
.param_field_type {
    border: 1px solid #b2b2b2;
    padding: 8px 0;
}
.param_field_type li {
    margin-bottom: 8px;
}
.param_field_type li a {
    display: block;
    height: 32px;
    line-height: 32px;
    font-size: 14px;
    font-weight: 300;
    padding: 0 10px;
    position: relative;
}
.param_field_type li a.selected {
    background: #f0f0f0;
}
.param_field_type li a i {
    position: absolute;
    right: 12px;
}
.param_field_type li a i.sprite-search-btn {
    top: 10px;
}
.param_field_form {
    border: 1px solid #b2b2b2;
    padding: 10px 15px;
}
.param_field_apercu {
    border: 1px solid #b2b2b2;
    padding: 60px;
}
.param_field_apercu i {
    display: block;
    margin: 0 auto;
}
.param_field_apercu p {
    font-size: 20px;
    font-weight: 700;
    color: #b2b2b2;
    text-align: center;
    margin-top: 20px;
}
.param_module {
    background: #fff;
    height: 155px;
    -moz-border-radius: 2px;
    -webkit-border-radius: 2px;
    border-radius: 2px;
    -moz-box-shadow: 0 1px 3px 0 #bcbcbc;
    -webkit-box-shadow: 0 1px 3px 0 #bcbcbc;
    box-shadow: 0 1px 3px 0 #bcbcbc;
}
.param_module_left {
    width: 114px;
    float: left;
    height: 155px;
    -moz-border-radius-topleft: 2px;
    -webkit-border-top-left-radius: 2px;
    border-top-left-radius: 2px;
    -moz-border-radius-bottomleft: 2px;
    -webkit-border-bottom-left-radius: 2px;
    border-bottom-left-radius: 2px;
}
.param_module_left.green {
    background: #00f098;
}
.param_module_left.orange {
    background: #f07100;
}
.param_module_left.bleu {
    background: #07ebff;
}
.param_module_left.rose {
    background: #f00066;
}
.param_module_left i {
    display: block;
    margin: 0 auto;
}
.param_module_left i.sprite-stock-green {
    margin-top: 33px;
}
.param_module_left i.sprite-commercial-brown {
    margin-top: 57px;
}
.param_module_left i.sprite-comtabilite-bleu {
    margin-top: 40px;
}
.param_module_left i.sprite-grh-maroon {
    margin-top: 56px;
}
.param_module_right {
    margin-left: 114px;
    padding: 10px;
    height: 155px;
    position: relative;
    -moz-border-radius-topright: 2px;
    -webkit-border-top-right-radius: 2px;
    border-top-right-radius: 2px;
    -moz-border-radius-bottomright: 2px;
    -webkit-border-bottom-right-radius: 2px;
    border-bottom-right-radius: 2px;
}
.param_module_right h3 {
    font-size: 16px;
    font-weight: 600;
}
.param_module_right span {
    font-size: 14px;
    font-weight: 700;
}
.param_module_right .param_btns,
.param_module_right p {
    margin-top: 15px;
}
.param_module_right a {
    float: left;
}
.param_module_right.green h3 {
    color: #00f098;
}
.param_module_right.green span {
    color: #008f5b;
}
.param_module_right.orange h3 {
    color: #f07100;
}
.param_module_right.orange span {
    color: #8b4100;
}
.param_module_right.bleu h3 {
    color: #07ebff;
}
.param_module_right.bleu span {
    color: #00a1af;
}
.param_module_right.rose h3 {
    color: #f00066;
}
.param_module_right.rose span {
    color: #91003e;
}
.param_module_right div {
    overflow: hidden;
}
.param_check {
    float: right;
    margin-top: 4px;
}
.user_detail_row {
    padding: 5px 10px;
    background: #f6f6f6;
    margin-bottom: 5px;
    -moz-border-radius: 2px;
    -webkit-border-radius: 2px;
    border-radius: 2px;
}
.user_detail_row.bigrow {
    height: 150px;
}
.user_detail_row span {
    display: block;
    color: grey;
    font-weight: 600;
}
.user_detail_row p {
    font-size: 14px;
    font-weight: 700;
}
.calendar_header {
    height: 44px;
    padding: 10px 10px 0 10px;
    background: #f8f8f8;
}
.change_month {
    display: inline-block;
}
.change_month span {
    font-size: 16px;
}
.change_month p {
    display: inline-block;
}
.change_month p a {
    vertical-align: middle;
}
.calendar_type {
    display: inline-block;
    margin-right: 4px;
    float: right;
}
.calendar_type a {
    display: inline-block;
    padding: 0 22px;
    height: 25px;
    color: #fff;
    text-align: center;
    line-height: 24px;
    font-weight: 600;
    cursor: pointer;
    background: #00beb0;
    margin-right: -4px;
    border-left: 1px solid #1aa59b;
}
.calendar_type a:first-child {
    border: none;
}
.calendar_type a:hover {
    background: #05ab9f;
}
.calendar_type a:active {
    background: #059187;
}
.calendar_type a.first {
    -moz-border-radius-topleft: 2px;
    -webkit-border-top-left-radius: 2px;
    border-top-left-radius: 2px;
    -moz-border-radius-bottomleft: 2px;
    -webkit-border-bottom-left-radius: 2px;
    border-bottom-left-radius: 2px;
}
.calendar_type a.last {
    -moz-border-radius-topright: 2px;
    -webkit-border-top-right-radius: 2px;
    border-top-right-radius: 2px;
    -moz-border-radius-bottomright: 2px;
    -webkit-border-bottom-right-radius: 2px;
    border-bottom-right-radius: 2px;
}
.calendar_days_left {
    width: 257px;
    float: left;
}
.calendar_days_right {
    margin-left: 260px;
}
.calendar_days_right table {
    width: 100%;
}
.calendar_days_right table td {
    height: 68px;
    border-top: 3px solid #f6f6f6;
    background: #ededed;
    vertical-align: top;
    position: relative;
}
.calendar_days_right table td.cdr_first {
    background: #f6f6f6;
    text-align: center;
    font-size: 14px;
    font-weight: 300;
    padding: 5px;
}
.cdr_event {
    width: 100%;
    height: 65px;
    background: #e7055a;
    padding: 5px;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 99;
}
.cdr_event p,
.cdr_event span {
    font-size: 14px;
    color: #fff;
}
.cdr_event p {
    font-weight: 300;
}
.calendar_days_head {
    background: #00beb0;
    overflow: hidden;
}
.calendar_days_head span {
    font-size: 50px;
    font-weight: 700;
    color: #fff;
    float: left;
    padding: 0 5px;
    width: 70px;
    text-align: center;
}
.calendar_days_head p {
    font-size: 18px;
    color: #fff;
    padding-left: 80px;
}
.calendar_days_head p.cdh_day {
    font-weight: 300;
    padding-top: 10px;
}
.calendar_days {
    overflow: hidden;
}
.calendar_days .calendar_day {
    height: 57px;
    width: 127px;
    float: left;
    background: #f6f6f6;
    margin-top: 3px;
    position: relative;
    cursor: pointer;
    padding: 5px;
    font-size: 18px;
    font-weight: 300;
}
.calendar_days .calendar_day span {
    font-size: 35px;
    font-weight: 300;
    position: absolute;
    right: 5px;
    bottom: 0;
}
.calendar_days .calendar_day:first-child {
    margin-right: 3px;
}
.calendar_days .calendar_day.active {
    color: #00beb0;
}
.calendar_days .calendar_day.active span {
    color: #00beb0;
}
.calendar_months {
    width: 100%;
}
.calendar_months td,
.calendar_months th {
    width: 14%;
    border: 3px solid #fff;
}
.calendar_months th {
    font-size: 18px;
    font-weight: 300;
    text-align: left;
}
.calendar_months td {
    background: #f6f6f6;
    height: 110px;
    vertical-align: top;
    position: relative;
    padding: 5px;
}
.calendar_months td.cm_disabled {
    background: #fff;
}
.calendar_months td.cm_disabled div {
    width: 100%;
    height: 100%;
    border: 1px solid #f6f6f6;
}
.cm_days {
    font-size: 50px;
    font-weight: 300;
    position: absolute;
    bottom: 0;
    right: 10px;
    z-index: 1;
}
.cm_event {
    width: 20px;
    height: 20px;
    margin: 2px 0 2px 2px;
    display: inline-block;
    float: left;
    -moz-border-radius: 10px;
    -webkit-border-radius: 10px;
    border-radius: 10px;
    position: relative;
}
.cm_event:hover > .cm_event_tooltip {
    display: block;
}
.cm_event.bleulight {
    background: #05e7da;
}
.cm_event.yellow {
    background: #e7aa05;
}
.cm_event_tooltip {
    width: 220px;
    padding: 10px;
    background: #fff;
    border: 1px solid #b2b2b2;
    -moz-border-radius: 3px;
    -webkit-border-radius: 3px;
    border-radius: 3px;
    position: absolute;
    top: -73px;
    left: -96px;
    z-index: 2;
    display: none;
}
.cm_event_tooltip p,
.cm_event_tooltip strong {
    font-size: 11px;
}
.cm_event_arrow {
    width: 5px;
    height: 6px;
    position: absolute;
    left: 100px;
    bottom: -6px;
}
.cm_event_arrow i {
    display: block;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 6px 5px 0 5px;
    border-color: #b2b2b2 transparent transparent transparent;
}
.cm_event_arrow span {
    display: block;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 6px 4px 0 4px;
    border-color: #fff transparent transparent transparent;
    position: absolute;
    top: -1px;
    left: 1px;
}
.calendar_week {
    width: 100%;
}
.calendar_week th {
    font-size: 18px;
    font-weight: 300;
    text-align: left;
}
.calendar_week th span {
    width: 100%;
    height: 3px;
    background: #00beb0;
    position: absolute;
    left: 0;
    z-index: 999;
}
.calendar_week td,
.calendar_week th {
    width: 12.5%;
    border-left: 3px solid #fff;
    border-right: 3px solid #fff;
    position: relative;
}
.calendar_week td {
    background: #f6f6f6;
    height: 30px;
}
.calendar_week td .cm_event {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 9999;
}
.calendar_week td.cw_first div {
    height: 100%;
    width: 100%;
    border-right: 2px solid #fff;
}
.calendar_week td.cw_first div p {
    padding: 5px;
}
.calendar_week tr.odd {
    border-bottom: 1px dashed #fff;
}
.calendar_week tr.even {
    border-bottom: 3px solid #fff;
}
.calendar_week tr.cw_head td {
    height: 70px;
    background: #ddd;
}
.tplanning_item_header {
    background: #eee;
    font-size: 14px;
    font-weight: 700;
    padding: 4px 10px;
    margin-bottom: 5px;
}
.tplanning_item_row {
    margin: 0 10px 8px 60px;
    padding: 0 5px;
}
.tplanning_item_row:after {
    visibility: hidden;
    display: block;
    font-size: 0;
    content: " ";
    clear: both;
    height: 0;
}
.tplanning_item_row:hover {
    background: #f7f7f7;
}
.tplanning_item_row span {
    font-size: 14px;
    font-weight: 600;
    float: left;
}
.tplanning_task_name {
    display: inline-block;
    margin-left: 20px;
    font-size: 14px;
    font-weight: 700;
    color: #00beb0;
    float: left;
}
.tplanning_task_name:hover {
    text-decoration: underline;
}
.tplanning_task_options {
    display: inline-block;
    float: right;
}
.general_infos_thumb {
    width: 111px;
    height: 111px;
    display: block;
    float: left;
    -moz-border-radius: 60px;
    -webkit-border-radius: 60px;
    border-radius: 60px;
}
.general_infos_thumb img {
    width: 100%;
    height: 100%;
    -moz-border-radius: 60px;
    -webkit-border-radius: 60px;
    border-radius: 60px;
}
.general_infos_cnt {
    margin-left: 131px;
}
.general_infos_left,
.general_infos_right {
    width: 50%;
    float: left;
    padding: 0 30px;
}
.general_infos_row {
    margin-bottom: 15px;
}
.general_infos_row span {
    font-weight: 600;
    color: grey;
}
.general_infos_row p {
    font-size: 14px;
    font-weight: 700;
}
.colored_content {
    min-height: 150px;
    margin: 15px 0;
}
.colored_content p {
    color: #fff;
    font-size: 14px;
}
.colored_content p.big_txt {
    font-size: 18px;
    font-weight: 700;
}
.colored_content p.mega_txt {
    font-size: 39px;
    font-weight: 700;
}
.colored_content p.total {
    font-size: 54px;
    font-weight: 700;
}
.colored_content p.total_detail {
    font-size: 16px;
}
.sprite-colored-icon {
    position: absolute;
    bottom: 10px;
    right: 10px;
}
.colored_btns {
    float: right;
    margin-top: -18px;
}
.colored_turquoise {
    background: #22b2cc;
    color: #0d859b !important;
}
.colored_rose {
    background: #e7055a;
    color: #860b39 !important;
}
.colored_bleuviolet {
    background: #af05e7;
    color: #630084 !important;
}
.colored_greenspring {
    background: #05e7aa;
    color: #00936b !important;
}
.colored_greenlawn {
    background: #85e705;
    color: #3f6f00 !important;
}
.colored_orange {
    background: #e7aa05;
    color: #7e5c00 !important;
}
.colored_orangered {
    background: #e75005;
    color: #892d00 !important;
}
.colored_red {
    background: #ed1c26;
    color: #892d00 !important;
}
.colored_lime {
    background: #05e705;
    color: #007b00 !important;
}
.colored_bleu {
    background: #054ae7;
    color: #001f67 !important;
}
.colored_peru {
    background: #644c2e;
    color: #291f13 !important;
}
.colored_sandybrown {
    background: #d98f2e;
    color: #83510e !important;
}
.colored_darkgrey {
    background: grey;
    color: #414141 !important;
}
.colored_darkergrey {
    background: #393939;
    color: #161616 !important;
}
.colored_violet {
    background: #d76dff;
    color: #8937a8 !important;
}
.colored_greenyellow {
    background: #cdff6d;
    color: #7a9a3b !important;
}
.colored_sienna {
    background: #da883e;
    color: #7a4b21 !important;
}
.colored_lightskybleu {
    background: #3edad8;
    color: #2c9796 !important;
}
.colored_mediumspringgreen {
    background: #3eda8f;
    color: #28915e !important;
}
.support_search_wrraper {
    padding: 40px 0;
}
.support_search_wrraper p {
    text-align: center;
    font-size: 22px;
    font-weight: 700;
    margin-bottom: 30px;
}
.support_search_wrraper .input_search {
    width: 610px;
    margin: 0 auto;
}
.ticket_items {
    position: relative;
}
.ticket_items .ticket_status {
    top: -49px;
    right: 10px;
}
.ticket_item {
    border: 1px solid #d6d6d6;
    overflow: hidden;
    margin-bottom: 10px;
    background: #f0f0f0;
    -moz-border-radius: 2px;
    -webkit-border-radius: 2px;
    border-radius: 2px;
}
.ticket_item_ref {
    width: 140px;
    height: 80px;
    background: #f0f0f0;
    padding: 20px 8px;
    float: left;
}
.ticket_item_ref a,
.ticket_item_ref p {
    font-size: 18px;
    font-weight: 700;
}
.ticket_item_ref small {
    font-size: 10px;
}
.ticket_item_detail {
    margin-left: 140px;
    position: relative;
    background: #fff;
}
.ticket_item_detail .ticket_item_txt {
    height: 80px;
    line-height: 80px;
    font-size: 14px;
    text-indent: 135px;
}
.ticket_item_detail .ticket_item_txt a {
    font-size: 14px;
}
.ticket_item_detail .ticket_status {
    top: 20px;
    left: -12px;
}
.ticket_item_detail div {
    padding: 10px;
}
.ticket_item_detail div p {
    font-size: 14px;
    font-weight: 600;
    min-height: 34px;
}
.ticket_status {
    display: block;
    width: 114px;
    height: 40px;
    line-height: 40px;
    white-space: nowrap;
    font-size: 14px;
    font-weight: 600;
    color: #fff;
    text-align: center;
    text-transform: uppercase;
    position: absolute;
    z-index: 999;
}
.ticket_status.red {
    background: #f3004a;
}
.ticket_status.green {
    background: #00f35b;
}
.ticket_status.orange {
    background: #f39a00;
}
.ticket_user {
    width: 140px;
    background: #f0f0f0;
    padding: 10px 5px;
    float: left;
}
.ticket_user p {
    font-size: 14px;
    font-weight: 600;
    text-align: center;
}
.ticket_user small {
    font-size: 10px;
    display: block;
    text-align: center;
}
.ticket_close {
    background: #f0f0f0;
    padding: 30px 0;
    text-align: center;
}
.ticket_close p {
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 10px;
    text-align: center;
}
.pagination {
    text-align: right;
}
.pagination a {
    vertical-align: middle;
}
.pagination span {
    height: 24px;
    line-height: 24px;
    border: 1px solid #f0f0f0;
    display: inline-block;
    padding: 0 10px;
    -moz-border-radius: 18px;
    -webkit-border-radius: 18px;
    border-radius: 18px;
    vertical-align: middle;
}
.activites_wrapper {
    background: #f0f0f0;
    border: 1px solid #d1d1d1;
    margin-bottom: 10px;
    overflow: hidden;
}
.activites_left {
    float: left;
    width: 151px;
    padding: 10px;
}
.activites_left em {
    display: block;
    width: 40px;
    height: 40px;
    background: #fff;
    float: left;
    margin-right: 10px;
    -moz-border-radius: 2px;
    -webkit-border-radius: 2px;
    border-radius: 2px;
}
.activites_left em img {
    width: 100%;
    height: 100%;
    -moz-border-radius: 2px;
    -webkit-border-radius: 2px;
    border-radius: 2px;
}
.activites_left div {
    line-height: 12px;
}
.activites_left div p {
    font-weight: 600;
}
.activites_left div span {
    font-size: 10px;
}
.activites_right {
    background: #fff;
    margin-left: 151px;
    padding: 10px;
    min-height: 71px;
    border-left: 1px solid #d1d1d1;
}
.activites_right li,
.activites_right p {
    font-weight: 600;
}
.activites_right li a,
.activites_right p a {
    font-weight: 600;
    color: #00beb0;
}
.activites_right ul {
    list-style: circle;
    margin-left: 15px;
}
.compatible_wrraper {
    min-height: 100%;
    background: #f4f4f4 url(../images/compatible_bg.png) center 60px no-repeat;
}
.compatible_header {
    padding: 114px 0 174px 0;
}
.compatible_header p {
    text-align: center;
    font-size: 14px;
    font-weight: 300;
}
.compatible_header p:first-child {
    font-size: 42px;
    font-weight: 700;
    text-transform: uppercase;
}
.compatible_download {
    font-size: 23px;
    font-weight: 300;
    text-align: center;
    margin-bottom: 30px;
}
.browsers_bloc {
    overflow: hidden;
    width: 668px;
    margin: 0 auto;
}
.browser_bloc {
    width: 152px;
    float: left;
    margin: 0 7px;
}
.browser_bloc .btn_bleu {
    display: none;
}
.browser_bloc:hover .browser_info {
    border: 3px solid #00beb0;
}
.browser_bloc:hover .btn_bleu {
    display: inline-block;
}
.browser_info {
    width: 152px;
    height: 152px;
    background: #fff;
    text-align: center;
    margin-bottom: 8px;
    position: relative;
    border: 3px solid #fff;
    -moz-border-radius: 2px;
    -webkit-border-radius: 2px;
    border-radius: 2px;
}
.browser_info img {
    margin: 16px;
}
.browser_txt {
    width: 152px;
    position: absolute;
    left: 0;
    top: 104px;
}
.browser_txt p {
    font-size: 14px;
    font-weight: 700;
}
.bloc_sub_header {
    height: 28px;
    border-top: 1px solid #dedede;
    border-bottom: 1px solid #dedede;
}
.bloc_sub_header a {
    float: left;
    width: 50%;
    text-align: center;
    margin-top: 4px;
    position: relative;
}
.bloc_sub_header a:first-child {
    border-right: 1px solid #dedede;
}
.bloc_sub_header a span {
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 8px 6px 8px;
    border-color: transparent transparent #dedede transparent;
    position: absolute;
    bottom: -5px;
    left: 50%;
    margin-left: -8px;
}
.bloc_sub_header a span i {
    display: block;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 8px 6px 8px;
    border-color: transparent transparent #fff transparent;
    margin-left: -8px;
    margin-top: 1px;
}
.bloc_search {
    margin: 0 0.5% 10px 0.5%;
    width: 99%;
}
.bloc2 {
    width: 49%;
    float: left;
    margin: 0 0.5%;
}
.bloc2 .input_date {
    margin-bottom: 10px;
}
.graph_detail {
    background: #fafafa;
    -moz-border-radius: 2px;
    -webkit-border-radius: 2px;
    border-radius: 2px;
    padding: 0 5px;
    margin-bottom: 2px;
}
.graph_detail p {
    font-size: 13px;
    font-weight: 300;
    text-align: right;
}
.graph_detail p.bleu {
    color: #6cf;
}
.graph_detail p.orange {
    color: #ffae00;
}
.graph_detail p.green {
    color: #2fc84b;
}
.graph_detail p.red {
    color: #ff000e;
}
.graph_detail span {
    display: block;
    font-size: 13px;
    font-weight: 700;
    text-align: right;
}
.bloc_total {
    background: #fafafa;
    padding: 0 5px;
    -moz-border-radius-topleft: 2px;
    -webkit-border-top-left-radius: 2px;
    border-top-left-radius: 2px;
    -moz-border-radius-topright: 2px;
    -webkit-border-top-right-radius: 2px;
    border-top-right-radius: 2px;
    border-bottom: 2px solid #fff;
}
.bloc_total p {
    text-align: right;
}
.bloc_total p.bt_first {
    font-size: 34px;
    font-weight: 700;
}
.bloc_total p.bt_second {
    font-size: 23px;
    font-weight: 300;
}
.bloc_total p.bt_third {
    font-size: 43px;
    font-weight: 700;
}
.bloc_nonpaye,
.bloc_paye {
    background: #fafafa;
    width: 50%;
    float: left;
    padding: 0 5px;
}
.bloc_paye {
    border-right: 1px solid #fff;
}
.bloc_paye p {
    text-align: right;
    color: #6cf;
}
.bloc_paye span {
    display: block;
    text-align: right;
}
.bloc_nonpaye {
    border-left: 1px solid #fff;
}
.bloc_nonpaye p {
    text-align: right;
    color: #ffae00;
}
.bloc_nonpaye span {
    display: block;
    text-align: right;
}
.setup_link_item {
    width: 50%;
    float: left;
    padding-left: 20px;
    position: relative;
    margin-bottom: 5px;
}
.setup_link_item i {
    position: absolute;
    top: 2px;
    left: 0;
}
.setup_link_item a {
    font-size: 16px;
    font-weight: 700;
}
.setup_link_item a.setup_done {
    font-style: italic;
    text-decoration: line-through;
}
.setup_title {
    color: #fff;
    font-size: 18px;
}
.setup_btn_cnt {
    text-align: center;
    padding: 50px 0;
}
.setup_skip {
    text-align: right;
}
.setup_skip a {
    font-weight: 300;
    color: #fff;
}
.link {
    color: #00beb0;
}
.link:hover {
    text-decoration: underline;
}
.link.delete {
    color: #fe000e;
}
.report_header {
    height: 28px;
    border-top: 1px solid #dedede;
    border-bottom: 1px solid #dedede;
    background: #fafafa;
}
.report_header p {
    line-height: 28px;
    padding: 0 10px;
    font-weight: 600;
}
.report_header p a {
    color: #00beb0;
    font-weight: 600;
}
.report_header p a.report_hlink {
    color: #494949;
    font-weight: 700;
}
.report_graph_details {
    padding: 10px 0;
    border-top: 1px solid #dedede;
}
.report_graph_details:after {
    visibility: hidden;
    display: block;
    font-size: 0;
    content: " ";
    clear: both;
    height: 0;
}
.report_graph_detail {
    width: 25%;
    float: left;
}
.report_graph_detail p {
    text-align: center;
    font-weight: 300;
}
.report_graph_detail span {
    display: block;
    text-align: center;
    font-weight: 600;
}
.chiffre_cle {
    padding: 15px 0;
}
.chiffre_cle span {
    display: block;
    text-align: center;
    font-size: 14px;
}
.chiffre_cle p {
    text-align: center;
    font-size: 18px;
    font-weight: 700;
}
.bloc_links {
    width: 50%;
    float: left;
    margin-bottom: 5px;
}
.bloc_links a {
    font-size: 14px;
}
.bloc_links a:hover {
    text-decoration: underline;
}
.person {
    display: inline-block;
    width: 28px;
    height: 28px;
    background-position: 0 -2176px;
    float: left;
}
.plan-search {
    margin-left: 68px;
    position: relative;
    padding-left: 44px;
}
.plan-search label {
    position: absolute;
    top: 3px;
    left: 0;
}
.plan-filter {
    position: relative;
}
.plan-filter label {
    margin-top: 4px;
    display: inline-block;
}
.plan-filter div {
    margin: 0 !important;
}
.row:after {
    visibility: hidden;
    display: block;
    font-size: 0;
    content: " ";
    clear: both;
    height: 0;
}
.plan-total strong {
    display: block;
    font-size: 16px;
    font-weight: 700;
    text-transform: uppercase;
    margin: 7px 0;
}
.plan-total strong small {
    display: inline-block;
    background: #fff;
    border-radius: 50%;
    padding: 3px 7px;
}
.plan-total span {
    font-size: 12px;
    font-weight: 700;
    color: #8f8f8f;
    line-height: 20px;
}
.plan-price {
    text-align: right;
}
.plan-price strong {
    display: block;
    font-size: 29px;
    font-weight: 700;
    color: #00beb0;
}
.plan-price span {
    font-size: 14px;
    font-weight: 700;
    color: #8f8f8f;
    display: block;
    text-align: left;
}
.plan-remise {
    display: block;
    background: #f6ac32;
    display: inline-block;
    color: #fff;
    padding: 3px;
}
.plan-user-detail {
    background: #fff;
    overflow: hidden;
    margin: -10px -10px 10px;
    padding: 10px;
}
.plan-row {
    position: relative;
    padding: 4px 0;
}
.plan-row .person {
    margin-right: 8px;
}
.plan-row:after {
    visibility: hidden;
    display: block;
    font-size: 0;
    content: " ";
    clear: both;
    height: 0;
}
.plan-row.plan-montant strong {
    font-size: 18px;
    font-weight: 700;
}
.plan-row.plan-montant .montant {
    display: inline-block;
    font-size: 14px;
    border: 1px solid #bbb;
    border-radius: 4px;
    padding: 3px 10px 3px 15px;
}
.plan-row.plan-montant .plan-montant-price {
    font-size: 18px;
    font-weight: 700;
    color: #00beb0;
}
.client {
    font-weight: 700;
    color: #8f8f8f;
    display: inline-block;
}
.user-calcul {
    margin: -10px;
}
.user-calcul .plan-row {
    padding: 10px;
}
.user-calcul .somme {
    background: #f4454c;
}
.user-calcul .somme div {
    font-size: 22px;
    font-weight: 700;
    color: #fff;
}
.user-calcul strong {
    font-size: 22px;
    font-weight: 700;
    color: #8f8f8f;
}
.user-calcul span {
    font-size: 22px;
}
.caret {
    display: inline-block;
    width: 5px;
    height: 9px;
    background-position: 0 -226px;
    margin-left: 5px;
}
.minus {
    display: inline-block;
    width: 22px;
    height: 22px;
    background-position: 0 -2084px;
    margin-right: 50px;
    margin-bottom: -32px;
}
.equal {
    display: inline-block;
    width: 17px;
    height: 11px;
    background-position: 0 -1091px;
    margin-right: 50px;
}
.livre {
    display: inline-block;
    width: 15px;
    height: 16px;
    background-position: 0 -2054px;
}
.espece {
    display: inline-block;
    width: 29px;
    height: 28px;
    background-position: 0 -1116px;
}
.cheque {
    display: inline-block;
    width: 32px;
    height: 32px;
    background-position: 0 -336px;
}
.cb {
    display: inline-block;
    width: 35px;
    height: 27px;
    background-position: 0 -249px;
}
.change {
    display: inline-block;
    width: 27px;
    height: 32px;
    background-position: 0 -290px;
}
.avoir {
    display: inline-block;
    width: 37px;
    height: 37px;
    background-position: 0 -175px;
}
.autre {
    display: inline-block;
    width: 36px;
    height: 32px;
    background-position: 0 -129px;
}
.valide-ico {
    display: inline-block;
    width: 104px;
    height: 103px;
    background-position: 0 -2676px;
}
.plus-ico {
    display: inline-block;
    width: 13px;
    height: 13px;
    background-position: 0 -2314px;
}
.print-ico {
    display: inline-block;
    width: 15px;
    height: 15px;
    background-position: 0 -2341px;
}
.list-ico {
    display: inline-block;
    width: 17px;
    height: 12px;
    background-position: 0 -2028px;
}
.facture-ico {
    display: inline-block;
    width: 15px;
    height: 17px;
    background-position: 0 -1158px;
}
.empty-search-ico {
    display: inline-block;
    width: 70px;
    height: 70px;
    background-position: 0 -1007px;
}
.pay-type {
    display: block;
    text-align: center;
    height: 100px;
    font-weight: 700;
}
.pay-type i {
    margin-top: 26px;
}
.pay-ok {
    height: 230px;
    font-size: 30px;
    font-weight: 700;
    color: #00beb0;
    text-align: center;
}
.pay-ok i {
    margin: 20px 0;
}
.plan-option {
    margin-bottom: 6px;
}
.plan-option:after {
    visibility: hidden;
    display: block;
    font-size: 0;
    content: " ";
    clear: both;
    height: 0;
}
.po-ico {
    height: 35px;
    width: 35px;
    text-align: center;
    float: left;
    background: #00beb0;
}
.po-ico .facture-ico,
.po-ico .list-ico,
.po-ico .plus-ico,
.po-ico .print-ico {
    margin-top: 10px;
}
.po-link {
    margin-left: 40px;
    padding: 0 10px;
    height: 35px;
    line-height: 35px;
    background: #fff;
    -moz-border-radius: 2px;
    -webkit-border-radius: 2px;
    border-radius: 2px;
    -moz-box-shadow: 0 1px 3px 0 #bcbcbc;
    -webkit-box-shadow: 0 1px 3px 0 #bcbcbc;
    box-shadow: 0 1px 3px 0 #bcbcbc;
}
.po-link a {
    text-transform: uppercase;
}
.po-link a:hover {
    text-decoration: underline;
    color: #00beb0;
}
.plan-empty {
    min-height: 470px;
    padding: 70px 20px;
    text-align: center;
}
.plan-empty p {
    font-size: 16px;
    font-weight: 300;
    text-transform: uppercase;
}
.plan-empty span {
    display: inline-block;
    padding: 40px 42px;
    margin: 62px 0;
    border-radius: 50%;
    border: 4px solid #898989;
}
.dash-left1 {
    display: inline-block;
    width: 209px;
    height: 180px;
    background-position: 0 -535px;
}
.dash-left2 {
    display: inline-block;
    width: 209px;
    height: 180px;
    background-position: 0 -729px;
}
.dashboard-bloc .bloc_content {
    min-height: 301px;
}
.dashboard-left-head {
    background: #00beb0;
    padding: 8px;
    text-align: center;
    color: #fff;
    font-size: 12px;
    font-weight: 700;
    -moz-border-radius: 3px;
    -webkit-border-radius: 3px;
    border-radius: 3px;
    position: relative;
}
.dashboard-left-head span {
    background: #e04322;
    height: 20px;
    line-height: 20px;
    padding: 0 8px;
    text-align: center;
    display: inline-block;
    font-size: 11px;
    color: #fff;
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
    border-radius: 50%;
    position: absolute;
    top: 10px;
    right: 20%;
}
.dashboard-left-content {
    text-align: center;
}
.dashboard-left-footer {
    padding: 21px;
    font-size: 12px;
    font-weight: 700;
    background: #f0f0f0;
    margin: 0 -10px -10px -10px;
    height: 57px;
}
.dashboard-left-footer span {
    float: right;
    font-size: 12px;
    font-weight: 700;
}
.dash-slide:after {
    visibility: hidden;
    display: block;
    font-size: 0;
    content: " ";
    clear: both;
    height: 0;
}
.dash-slide-img {
    display: inline-block;
    float: left;
    width: 264px;
    text-align: center;
    height: 244px;
}
.dash-slide-img img {
    display: block;
    max-width: 100%;
    max-height: 100%;
    height: 100%;
    width: 100%;
}
.dash-slide-content {
    margin-left: 264px;
    padding: 20px;
}
.dash-slide-content h2 {
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 8px;
    color: #4a4a4a;
}
.dash-slide-content p {
    color: #4a4a4a;
    margin-bottom: 10px;
}
.dash-slide-btn a {
    float: right;
}
.owl-page {
    display: inline-block;
    width: 13px;
    height: 13px;
    background: #8f8f8f;
    margin-right: 7px;
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
    border-radius: 50%;
}
.owl-page.active {
    background: #00beb0;
}
.owl-pagination {
    position: absolute;
    bottom: -46px;
    left: 10px;
    z-index: 1;
}
.dash-slider-footer {
    background: #f0f0f0;
    height: 57px;
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
}
.dash-services {
    margin: -10px;
    overflow: hidden;
}
.dash-service-bloc {
    background: #f0f0f0;
    padding: 10px 15px;
    text-align: center;
    border-bottom: 1px solid #dedede;
    font-size: 14px;
    color: #8f8f8f;
    height: 42px;
}
.dash-service-bloc i {
    vertical-align: middle;
    margin-left: 10px;
}
.dash-service-bloc h2 {
    font-size: 16px;
    font-weight: 700;
    color: #4a4a4a;
    text-transform: uppercase;
}
.dash-service-tel {
    font-size: 24px;
    font-weight: 700;
    color: #00beb0;
    text-align: center;
    padding: 10px;
    background: #f8f8f8;
    border-bottom: 1px solid #e2e2e2;
}
.dash-head {
    display: inline-block;
    width: 34px;
    height: 32px;
    background-position: 0 -489px;
}
.dash-site {
    display: inline-block;
    width: 21px;
    height: 20px;
    background-position: 0 -923px;
}
.dash-envelope {
    display: inline-block;
    width: 17px;
    height: 10px;
    background-position: 0 -465px;
}
.dash-open-service {
    text-align: center;
    padding: 20px 0;
}
.dash-open-service span {
    font-size: 14px;
    text-align: center;
    display: inline-block;
    height: 41px;
    width: 95%;
    background: #f0f0f0;
    color: #8f8f8f;
    font-size: 14px;
    line-height: 37px;
    position: relative;
    -moz-border-radius-topleft: 3px;
    -webkit-border-top-left-radius: 3px;
    border-top-left-radius: 3px;
    -moz-border-radius-topright: 3px;
    -webkit-border-top-right-radius: 3px;
    border-top-right-radius: 3px;
}
.dash-open-service span.colored {
    background: #00beb0;
    color: #fff;
    -moz-border-radius-topleft: 0;
    -webkit-border-top-left-radius: 0;
    border-top-left-radius: 0;
    -moz-border-radius-topright: 0;
    -webkit-border-top-right-radius: 0;
    border-top-right-radius: 0;
    -moz-border-radius-bottomleft: 3px;
    -webkit-border-bottom-left-radius: 3px;
    border-bottom-left-radius: 3px;
    -moz-border-radius-bottomright: 3px;
    -webkit-border-bottom-right-radius: 3px;
    border-bottom-right-radius: 3px;
}
.dash-open-service span.colored:after {
    border-width: 0;
}
.dash-open-service span i {
    vertical-align: middle;
    margin-right: 8px;
}
.holder {
    background: #fff;
    position: relative;
    margin-bottom: 2%;
    -moz-box-shadow: 0 1px 5px 0 #bdbdbd;
    -webkit-box-shadow: 0 1px 5px 0 #bdbdbd;
    box-shadow: 0 1px 5px 0 #bdbdbd;
}
.holder-head {
    background: #f0f0f0;
    padding: 10px 8px;
}
.holder-head h2 {
    font-size: 12px;
    color: #4a4a4a;
}
.holder-body {
    padding: 10px;
}
.holder-body .container4 {
    overflow: hidden;
}
.holder-body .grid2 {
    margin-bottom: 0;
}
.holder-body img {
    width: 100%;
}
.holder-footer {
    background: #f8f8f8;
    padding: 10px;
}
.holder-footer:after {
    visibility: hidden;
    display: block;
    font-size: 0;
    content: " ";
    clear: both;
    height: 0;
}
.holder-footer .dropdown_btn {
    display: inline-block;
    position: relative;
}
.holder-footer .dropdown_btn i {
    vertical-align: middle;
}
.holder-footer .dropdown_btn i.cog2 {
    margin-right: 5px;
    margin-top: -1px;
}
.holder-footer .dropdown_btn i.down-arrow {
    margin-left: 5px;
}
.hf-item {
    margin-left: 10px;
}
ul.share-tabs {
    overflow: hidden;
}
ul.share-tabs li {
    background: #f0f0f0;
    color: #222;
    display: inline-block;
    height: 40px;
    line-height: 40px;
    cursor: pointer;
    width: 50%;
    float: left;
    font-weight: 700;
    text-align: center;
}
ul.share-tabs li i {
    margin-right: 10px;
}
ul.share-tabs li.current {
    background: #fff;
}
.share-tab-content {
    display: none;
}
.share-tab-content textarea {
    height: 90px;
    width: 100%;
    border: none;
    padding: 15px;
    color: #4a4a4a;
}
.share-tab-content.current {
    display: inherit;
}
.dsh-post-config {
    position: absolute;
    top: 12px;
    right: 10px;
}
.dsh-post-config a i {
    vertical-align: middle;
    margin-left: 4px;
}
.dsh-share-links li {
    display: inline-block;
    margin-right: 5px;
    color: #878787;
}
.dsh-share-links li a {
    color: #878787;
}
.dsh-share-links li i {
    font-size: 16px;
    vertical-align: middle;
    margin-right: 4px;
}
.dsh-share-links.green li i {
    color: #00beb0;
}
.dsh-share-links.grey li i {
    color: #9f9d9d;
}
.dsh-post-head {
    margin-bottom: 10px;
}
.dsh-post-head:after {
    visibility: hidden;
    display: block;
    font-size: 0;
    content: " ";
    clear: both;
    height: 0;
}
.dsh-post-thumb {
    border-radius: 50%;
    margin-right: 8px;
}
.dsh-post-thumb img {
    border-radius: 50%;
}
.dsh-post-owner {
    padding-top: 6px;
}
.dsh-post-owner a {
    font-weight: 700;
}
.dsh-post-img {
    display: block;
    position: relative;
}
.dsh-post-img img {
    width: 100%;
    display: block;
}
.dsh-post-img .badge {
    position: absolute;
    bottom: 3px;
    right: 3px;
    color: #fff;
    background: #00beb0;
    padding: 3px 5px;
}
.dsh-post-img .badge i {
    color: #fff;
    vertical-align: middle;
}
.dsh-post-comments {
    border-top: 1px solid #8d8d8d;
    border-bottom: 1px solid #8d8d8d;
}
.dsh-post-comments .write-comment {
    padding: 10px;
}
.dsh-post-comments .write-comment textarea {
    height: 60px;
    width: 100%;
    color: #5a5a5a;
    font-family: "Open Sans", sans-serif;
    border: 1px solid #bbb;
    padding: 1px 5px;
    margin: 0;
    -moz-border-radius: 3px;
    -webkit-border-radius: 3px;
    border-radius: 3px;
}
.dpc-head {
    background: #f0f0f0;
    padding: 10px;
}
.dpc-head i {
    vertical-align: middle;
    margin-right: 8px;
}
.dpc-body {
    padding: 10px;
    background: #f8f8f8;
}
.dsh-promo {
    margin-top: 10px;
}
.dsh-promo:after {
    visibility: hidden;
    display: block;
    font-size: 0;
    content: " ";
    clear: both;
    height: 0;
}
.dsh-promo ul {
    overflow: hidden;
    -moz-border-radius-topright: 3px;
    -webkit-border-top-right-radius: 3px;
    border-top-right-radius: 3px;
    -moz-border-radius-bottomright: 3px;
    -webkit-border-bottom-right-radius: 3px;
    border-bottom-right-radius: 3px;
    -moz-border-radius-topleft: 3px;
    -webkit-border-top-left-radius: 3px;
    border-top-left-radius: 3px;
    -moz-border-radius-bottomleft: 3px;
    -webkit-border-bottom-left-radius: 3px;
    border-bottom-left-radius: 3px;
}
.dsh-promo li {
    float: left;
    display: inline-block;
    width: 80px;
    padding: 10px;
    background: #f8f8f8;
    border-left: 1px solid #e4e4e4;
    text-align: center;
    font-size: 11px;
    color: #878787;
}
.dsh-promo li:first-child {
    border-left: 0;
}
.dsh-promo li span {
    display: block;
    font-size: 16px;
    font-weight: 700;
    color: #00beb0;
}
.dsh-promo .btn_bleu {
    margin-top: 20px;
}
.badge {
    background: #f2f2f2;
    display: inline-block;
    min-width: 10px;
    padding: 3px 13px;
    font-size: 11px;
    line-height: 1;
    color: #878787;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    border-radius: 10px;
}
.dsh-more {
    display: block;
    padding: 0 22px;
    height: 25px;
    color: #fff;
    text-align: center;
    line-height: 24px;
    font-weight: 600;
    cursor: pointer;
    background: #9f9d9d;
    -moz-border-radius: 2px;
    -webkit-border-radius: 2px;
    border-radius: 2px;
}
.dsh-nav li {
    padding: 5px 0;
}
.dsh-nav li a {
    color: #878787;
    text-transform: capitalize;
}
.dsh-nav li.active a {
    color: #1c4c4c;
}
.dsh-con-user {
    margin-bottom: 3px !important;
}
.connected {
    display: inline-block;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background: #ebebeb;
}
.connected.green {
    background: #65bf7c;
}
.dsh-post-date {
    width: 50px;
    margin-right: 10px;
    margin-bottom: 5px;
}
.dsh-post-date span {
    display: block;
    background: #d44340;
    text-align: center;
    color: #fff;
    font-weight: 700;
    -moz-border-radius-topleft: 2px;
    -webkit-border-top-left-radius: 2px;
    border-top-left-radius: 2px;
    -moz-border-radius-topright: 2px;
    -webkit-border-top-right-radius: 2px;
    border-top-right-radius: 2px;
}
.dsh-post-date div {
    background: #f3f3f3;
    font-size: 18px;
    font-weight: 700;
    text-align: center;
    padding: 3px;
    -moz-border-radius-bottomleft: 2px;
    -webkit-border-bottom-left-radius: 2px;
    border-bottom-left-radius: 2px;
    -moz-border-radius-bottomright: 2px;
    -webkit-border-bottom-right-radius: 2px;
    border-bottom-right-radius: 2px;
}
.dsh-btn {
    width: 23px;
    height: 23px;
    display: inline-block;
    background: #00beb0;
    text-align: center;
    line-height: 29px;
    border-radius: 2px;
}
.pub {
    border: 1px solid #b6b6b6;
    margin-bottom: 2%;
}
.pub img {
    width: 100%;
    display: block;
}
.profile-bg {
    height: 77px;
    background: transparent url(../images/profile-bg.png) no-repeat right top;
}
.dsh-profile-info {
    overflow: hidden;
    margin-top: -48px;
    padding: 10px;
}
.dsh-profile-info img {
    display: inline-block;
    border-radius: 50%;
    float: left;
    border: 3px solid #fff;
}
.dsh-profile-info a,
.dsh-profile-info p {
    color: #4a4a4a;
}
.dsh-profile-info a {
    font-size: 14px;
    font-weight: 700;
}
.dsh-profile-info div {
    padding-top: 43px;
}
.dsh-profile-nbr {
    text-align: center;
    padding-bottom: 10px;
}
.dsh-profile-nbr li {
    display: inline-block;
    font-size: 11px;
    margin-right: 8px;
    color: #878787;
}
.cog {
    display: inline-block;
    width: 12px;
    height: 12px;
    background-position: 0 -382px;
}
.share {
    display: inline-block;
    width: 15px;
    height: 15px;
    background-position: 0 -2424px;
}
.comment-envelope {
    display: inline-block;
    width: 14px;
    height: 13px;
    background-position: 0 -438px;
}
.dsh-comment {
    display: inline-block;
    width: 23px;
    height: 16px;
    background-position: 0 -977px;
}
.user2 {
    display: inline-block;
    width: 15px;
    height: 15px;
    background-position: 0 -2622px;
}
.users {
    display: inline-block;
    width: 15px;
    height: 11px;
    background-position: 0 -2651px;
}
.pen {
    display: inline-block;
    width: 12px;
    height: 14px;
    background-position: 0 -2120px;
}
.imgs {
    display: inline-block;
    width: 15px;
    height: 15px;
    background-position: 0 -1999px;
}
.pen2 {
    display: inline-block;
    width: 14px;
    height: 14px;
    background-position: 0 -2148px;
}
.picture {
    display: inline-block;
    width: 16px;
    height: 13px;
    background-position: 0 -2218px;
}
.cog2 {
    display: inline-block;
    width: 16px;
    height: 16px;
    background-position: 0 -408px;
}
.down-arrow {
    display: inline-block;
    width: 11px;
    height: 6px;
    background-position: 0 -957px;
}
.gs-slide {
    padding: 10px;
    width: 50%;
    float: left;
    border: 1px solid #f0f0f0;
}
.slide-row .gs-slide:first-child {
    border: none;
}
.gs-logo {
    float: left;
    margin-right: 10px;
    border: 5px solid #e4e6e0;
    border-radius: 50%;
    width: 90px;
    height: 90px;
    text-align: center;
    line-height: 123px;
}
.gs-logo img {
    display: inline-block;
    border-radius: 50%;
    max-width: 100%;
}
.gs-content {
    margin-left: 100px;
}
.gs-content a {
    font-size: 16px;
    font-weight: 700;
    display: inline-block;
    margin-bottom: 4px;
    text-transform: uppercase;
}
.gs-content small {
    display: block;
    margin-bottom: 5px;
}
.gs-content .row {
    margin: 0;
}
.gs-row {
    overflow: hidden;
}
.gs-row .grid2 {
    margin-left: 0;
    margin-right: 0;
}
.gs-pag {
    padding: 16px;
}
.group-slider .owl-pagination {
    bottom: -22px;
    left: 44.5%;
}
.group-slider .owl-page {
    width: 43px;
    height: 5px;
    border-radius: 0;
    background: #d4d4d4;
}
.group-slider .owl-page.active {
    background: #00beb0;
}
.facture_apercu_table {
    padding: 10px;
}
.facture_apercu_table table {
    width: 100%;
}
.facture_apercu_table table td,
.facture_apercu_table table th {
    padding: 10px 0;
    font-size: 18px;
    border: 1px solid #5a5a5a;
    text-align: center !important;
}
.facture_apercu_table table th {
    border-bottom: 1px solid #5a5a5a;
    font-weight: 600;
    text-align: left;
}
.bloc {
    background: #fff;
}
.facture_elem_wrraper.right {
    float: right;
}
.facture_extra_price:after,
.facture_form:after,
.facture_header:after,
.facture_note_signature:after,
.facture_table:after {
    visibility: hidden;
    display: block;
    font-size: 0;
    content: " ";
    clear: both;
    height: 0;
}
.fh_title {
    font-size: 66px;
    font-weight: 300;
    color: #00beb0;
    line-height: 48px;
    padding: 10px;
}
.fh_title span {
    display: block;
    font-size: 38px;
    font-weight: 300;
}
.fh_logo {
    width: 274px;
    min-height: 105px;
    line-height: 105px;
    text-align: center;
    font-size: 16px;
    background: #fefefe;
    border: 1px dashed #f0f0f0;
    float: right;
}
.fh_logo img {
    width: 100%;
}
.ff_title {
    font-size: 18px;
    font-weight: 700;
    color: #9b9b9b;
    margin-bottom: 10px;
}
.facture_extra,
.facture_price {
    padding: 10px;
}
.facture_extra_price {
    margin-bottom: 60px;
}
.facture_price {
    width: 315px;
    float: right;
}
.facture_price table {
    width: 100%;
}
.facture_price table td {
    text-align: right;
    font-size: 18px;
    padding: 7px 10px;
}
.facture_price table td:first-child {
    text-align: left;
    font-weight: 600;
}
.facture_price table .fp_total td {
    background: #e9e9e9;
}
.facture_apercu_wrraper {
    padding: 10px;
    background: #f8f8f8;
}
.facture_row {
    font-size: 18px;
    font-weight: 600;
}
.facture_note {
    width: 360px;
    padding: 10px;
    position: relative;
    margin-top: 5px;
}
.facture_note h3 {
    font-size: 18px;
    font-weight: 600;
}
.facture_note p {
    font-size: 14px;
}
.facture_note span {
    position: absolute;
    width: 36px;
    height: 14px;
}
.facture_note span.top_left {
    border-top: 2px solid #5a5a5a;
    border-left: 2px solid #5a5a5a;
    top: 0;
    left: 0;
}
.facture_note span.top_right {
    border-top: 2px solid #5a5a5a;
    border-right: 2px solid #5a5a5a;
    top: 0;
    right: 0;
}
.facture_note span.bottom_left {
    border-bottom: 2px solid #5a5a5a;
    border-left: 2px solid #5a5a5a;
    bottom: 0;
    left: 0;
}
.facture_note span.bottom_right {
    border-bottom: 2px solid #5a5a5a;
    border-right: 2px solid #5a5a5a;
    bottom: 0;
    right: 0;
}
.facture_signature {
    margin-bottom: 60px;
}
.facture_signature h3 {
    font-size: 18px;
    font-weight: 600;
}
.facture_signature div {
    width: 295px;
    height: 106px;
    background: #e9e9e9;
    margin-top: 10px;
}
.facture_mention_legal {
    padding: 10px;
}
.facture_mention_legal h3 {
    font-size: 18px;
    font-weight: 600;
}
.facture_footer p {
    height: 40px;
    line-height: 40px;
    width: 682px;
    float: left;
    padding: 0 10px;
}
.facture_footer span {
    display: block;
    width: 220px;
    height: 40px;
    line-height: 40px;
    float: right;
    background: #ececec;
    text-align: center;
}
.has-tuto {
    overflow: hidden !important;
}
.tuto-wrapper {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.4);
    z-index: 999;
    overflow: hidden;
}
.has-keyboard-shortcut {
    position: relative;
    z-index: 9991;
}
.shortcut {
    position: absolute;
    background: #fff;
    -moz-border-radius: 2px;
    -webkit-border-radius: 2px;
    border-radius: 2px;
    padding: 15px 30px;
    text-transform: uppercase;
    font-size: 24px;
    font-weight: 700;
    -moz-box-shadow: 0 0 9px 0 #656565;
    -webkit-box-shadow: 0 0 9px 0 #656565;
    box-shadow: 0 0 9px 0 #656565;
    top: 30px;
    right: 50%;
}
.shortcut:after {
    content: "";
    display: inline-block;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 9px 8px 9px;
    border-color: transparent transparent #fff transparent;
    position: absolute;
    top: -8px;
    left: 50%;
    margin-left: -9px;
}
.popup {
    position: absolute;
    top: 90px;
    left: 50%;
    margin-left: -186px;
    z-index: 9999;
    width: 370px;
    background: #fff;
    -moz-box-shadow: 0 0 6px 2px #8a8a8a;
    -webkit-box-shadow: 0 0 6px 2px #8a8a8a;
    box-shadow: 0 0 6px 2px #8a8a8a;
    -moz-border-radius: 2px;
    -webkit-border-radius: 2px;
    border-radius: 3px;
}
.popup_top {
    height: 184px;
    background-color: #7953a5;
    border-radius: 3px 3px 0 0;
}
.popup_bottom {
    height: 206px;
    background-color: #603f87;
    border-radius: 0 0 3px 3px;
}
.popup_form_parrainage {
    height: 343px !important;
}
.popup_top img {
    margin-left: 30%;
    margin-top: 20px;
}
.popup_close {
    float: right;
}
.popup_close img {
    margin-left: -13px;
    margin-top: -6px;
}
.popup_bottom h3 {
    font-family: "Open Sans", sans-serif;
    font-size: 16px;
    font-weight: 700;
    text-transform: uppercase;
    text-align: center;
    color: #fff;
    padding-top: 15px;
}
.popup_bottom p {
    font-family: "Open Sans", sans-serif;
    font-size: 12px;
    font-weight: 400;
    text-align: center;
    color: #fff;
    margin-top: 5px;
    padding-left: 18px;
    padding-right: 18px;
}
.champs_code {
    width: 60%;
    vertical-align: middle;
    margin-left: auto;
    margin-right: auto;
    margin-top: 10px;
    margin-bottom: 10px;
}
.popup_btns {
    width: 60%;
    vertical-align: middle;
    margin-left: auto;
    margin-right: auto;
    margin-top: 10px;
}
.btn_yellow {
    display: inline-block;
    padding: 0 30px;
    height: 25px;
    color: #fff;
    text-align: center;
    line-height: 24px;
    font-weight: 600;
    cursor: pointer;
    background: #ffa902;
    -moz-border-radius: 2px;
    -webkit-border-radius: 2px;
    border-radius: 2px;
    border: 1px solid #ffa902;
    float: left;
}
.btn_yellow:hover {
    background: #f69600;
    border: 1px solid #f69600;
}
.btn_green {
    display: inline-block;
    padding: 0 30px;
    height: 25px;
    color: #fff;
    text-align: center;
    line-height: 24px;
    font-weight: 600;
    cursor: pointer;
    background: #00beb0;
    -moz-border-radius: 2px;
    -webkit-border-radius: 2px;
    border-radius: 2px;
    border: 1px solid #00beb0;
    float: right;
}
.btn_green:hover {
    background: #05ab9f;
    border: 1px solid #05ab9f;
}
.input_code {
    border: 1px solid #3d245b;
    background-image: url(../images/codebarre.png);
    background-repeat: no-repeat;
    background-position-x: 195px;
    background-position-y: 5px;
}
.input_code:hover {
    border: 1px solid #ffa902;
    background-image: url(../images/codebarre.png);
    background-repeat: no-repeat;
    background-position-x: 195px;
    background-position-y: 5px;
}
.popup_footer {
    background-color: #4c2f6d;
    height: 41px;
    bottom: 0;
    margin-top: 53px;
    border-radius: 0 0 3px 3px;
}
.popup_footer img {
    float: left;
    margin-left: 10px;
    margin-top: 7px;
    margin-right: 10px;
    display: inline-block;
}
.popup_footer a {
    font-family: "Open Sans", sans-serif;
    font-weight: 700;
    font-size: 12px;
    color: #fff;
    margin-top: 11px;
    display: inline-block;
}
.btn_sobruscode {
    display: inline-block;
    padding: 0 10px;
    height: 25px;
    color: #fff;
    text-align: center;
    line-height: 24px;
    font-weight: 600;
    cursor: pointer;
    background: #7953a5;
    -moz-border-radius: 2px;
    -webkit-border-radius: 2px;
    border-radius: 2px;
    border: 1px solid #7953a5;
    float: right;
}
.btn_sobruscode:hover {
    background: #613d8b;
    border: 1px solid #613d8b;
}
.btn_sobruscode img {
    margin-top: 2px;
    float: left;
    margin-right: 6px;
}
.bloc_content_top {
    background-color: #e24337;
    height: 149px;
}
.Products_tab_content {
    padding: 0;
}
.Products_tab_content img {
    float: left;
    margin-top: 12px;
    margin-left: 20px;
    display: inline-block;
}
.Products_tab_content h3 {
    color: #fff;
    font-size: 16px;
    font-family: "Open Sans", sans-serif;
    font-weight: 700;
    text-transform: uppercase;
    margin-left: 20px;
    margin-top: 4%;
    display: inline-block;
}
.Products_tab_content p {
    color: #fff;
    font-size: 12px;
    font-family: "Open Sans", sans-serif;
    font-weight: 400;
    margin-left: 164px;
    margin-top: 5px;
    text-align: left;
    padding-right: 11px;
}
.table_wrraper {
    padding: 0;
}

.tab_a {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    background-color: #6dc3db;
    padding-top: 4px;
}
.tab_a span {
    color: #fff;
    text-transform: uppercase;
    text-align: center;
    font-size: 16px;
    font-family: "Open Sans", sans-serif;
    font-weight: 700;
    display: inherit;
}
.tab_b {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    background-color: #f6ac32;
    padding-top: 4px;
}
.tab_b span {
    color: #fff;
    text-transform: uppercase;
    text-align: center;
    font-size: 16px;
    font-family: "Open Sans", sans-serif;
    font-weight: 700;
    display: inherit;
}
.tab_c {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    background-color: #e24337;
    padding-top: 4px;
}
.tab_c span {
    color: #fff;
    text-transform: uppercase;
    text-align: center;
    font-size: 16px;
    font-family: "Open Sans", sans-serif;
    font-weight: 700;
    display: inherit;
}
.statistiques li {
    display: inline-block;
    width: 100%;
    height: 29px;
    margin-bottom: 5px;
}
.stat_blue {
    width: 29px;
    height: 29px;
    border-radius: 3px;
    background-color: #6dc3db;
    margin-right: 6px;
    display: inline-block;
    float: left;
    background-image: url(../images/views.png);
    background-repeat: no-repeat;
    background-position-x: 5px;
    background-position-y: 8px;
}
.stat_green {
    width: 29px;
    height: 29px;
    border-radius: 3px;
    background-color: #8ec549;
    margin-right: 6px;
    display: inline-block;
    float: left;
    background-image: url(../images/participations.png);
    background-repeat: no-repeat;
    background-position-x: 6px;
    background-position-y: 4px;
    background-size: 19px;
}
.stat_red {
    width: 29px;
    height: 29px;
    border-radius: 3px;
    background-color: #fd6c6e;
    margin-right: 6px;
    display: inline-block;
    float: left;
    background-image: url(../images/commandes.png);
    background-repeat: no-repeat;
    background-position-x: 5px;
    background-position-y: 4px;
    background-size: 19px;
}
.stat_yellow {
    width: 29px;
    height: 29px;
    border-radius: 3px;
    background-color: #f7ca2e;
    margin-right: 6px;
    display: inline-block;
    float: left;
    background-image: url(../images/price.png);
    background-repeat: no-repeat;
    background-position-x: 5px;
    background-position-y: 5px;
}
.stat_purple {
    width: 29px;
    height: 29px;
    border-radius: 3px;
    background-color: #785ea8;
    margin-right: 6px;
    display: inline-block;
    float: left;
    background-repeat: no-repeat;
    background-position-x: 5px;
    background-position-y: 5px;
}
.stat_content {
    background-color: #f6f6f6;
    width: 90%;
    height: 29px;
    border-radius: 3px;
    margin-left: 34px;
}
.stat_content p {
    color: #4a4a4a;
    font-family: "Open Sans", sans-serif;
    font-weight: 400;
    font-size: 13px;
    display: inline-block;
    margin-top: 4px;
    margin-left: 5px;
}
.dash-open-service a {
    font-size: 14px;
    text-align: center;
    display: inline-block;
    height: 41px;
    width: 95%;
    font-size: 14px;
    line-height: 37px;
    position: relative;
    -moz-border-radius-topleft: 3px;
    -webkit-border-top-left-radius: 3px;
    border-top-left-radius: 3px;
    -moz-border-radius-topright: 3px;
    -webkit-border-top-right-radius: 3px;
    border-top-right-radius: 3px;
}
#dash_time {
    margin-bottom: 9px;
    margin-top: -10px;
    background-color: #f7ad1c;
    color: #fff !important;
    font-size: 14px;
    font-family: "Open Sans", sans-serif;
    border-radius: 3px;
}
#dash_time_icone {
    background-image: url(../images/time.png);
    background-repeat: no-repeat;
    display: inline-block;
    width: 27px;
    height: 31px;
    background-position-y: 1px;
    margin-right: 7px;
    vertical-align: middle;
}
a.tooltip_horaires {
    outline: 0;
}
a.tooltip_horaires strong {
    line-height: 30px;
}
a.tooltip_horaires:hover {
    text-decoration: none;
}
a.tooltip_horaires label {
    text-align: left;
    z-index: 10;
    display: none;
    padding: 8px 16px;
    margin-top: -30px;
    margin-left: 28px;
    width: 260px;
    line-height: 16px;
    padding-top: 4px;
}
a.tooltip_horaires:hover label {
    display: inline;
    position: absolute;
    color: #111;
    border: 1px solid #636363;
    background: #fff;
    margin-left: -234px;
    margin-top: -106px;
}
.callout {
    z-index: 20;
    position: absolute;
    top: 104px;
    border: 0;
    left: 31px;
}
a.tooltip_horaires label {
    border-radius: 5px;
    box-shadow: 3px 4px 19px -6px rgba(0, 0, 0, 0.75);
}
#ecart_green {
    background-color: #00beb0;
}
#ecart_red {
    background-color: #f4454c;
}
.ecart td {
    color: #fff;
    text-transform: capitalize;
}
.ecart strong {
    color: #fff;
    font-size: 18px;
    font-weight: 700;
    text-align: right;
    float: right;
    margin-right: 50px;
}
.ventillation_total {
    width: 207px;
}
.ventillation_tva {
    width: 714px;
}
.table .montant_arrondi:hover {
    background: #fff;
}
.table tbody .montant_arrondi:hover td {
    color: #494949;
}
.input_montant {
    width: 157px;
    float: right;
    margin-right: 6px;
}
.ads_table {
    background-color: #e7e7e7;
    height: 110px;
}
.ads_bloc {
    height: auto;
    margin-top: 5px;
    border-radius: 5px;
    background-color: #fff;
    padding-bottom: 10px;
    display: inline-block;
    margin-left: 2px;
    margin-right: 2px;
}
.ads1 {
    width: 33%;
    display: inline-block;
}
.ads2 {
    width: 33%;
    display: inline-block;
}
.ads3 {
    width: 33%;
    display: inline-block;
}
.ads_bloc img {
    float: left;
    display: inline-block;
    margin-top: 10px;
    margin-left: 10px;
    margin-right: 20px;
}
.ads_title {
    color: #525252;
    font-size: 16px;
    font-family: "Open Sans", sans-serif;
    font-weight: 700;
    margin-top: 10px;
    display: inherit;
}
.ads_bloc p {
    color: #525252;
    font-size: 12px;
    font-family: "Open Sans", sans-serif;
    font-weight: 400;
}
.btn_remise {
    border-radius: 3px;
    background-color: #d44344;
    transition: all ease-in-out 0.2s;
}
.btn_profite {
    border-radius: 3px;
    background-color: #f9c553;
    color: #fff;
    transition: all ease-in-out 0.2s;
}
.btn_remise:hover {
    border-radius: 3px;
    background-color: #ab3434;
    transition: all ease-in-out 0.2s;
}
.btn_profite:hover {
    border-radius: 3px;
    background-color: #eea507;
    color: #fff;
    transition: all ease-in-out 0.2s;
}
.ads_bloc ul {
    float: right;
    margin-right: 20px;
}
.ads_bloc li {
    display: inline-block;
    margin-left: 10px;
    text-align: center;
}
.btn_remise a {
    width: 127px;
    height: 32px;
    font-family: "Open Sans", sans-serif;
    font-size: 14px;
    font-weight: 700;
    text-align: center;
    padding-top: 6px;
    display: inherit;
    margin-top: 0;
    color: #fff;
}
.btn_profite a {
    width: 127px;
    height: 32px;
    font-family: "Open Sans", sans-serif;
    font-size: 14px;
    font-weight: 700;
    text-align: center;
    padding-top: 6px;
    display: inherit;
    margin-top: 0;
    color: #fff;
}
@media (max-width: 1900px) {
    .ads1 {
        width: 49.3%;
        margin-right: 3px;
    }
    .ads2 {
        width: 49.3%;
    }
    .ads3 {
        display: none;
    }
    .ads_bloc ul {
        float: right;
        margin-right: 20px;
        margin-top: 5px;
    }
}
@media (max-width: 1200px) {
    .ads1 {
        width: 49.3%;
        margin-right: 3px;
    }
    .ads2 {
        width: 49.3%;
    }
    .ads3 {
        display: none;
    }
    .ads_bloc ul {
        float: right;
        margin-right: 20px;
        margin-top: 0;
    }
}
.popup_BC {
    position: absolute;
    top: 90px;
    left: 50%;
    margin-left: -295px;
    z-index: 9999;
    width: 575px;
    background: #fff;
    -moz-box-shadow: 0 0 6px 2px #8a8a8a;
    -webkit-box-shadow: 0 0 6px 2px #8a8a8a;
    box-shadow: 0 0 6px 2px #8a8a8a;
    -moz-border-radius: 2px;
    -webkit-border-radius: 2px;
    border-radius: 3px;
}
.popup_BC_top {
    height: 184px;
    background-color: #00beb0;
    border-radius: 3px 3px 0 0;
}
.popup_BC_top img {
    margin-left: 4%;
    margin-top: 20px;
    float: left;
    display: inline-block;
    margin-right: 15px;
}
.popup_close {
    float: right;
}
.popup_close img {
    margin-left: -13px;
    margin-top: 11px;
}
.popup_BC_top h2 {
    font-family: "Open Sans", sans-serif;
    font-size: 16px;
    font-weight: 700;
    color: #fff;
    text-transform: uppercase;
    padding-top: 50px;
}
.popup_BC_top p {
    font-family: "Open Sans", sans-serif;
    font-size: 12px;
    font-weight: 400;
    color: #fff;
}
.popup_BC_bottom {
    height: 323px;
    background-color: #f0f0f0;
    border-radius: 0 0 3px 3px;
}
.bloc_bc {
    width: 264px;
    height: 139px;
    border-radius: 5px;
    background-color: #fff;
    display: inline-block;
    margin-left: 15px;
    margin-top: 15px;
    float: left;
    padding-left: 10px;
}
.photo_bc {
    background-color: #efefef;
    width: 82px;
    height: 82px;
    border-radius: 5px;
    margin-top: 10px;
    float: left;
    display: inline-block;
    margin-right: 10px;
}
.photo_bc img {
    float: left;
    width: 82px;
    height: 82px;
}
.bloc_bc h3 {
    font-family: "Open Sans", sans-serif;
    font-size: 12px;
    font-weight: 400;
    text-transform: uppercase;
    color: #4a4a4a;
    margin-top: 10px;
}
.bloc_bc p {
    font-family: "Open Sans", sans-serif;
    font-size: 12px;
    font-weight: 400;
    color: #4a4a4a;
    margin-top: 4px;
}
.bc_remise {
    width: 90px;
    height: 22px;
    border-radius: 3px;
    background-color: #f4ac2f;
    display: inline-block;
    margin-top: 4px;
    text-align: center;
    padding-top: 1px;
}
.bloc_bc span {
    font-family: "Open Sans", sans-serif;
    font-size: 11px;
    font-weight: 700;
    color: #fff;
    margin-top: 5px;
}
.bc_btn {
    width: 243px;
    height: 32px;
    border-radius: 3px;
    background-color: #45bbbc;
    color: #fff;
    font-family: "Open Sans", sans-serif;
    font-size: 12px;
    font-weight: 700;
    text-align: center;
    margin-top: 8px;
    float: left;
    padding-bottom: 8px;
    padding-top: 6px;
    padding-left: 50px;
    padding-right: 50px;
    transition: all ease-in-out 0.2s;
}
.bc_btn:hover {
    width: 243px;
    height: 32px;
    border-radius: 3px;
    background-color: #d44344;
    color: #fff;
    font-family: "Open Sans", sans-serif;
    font-size: 12px;
    font-weight: 700;
    text-align: center;
    margin-top: 8px;
    float: left;
    padding-bottom: 8px;
    padding-top: 6px;
    padding-left: 50px;
    padding-right: 50px;
    transition: all ease-in-out 0.2s;
}
.bloc_fidelite {
    height: 112px;
    background-color: #57c8f8;
    background-image: url(../images/fidelite_icone.png);
    background-repeat: no-repeat;
    background-position-x: right;
    background-position-y: 6px;
    background-size: 130px;
}
.bloc_fidelite h4 {
    color: #fff;
    font-family: "Open Sans", sans-serif;
    font-weight: 700;
    font-size: 30px;
    margin-bottom: -5px;
}
.bloc_fidelite span {
    color: #fff;
    font-family: "Open Sans", sans-serif;
    font-weight: 400;
    font-size: 15px;
    float: left;
    width: 100%;
}
.accord_content {
    display: block;
}
.accord {
    height: 50px;
    background-color: #fff;
    box-shadow: 0 1px 3px 0 #bcbcbc;
    display: inline-block;
    cursor: pointer;
    margin-bottom: 15px;
    width: 100%;
}
.accord_left {
    height: 100%;
    width: 77px;
    float: left;
    text-align: center;
}
.accord_left span {
    color: #fff;
    font-family: "Open Sans", sans-serif;
    font-weight: 700;
    font-size: 16px;
    margin-left: -10px;
    margin-top: 12px;
    display: inline-block;
}
.r_principaux .accord_left {
    border-left: 8px solid #3d98b3;
    background-color: #5bc0de;
    background-image: url(../images/arrow_rapport.png);
    background-position: right;
    background-repeat: no-repeat;
}
.r_principaux img {
    margin-left: 13px;
    margin-top: 10px;
    width: 29px;
}
.accord h3 {
    font-family: "Open Sans", sans-serif;
    font-size: 13px;
    color: #4a4a4a;
    font-weight: 600;
    margin-left: 10px;
    margin-top: 13px;
    display: inline-block;
}
.r_principaux .accord_content {
    width: 100%;
    background-color: #fff;
    border-top: 1px solid #8d8d8d;
    border-left: 8px solid #3d98b3;
    padding-top: 10px;
    padding-left: 10px;
    margin-bottom: 10px;
    box-shadow: 0 1px 3px 0 #bcbcbc;
}
.accord_content ul {
    list-style-image: url(../images/rapport_content_ul.png);
    margin-left: 15px;
}
.accord_content li {
    font-family: "Open Sans", sans-serif;
    font-size: 12px;
    color: #525252;
    font-weight: 400;
    margin-bottom: 8px;
    transition: all ease-in-out 0.2s;
}
.accord_content_left {
    width: 50%;
    float: left;
}
.accord_content_right {
    width: 50%;
    float: right;
}
.r_chiffres .accord_left {
    border-left: 8px solid #429542;
    background-color: #60c560;
    background-image: url(../images/arrow_rapport.png);
    background-position: right;
    background-repeat: no-repeat;
}
.r_chiffres img {
    margin-left: 16px;
    margin-top: 10px;
    width: 29px;
}
.r_chiffres .accord_content {
    width: 100%;
    background-color: #fff;
    border-top: 1px solid #8d8d8d;
    border-left: 8px solid #429542;
    padding-top: 10px;
    padding-left: 10px;
    margin-bottom: 10px;
    box-shadow: 0 1px 3px 0 #bcbcbc;
}
.r_ventes .accord_left {
    border-left: 8px solid #cd781e;
    background-color: #f59b3b;
    background-image: url(../images/arrow_rapport.png);
    background-position: right;
    background-repeat: no-repeat;
}
.r_ventes img {
    margin-left: 16px;
    margin-top: 10px;
    width: 29px;
}
.r_ventes .accord_content {
    width: 100%;
    background-color: #fff;
    border-top: 1px solid #8d8d8d;
    border-left: 8px solid #cd781e;
    padding-top: 10px;
    padding-left: 10px;
    margin-bottom: 10px;
    box-shadow: 0 1px 3px 0 #bcbcbc;
}
.r_paiement .accord_left {
    border-left: 8px solid #ad3e3a;
    background-color: #d9534f;
    background-image: url(../images/arrow_rapport.png);
    background-position: right;
    background-repeat: no-repeat;
}
.r_paiement img {
    margin-left: 16px;
    margin-top: 10px;
    width: 29px;
}
.r_paiement .accord_content {
    width: 100%;
    background-color: #fff;
    border-top: 1px solid #8d8d8d;
    border-left: 8px solid #ad3e3a;
    padding-top: 10px;
    padding-left: 10px;
    margin-bottom: 10px;
    box-shadow: 0 1px 3px 0 #bcbcbc;
}
.r_client .accord_left {
    border-left: 8px solid #52488d;
    background-color: #8175c7;
    background-image: url(../images/arrow_rapport.png);
    background-position: right;
    background-repeat: no-repeat;
}
.r_client img {
    margin-left: 16px;
    margin-top: 10px;
    width: 29px;
}
.r_client .accord_content {
    width: 100%;
    background-color: #fff;
    border-top: 1px solid #8d8d8d;
    border-left: 8px solid #52488d;
    padding-top: 10px;
    padding-left: 10px;
    margin-bottom: 10px;
    box-shadow: 0 1px 3px 0 #bcbcbc;
}
.r_comptables .accord_left {
    border-left: 8px solid #af2d67;
    background-color: #d94084;
    background-image: url(../images/arrow_rapport.png);
    background-position: right;
    background-repeat: no-repeat;
}
.r_comptables img {
    margin-left: 16px;
    margin-top: 10px;
    width: 29px;
}
.r_comptables .accord_content {
    width: 100%;
    background-color: #fff;
    border-top: 1px solid #8d8d8d;
    border-left: 8px solid #af2d67;
    padding-top: 10px;
    padding-left: 10px;
    margin-bottom: 10px;
    box-shadow: 0 1px 3px 0 #bcbcbc;
}
.r_achats .accord_left {
    border-left: 8px solid #30948e;
    background-color: #45b6af;
    background-image: url(../images/arrow_rapport.png);
    background-position: right;
    background-repeat: no-repeat;
}
.r_achats img {
    margin-left: 16px;
    margin-top: 8px;
    width: 29px;
}
.r_achats .accord_content {
    width: 100%;
    background-color: #fff;
    border-top: 1px solid #8d8d8d;
    border-left: 8px solid #30948e;
    padding-top: 10px;
    padding-left: 10px;
    margin-bottom: 10px;
    box-shadow: 0 1px 3px 0 #bcbcbc;
}
.r_stock .accord_left {
    border-left: 8px solid #c1940c;
    background-color: #ecbc29;
    background-image: url(../images/arrow_rapport.png);
    background-position: right;
    background-repeat: no-repeat;
}
.r_stock img {
    margin-left: 17px;
    margin-top: 10px;
    width: 29px;
}
.r_stock .accord_content {
    width: 100%;
    background-color: #fff;
    border-top: 1px solid #8d8d8d;
    border-left: 8px solid #c1940c;
    padding-top: 10px;
    padding-left: 10px;
    margin-bottom: 10px;
    box-shadow: 0 1px 3px 0 #bcbcbc;
}
.r_fournisseurs .accord_left {
    border-left: 8px solid #c33940;
    background-color: #f3565d;
    background-image: url(../images/arrow_rapport.png);
    background-position: right;
    background-repeat: no-repeat;
}
.r_fournisseurs img {
    margin-left: 16px;
    margin-top: 10px;
    width: 29px;
}
.r_fournisseurs .accord_content {
    width: 100%;
    background-color: #fff;
    border-top: 1px solid #8d8d8d;
    border-left: 8px solid #c33940;
    padding-top: 10px;
    padding-left: 10px;
    margin-bottom: 10px;
    box-shadow: 0 1px 3px 0 #bcbcbc;
}
.r_produits .accord_left {
    border-left: 8px solid #75913b;
    background-color: #a5c562;
    background-image: url(../images/arrow_rapport.png);
    background-position: right;
    background-repeat: no-repeat;
}
.r_produits img {
    margin-left: 16px;
    margin-top: 10px;
    width: 29px;
}
.r_produits .accord_content {
    width: 100%;
    background-color: #fff;
    border-top: 1px solid #8d8d8d;
    border-left: 8px solid #75913b;
    padding-top: 10px;
    padding-left: 10px;
    margin-bottom: 10px;
    box-shadow: 0 1px 3px 0 #bcbcbc;
}
.accordion .accord_title:after {
    content: "";
    display: inline-block;
    position: relative;
    float: right;
    margin-right: 15px;
    margin-top: 10px;
    background: url(../images/icon-arrow-up-accord.png);
    width: 27px;
    height: 27px;
}
.accordion .accord_title.in:after {
    background: url(../images/icon-arrow-down-accord.png);
    display: inline-block;
    position: relative;
    float: right;
    margin-right: 15px;
    margin-top: 10px;
}
.accord_title {
    height: 100%;
}
.accord_content_right a:hover {
    color: #1abaae;
    transition: all ease-in-out 0.2s;
}
.accord_content_left a:hover {
    color: #1abaae;
    transition: all ease-in-out 0.2s;
}
.switch_user {
    height: 34px;
    width: 34px;
    border-radius: 50%;
    background-color: #f6ac32;
    border: 1px solid #fff;
    display: inline-block;
    float: left;
    background-image: url(../images/user_switch.png);
    background-repeat: no-repeat;
    background-position-x: 6px;
    background-position-y: 6px;
    transition: all ease-in-out 0.2s;
}
.switch_user:hover {
    height: 34px;
    width: 34px;
    border-radius: 50%;
    background-color: #03a79b;
    border: 1px solid #fff;
    display: inline-block;
    float: left;
    background-image: url(../images/user_switch.png);
    background-repeat: no-repeat;
    background-position-x: 6px;
    background-position-y: 6px;
    transition: all ease-in-out 0.2s;
}
.header_profil_infos {
    float: left;
    margin: 6px 10px 0 10px;
}
.profil_arrow {
    width: 8px;
    height: 5px;
    position: relative;
    float: right;
    margin-top: 2px;
}
.header_profil_infos span {
    margin-top: -3px;
}
.profil_wrraper {
    padding: 7px 10px 0 20px;
}
.popup_top_users {
    height: 184px;
    background-color: #00beb0;
    border-radius: 3px 3px 0 0;
}
.popup_bottom_users {
    height: 233px;
    padding-top: 10px;
    background-color: #fff;
    border-radius: 0 0 3px 3px;
}
.popup_top_users img {
    margin-left: 30%;
    margin-top: 20px;
}
.popup_top_users .popup_close {
    float: right;
}
.popup_top_users .popup_close img {
    margin-left: -13px;
    margin-top: -6px;
}
.popup_bottom_users h3 {
    color: #525252;
    font-size: 15px;
    font-weight: 700;
    text-align: center;
    text-transform: uppercase;
}
.popup_bottom_users p {
    color: #525252;
    font-size: 12px;
    font-weight: 400;
    text-align: center;
    margin-top: 5px;
    margin-left: 10px;
    margin-right: 10px;
}
.popup_bottom_users form {
    width: 80%;
    vertical-align: middle;
    margin-right: auto;
    margin-left: auto;
    margin-top: 10px;
}
.popup_bottom_users label {
    color: #525252;
    font-size: 12px;
    font-weight: 700;
}
.popup_bottom_users .selectbox {
    margin-top: 8px;
    padding-left: 5px;
}
.popup_bottom_users .input {
    margin-top: 8px;
}
.popup_bottom_users .btn_green {
    margin-bottom: 15px;
    margin-top: 5px;
}
.popup_switch {
    position: absolute;
    top: 90px;
    left: 50%;
    margin-left: -186px;
    z-index: 9999;
    height: 409px;
    width: 370px;
    background: #fff;
    -moz-box-shadow: 0 0 6px 2px #8a8a8a;
    -webkit-box-shadow: 0 0 6px 2px #8a8a8a;
    box-shadow: 0 0 6px 2px #8a8a8a;
    -moz-border-radius: 2px;
    -webkit-border-radius: 2px;
    border-radius: 3px;
}
.form_row_switch {
    margin-bottom: 6px;
}
.switch_user {
    height: 34px;
    width: 34px;
    border-radius: 50%;
    background-color: #f6ac32;
    border: 1px solid #fff;
    display: inline-block;
    float: left;
    background-image: url(../images/user_switch.png);
    background-repeat: no-repeat;
    background-position-x: 6px;
    background-position-y: 6px;
    transition: all ease-in-out 0.2s;
}
.switch_user:hover {
    height: 34px;
    width: 34px;
    border-radius: 50%;
    background-color: #03a79b;
    border: 1px solid #fff;
    display: inline-block;
    float: left;
    background-image: url(../images/user_switch.png);
    background-repeat: no-repeat;
    background-position-x: 6px;
    background-position-y: 6px;
    transition: all ease-in-out 0.2s;
}
.header_profil_infos {
    float: left;
    margin: 6px 10px 0 10px;
}
.profil_arrow {
    width: 8px;
    height: 5px;
    position: relative;
    float: right;
    margin-top: 2px;
}
.profil_wrraper {
    padding: 7px 10px 0 20px;
}
.popup_top_satisfaction {
    height: 184px;
    background-color: #fdd737;
    border-radius: 3px 3px 0 0;
}
.popup_bottom_satisfaction {
    height: 172px;
    padding-top: 20px;
    background-color: #fff;
    border-radius: 0 0 3px 3px;
}
.popup_top_satisfaction img {
    margin-left: 25%;
    margin-top: 20px;
}
.popup_top_satisfaction .popup_close {
    float: right;
}
.popup_top_satisfaction .popup_close img {
    margin-left: -13px;
    margin-top: -6px;
}
.popup_bottom_satisfaction h3 {
    color: #525252;
    font-size: 15px;
    font-weight: 700;
    text-align: center;
    text-transform: uppercase;
}
.popup_bottom_satisfaction p {
    color: #525252;
    font-size: 12px;
    font-weight: 400;
    text-align: center;
    margin-top: 5px;
    padding-left: 10px;
    padding-right: 10px;
}
.popup_satisfaction {
    position: absolute;
    top: 90px;
    left: 50%;
    margin-left: -186px;
    z-index: 9999;
    height: 359px;
    width: 370px;
    background: #fff;
    -moz-box-shadow: 0 0 6px 2px #8a8a8a;
    -webkit-box-shadow: 0 0 6px 2px #8a8a8a;
    box-shadow: 0 0 6px 2px #8a8a8a;
    -moz-border-radius: 2px;
    -webkit-border-radius: 2px;
    border-radius: 3px;
}
.popup_bottom_satisfaction ul {
    vertical-align: middle;
    margin-right: auto;
    margin-left: auto;
    width: 335px;
    margin-top: 20px;
}
.satisfaction_btns {
    display: inline-block;
}
.satisfaction_btns a {
    color: #fff;
    font-size: 12px;
    font-weight: 400;
    text-align: center;
    margin-right: 2px;
    margin-left: 2px;
}
.btn_non_satisfait {
    background-color: #d01d15;
    padding: 9px 10px 9px 10px;
    border-radius: 3px;
    transition: all ease-in-out 0.2s;
}
.btn_moyennement {
    background-color: #1fbbde;
    padding: 9px 10px 9px 10px;
    border-radius: 3px;
    transition: all ease-in-out 0.2s;
}
.btn_satisfait {
    background-color: #5cb54b;
    padding: 9px 10px 9px 10px;
    border-radius: 3px;
    transition: all ease-in-out 0.2s;
}
.btn_non_satisfait:hover {
    background-color: #ad160f;
    padding: 9px 10px 9px 10px;
    border-radius: 3px;
    transition: all ease-in-out 0.2s;
}
.btn_moyennement:hover {
    background-color: #119ebe;
    padding: 9px 10px 9px 10px;
    border-radius: 3px;
    transition: all ease-in-out 0.2s;
}
.btn_satisfait:hover {
    background-color: #479837;
    padding: 9px 10px 9px 10px;
    border-radius: 3px;
    transition: all ease-in-out 0.2s;
}
.popup_bottom_satisfaction input {
    width: 85%;
    margin-left: 29px;
    margin-top: 10px;
    height: 63px;
    padding-bottom: 35px;
}
.popup_bottom_satisfaction .btn_green {
    margin-top: 10px;
    margin-right: 27px;
}
.popup_bottom_satisfaction h2 {
    color: #00beb0;
    font-size: 23px;
    font-weight: 700;
    text-align: center;
    text-transform: uppercase;
}
.popup_bottom_satisfaction .btn_end {
    margin-right: 126px;
    margin-top: 20px;
}
.lookup {
    background-image: url(../images/lookup.png);
    background-position: right;
    background-repeat: no-repeat;
    display: inline-block;
}
.disp_neg {
    padding: 3px 10px 3px 10px;
    border-radius: 3px;
    background-color: #e7055a;
    color: #fff;
}
.disp_zero {
    padding: 3px 10px 3px 10px;
    border-radius: 3px;
    background-color: #f7ad1c;
    color: #fff;
}
.disp_pos {
    padding: 3px 10px 3px 10px;
    border-radius: 3px;
    background-color: #1bb9ad;
    color: #fff;
}
.ds_complete {
    background-color: #10cc83;
    height: 40px;
}
.devis_statut h3 {
    color: #fff;
    font-family: "Open Sans", sans-serif;
    font-size: 14px;
    font-weight: 600;
    float: left;
}
.ds_brouillon {
    background-color: #e7aa05;
    height: 40px;
}
.ds_annule {
    background-color: #e7055a;
    height: 40px;
}
.notification {
    width: 100%;
    height: 50px;
}
.notif_succes {
    background-color: #cdeaca;
    border-bottom: 1px solid #02958a;
    padding-top: 15px;
}
.notif_succes p {
    font-size: 12px;
    font-family: "Open Sans", sans-serif;
    font-weight: 400;
    color: #3a7824;
    text-align: center;
}
.notif_succes img {
    float: left;
    margin-top: -7px;
}
.notif_succes_content {
    width: 325px;
    vertical-align: middle;
    margin-right: auto;
    margin-left: auto;
}
.notif_error_content {
    width: 415px;
    vertical-align: middle;
    margin-right: auto;
    margin-left: auto;
}
.notif_error {
    background-color: #f2dedf;
    border-bottom: 1px solid #02958a;
    padding-top: 15px;
}
.notif_error p {
    font-size: 12px;
    font-family: "Open Sans", sans-serif;
    font-weight: 400;
    color: #ba4a46;
    text-align: center;
}
.notif_error img {
    float: left;
    margin-top: -7px;
}
.header_profil_infos {
    float: left;
    margin: 6px 10px 0 10px;
}
.profil_arrow {
    width: 8px;
    height: 5px;
    position: relative;
    float: right;
    margin-top: 2px;
}
.header_profil_infos span {
    margin-top: -3px;
}
.profil_wrraper {
    padding: 7px 10px 0 20px;
}
.popup_top_carte {
    height: 184px;
    background-color: #00beb0;
    border-radius: 3px 3px 0 0;
}
.popup_bottom_carte {
    height: 233px;
    padding-top: 10px;
    background-color: #fff;
    border-radius: 0 0 3px 3px;
}
.popup_top_carte img {
    margin-left: 16%;
    margin-top: 70px;
}
.popup_top_carte .popup_close {
    float: right;
}
.popup_top_carte .popup_close img {
    margin-left: -13px;
    margin-top: -6px;
}
.popup_bottom_carte h3 {
    color: #525252;
    font-size: 15px;
    font-weight: 700;
    text-align: center;
    text-transform: uppercase;
}
.popup_bottom_carte p {
    color: #525252;
    font-size: 12px;
    font-weight: 400;
    text-align: center;
    margin-top: 5px;
    margin-left: 10px;
    margin-right: 10px;
}
.popup_bottom_carte form {
    width: 80%;
    vertical-align: middle;
    margin-right: auto;
    margin-left: auto;
    margin-top: 10px;
}
.popup_bottom_carte label {
    color: #525252;
    font-size: 12px;
    font-weight: 700;
}
.popup_bottom_carte .selectbox {
    margin-top: 8px;
    padding-left: 5px;
}
.popup_bottom_carte .input {
    margin-top: 8px;
}
.popup_bottom_carte .btn_green {
    margin-bottom: 15px;
    margin-top: 5px;
}
.popup_carte {
    position: absolute;
    top: 90px;
    left: 50%;
    margin-left: -186px;
    z-index: 9999;
    height: 409px;
    width: 370px;
    background: #fff;
    -moz-box-shadow: 0 0 6px 2px #8a8a8a;
    -webkit-box-shadow: 0 0 6px 2px #8a8a8a;
    box-shadow: 0 0 6px 2px #8a8a8a;
    -moz-border-radius: 2px;
    -webkit-border-radius: 2px;
    border-radius: 3px;
}
.form_row_carte {
    margin-bottom: 6px;
}
.popup_horaires2 {
    height: 34px;
    width: 34px;
    border-radius: 50%;
    background-color: #f6ac32;
    border: 1px solid #fff;
    display: inline-block;
    float: left;
    background-repeat: no-repeat;
    background-position-x: 6px;
    background-position-y: 6px;
    transition: all ease-in-out 0.2s;
}
.popup_horaires2:hover {
    height: 34px;
    width: 34px;
    border-radius: 50%;
    background-color: #03a79b;
    border: 1px solid #fff;
    display: inline-block;
    float: left;
    background-repeat: no-repeat;
    background-position-x: 6px;
    background-position-y: 6px;
    transition: all ease-in-out 0.2s;
}
.header_profil_infos {
    float: left;
    margin: 6px 10px 0 10px;
}
.profil_arrow {
    width: 8px;
    height: 5px;
    position: relative;
    float: right;
    margin-top: 2px;
}
.header_profil_infos span {
    margin-top: -3px;
}
.profil_wrraper {
    padding: 7px 10px 0 20px;
}
.popup_top_horaires {
    height: 90px;
    background-color: #00beb0;
    border-radius: 3px 3px 0 0;
    border-bottom: 5px solid #04968b;
    background-image: url(../images/horloge.png);
    background-repeat: no-repeat;
    background-position-x: -14px;
    background-position-y: 8px;
}
.popup_top_horaires h2 {
    color: #fff;
    font-size: 18px;
    font-weight: 700;
    text-transform: uppercase;
    float: left;
    margin-left: 88px;
    margin-top: 15px;
    display: inline-block;
}
.popup_top_horaires p {
    color: #fff;
    font-size: 12px;
    font-weight: 400;
    float: left;
    margin-left: 88px;
    padding-right: 90px;
    display: inline-block;
}
.popup_bottom_horaires {
    height: 233px;
    padding-top: 10px;
    background-color: #fff;
    border-radius: 0 0 3px 3px;
}
.popup_top_horaires img {
    margin-left: 30%;
    margin-top: 20px;
}
.popup_top_horaires .popup_close {
    float: right;
}
.popup_top_horaires .popup_close img {
    margin-left: -13px;
    margin-top: -67px;
    margin-right: 7px;
    float: right;
}
.popup_bottom_horaires h3 {
    color: #525252;
    font-size: 15px;
    font-weight: 700;
    text-align: center;
    text-transform: uppercase;
}
.popup_bottom_horaires p {
    color: #525252;
    font-size: 12px;
    font-weight: 400;
    text-align: center;
    margin-top: 5px;
    margin-left: 10px;
    margin-right: 10px;
}
.popup_bottom_horaires form {
    width: 94%;
    vertical-align: middle;
    margin-right: auto;
    margin-left: auto;
    margin-top: 10px;
}
.popup_bottom_horaires span {
    color: #525252;
    font-size: 12px;
    font-weight: 700;
    display: inline-block;
    margin-right: 30px;
    margin-top: -5px;
    float: left;
}
.popup_bottom_horaires label {
    color: #525252;
    font-size: 12px;
    margin-right: 8px;
    margin-top: 3px;
    display: inline-block;
    float: left;
}
.popup_bottom_horaires .selectbox {
    margin-top: 0;
    padding-left: 5px;
    width: 85px;
    display: inline-block;
}
.popup_bottom_horaires .selectbox_fermer {
    margin-top: 0;
    padding-left: 5px;
    width: 85px;
    display: inline-block;
}
.popup_bottom_horaires .input {
    margin-top: 16px;
}
.popup_bottom_horaires .btn_green {
    margin-bottom: 15px;
    margin-top: 10px;
    padding: 0 14px;
    margin-right: 36px;
}
.popup_horaires {
    position: absolute;
    top: 90px;
    left: 43%;
    margin-left: -186px;
    z-index: 9999;
    height: 409px;
    width: 634px;
    background: #fff;
    -moz-box-shadow: 0 0 6px 2px #8a8a8a;
    -webkit-box-shadow: 0 0 6px 2px #8a8a8a;
    box-shadow: 0 0 6px 2px #8a8a8a;
    -moz-border-radius: 2px;
    -webkit-border-radius: 2px;
    border-radius: 3px;
}
.form_row_horaires {
    margin-bottom: 6px;
    height: 30px;
}
#table_horaires > tbody > tr {
    height: 35px;
}
.selectbox_fermer {
    width: 100%;
    height: 25px;
    overflow: hidden;
    border: 1px solid #f5083a;
    -moz-border-radius: 3px;
    -webkit-border-radius: 3px;
    border-radius: 3px;
    background: #fff url(../images/selectbox_fermer.png) 100% -1px no-repeat;
    position: relative;
}
.selectbox_fermer .sprite-selectbox-btn {
    position: absolute;
    top: -1px;
    right: -1px;
}
.selectbox_fermer:hover {
    border-color: #f5083a;
}
.selectbox_fermer select {
    background: 0 0;
    width: 100%;
    height: 22px;
    margin-top: 1px;
    font-size: 12px;
    line-height: 1;
    border: 0;
    -webkit-appearance: none;
    color: #f5083a !important;
}
.popup_footer_horaires {
    height: 46px;
    width: 100%;
    background-color: #f0f0f0;
    bottom: 0;
    border-radius: 0 0 3px 3px;
    margin-top: 10px;
    float: left;
}
.popup_footer_horaires p {
    color: #4a4a4a;
    font-size: 12px;
    font-family: "Open Sans", sans-serif;
    float: left;
    margin-top: 14px;
}
.popup_bottom_horaires .infos_generales {
    height: 60px;
    margin-bottom: 15px;
}
.popup_bottom_horaires input {
    width: 80.2%;
    float: right;
}
.infos_generales label {
    color: #525252;
    font-size: 12px;
    font-weight: 700;
    display: inline-block;
    margin-right: 30px;
    margin-top: 18px;
    float: left;
}
.row_type1 {
    width: 100%;
    margin-bottom: 20px;
    float: left;
}
.row_type2 {
    width: 50%;
    float: left;
    display: inline-block;
    margin-bottom: 20px;
}
.row_type2 input {
    width: 180px;
}
.row_type2 label {
    margin-right: 10px;
}
.row_type3 {
    width: 50%;
    float: left;
    display: inline-block;
    margin-bottom: 20px;
}
.row_type3 input {
    width: 180px;
}
.row_type3 label {
    margin-right: 10px;
    margin-left: 20px;
}
.header_profil_infos {
    float: left;
    margin: 6px 10px 0 10px;
}
.profil_arrow {
    width: 8px;
    height: 5px;
    position: relative;
    float: right;
    margin-top: 2px;
}
.header_profil_infos span {
    margin-top: -3px;
}
.profil_wrraper {
    padding: 7px 10px 0 20px;
}
.popup_top_contreindication {
    height: 184px;
    background-color: #e75540;
    border-radius: 3px 3px 0 0;
}
.popup_bottom_contreindication {
    height: 233px;
    padding-top: 10px;
    background-color: #cc3f2a;
    border-radius: 0 0 3px 3px;
}
.popup_top_contreindication img {
    margin-left: 30%;
    margin-top: 20px;
}
.popup_top_contreindication .popup_close {
    float: right;
}
.popup_top_contreindication .popup_close img {
    margin-left: -13px;
    margin-top: -6px;
}
.popup_bottom_contreindication h3 {
    color: #fff;
    font-size: 15px;
    font-weight: 700;
    text-align: center;
    text-transform: uppercase;
}
.popup_bottom_contreindication p {
    color: #fff;
    font-size: 12px;
    font-weight: 400;
    text-align: center;
    margin-top: 5px;
    margin-left: 10px;
    margin-right: 10px;
    margin-bottom: 15px;
}
.popup_bottom_contreindication .btn_yellow {
    margin-bottom: 15px;
    margin-top: 5px;
    margin-left: 131px;
}
.popup_contreindication {
    position: absolute;
    top: 90px;
    left: 50%;
    margin-left: -186px;
    z-index: 9999;
    height: 409px;
    width: 370px;
    background: #fff;
    -moz-box-shadow: 0 0 6px 2px #8a8a8a;
    -webkit-box-shadow: 0 0 6px 2px #8a8a8a;
    box-shadow: 0 0 6px 2px #8a8a8a;
    -moz-border-radius: 2px;
    -webkit-border-radius: 2px;
    border-radius: 3px;
}
.popup_bottom_contreindication .popup_molecule {
    background-color: #b2311d;
    width: 90%;
    border-radius: 3px;
    height: 32px;
    margin-left: 19px;
    margin-bottom: 10px;
    background-image: url(../images/molecule.png);
    background-repeat: no-repeat;
    background-position-x: 8px;
    background-position-y: 5px;
}
.popup_bottom_contreindication .popup_molecule span {
    color: #fff;
    font-size: 12px;
    font-weight: 700;
    text-align: left;
    margin-left: 35px;
    margin-top: 5px;
    float: left;
}
.header_profil_infos {
    float: left;
    margin: 6px 10px 0 10px;
}
.profil_arrow {
    width: 8px;
    height: 5px;
    position: relative;
    float: right;
    margin-top: 2px;
}
.header_profil_infos span {
    margin-top: -3px;
}
.profil_wrraper {
    padding: 7px 10px 0 20px;
}
.popup_top_deconseiller {
    height: 184px;
    background-color: #eab416;
    border-radius: 3px 3px 0 0;
}
.popup_bottom_deconseiller {
    height: 233px;
    padding-top: 10px;
    background-color: #dca808;
    border-radius: 0 0 3px 3px;
}
.popup_top_deconseiller img {
    margin-left: 30%;
    margin-top: 20px;
}
.popup_top_deconseiller .popup_close {
    float: right;
}
.popup_top_deconseiller .popup_close img {
    margin-left: -13px;
    margin-top: -6px;
}
.popup_bottom_deconseiller h3 {
    color: #fff;
    font-size: 15px;
    font-weight: 700;
    text-align: center;
    text-transform: uppercase;
}
.popup_bottom_deconseiller p {
    color: #fff;
    font-size: 12px;
    font-weight: 400;
    text-align: center;
    margin-top: 5px;
    margin-left: 10px;
    margin-right: 10px;
    margin-bottom: 15px;
}
.popup_bottom_deconseiller .btn_green {
    margin-bottom: 15px;
    margin-top: 5px;
    margin-left: 131px;
    float: left;
}
.popup_deconseiller {
    position: absolute;
    top: 90px;
    left: 50%;
    margin-left: -186px;
    z-index: 9999;
    height: 409px;
    width: 370px;
    background: #fff;
    -moz-box-shadow: 0 0 6px 2px #8a8a8a;
    -webkit-box-shadow: 0 0 6px 2px #8a8a8a;
    box-shadow: 0 0 6px 2px #8a8a8a;
    -moz-border-radius: 2px;
    -webkit-border-radius: 2px;
    border-radius: 3px;
}
.popup_bottom_deconseiller .popup_molecule {
    background-color: #c49400;
    width: 90%;
    border-radius: 3px;
    height: 32px;
    margin-left: 19px;
    margin-bottom: 10px;
    background-image: url(../images/molecule.png);
    background-repeat: no-repeat;
    background-position-x: 8px;
    background-position-y: 5px;
}
.popup_bottom_deconseiller .popup_molecule span {
    color: #fff;
    font-size: 12px;
    font-weight: 700;
    text-align: left;
    margin-left: 35px;
    margin-top: 5px;
    float: left;
}
.header_profil_infos {
    float: left;
    margin: 6px 10px 0 10px;
}
.profil_arrow {
    width: 8px;
    height: 5px;
    position: relative;
    float: right;
    margin-top: 2px;
}
.header_profil_infos span {
    margin-top: -3px;
}
.profil_wrraper {
    padding: 7px 10px 0 20px;
}
.popup_input_date {
    background-image: url(../images/input_calendar.png);
    background-repeat: no-repeat;
    background-position-x: 160px;
    background-position-y: 5px;
}
.Horaires_save {
    margin-right: 18px !important;
}
.header_profil_infos {
    float: left;
    margin: 6px 10px 0 10px;
}
.profil_arrow {
    width: 8px;
    height: 5px;
    position: relative;
    float: right;
    margin-top: 2px;
}
.header_profil_infos span {
    margin-top: -3px;
}
.profil_wrraper {
    padding: 7px 10px 0 20px;
}
.popup_top_user h2 {
    color: #fff;
    font-size: 18px;
    font-weight: 700;
    text-transform: uppercase;
    float: left;
    margin-left: 10px;
    margin-top: 40px;
    display: inline-block;
}
.popup_top_user p {
    color: #fff;
    font-size: 12px;
    font-weight: 400;
    float: left;
    margin-left: 10px;
    padding-right: 90px;
    display: inline-block;
}
.popup_top_user {
    height: 184px;
    background-color: #40bef4;
    border-radius: 3px 3px 0 0;
    border-bottom: 5px solid #29a6dc;
}
.popup_top_content {
    float: right;
    display: inline-block;
    width: 69%;
}
.input_name {
    background-image: url(../images/loop.png);
    background-repeat: no-repeat;
    background-position-x: 150px;
    background-position-y: 2px;
}
.input_code {
    background-image: url(../images/code.png);
    background-repeat: no-repeat;
    background-position-x: 196px;
    background-position-y: 4px;
}
.input_name::hover {
    background-image: url(../images/loop.png);
    background-repeat: no-repeat;
    background-position-x: 150px;
    background-position-y: 2px;
}
.input_code:hover {
    background-image: url(../images/code.png);
    background-repeat: no-repeat;
    background-position-x: 146px;
    background-position-y: 4px;
}
.popup_bottom_user {
    height: 233px;
    background-color: #fff;
    border-radius: 0 0 3px 3px;
}
.popup_top_user img {
    margin-left: 5%;
    margin-top: 18px;
    float: left;
    display: inline-block;
}
.popup_top_content input {
    width: 172px;
    border: 1px solid #0d88bd;
    display: inline-block;
    float: left;
    margin-left: 10px;
    margin-top: 10px;
}
.popup_top_content input:hover {
    width: 172px;
    border: 1px solid #0d88bd;
}
.popup_top_user .popup_close {
    float: right;
}
.popup_top_user .popup_close img {
    margin-left: -16px;
    margin-top: 5px;
}
.popup_bottom_user h3 {
    color: #525252;
    font-size: 15px;
    font-weight: 700;
    text-align: center;
    text-transform: uppercase;
}
.popup_bottom_user p {
    color: #525252;
    font-size: 12px;
    font-weight: 400;
    text-align: center;
    margin-top: 5px;
    margin-left: 10px;
    margin-right: 10px;
}
.popup_bottom_user .btn_yellow {
    margin-bottom: 15px;
    margin-top: 13px;
    float: right;
    margin-right: 15px;
    margin-left: 10px;
}
.popup_bottom_user .btn_green {
    margin-bottom: 15px;
    margin-top: 13px;
    float: right;
}
.btn_popup_new {
    vertical-align: middle;
    margin-left: auto;
    margin-right: auto;
    width: 200.73px;
}
.popup_user {
    position: absolute;
    top: 90px;
    left: 50%;
    margin-left: -288px;
    z-index: 9999;
    height: 500px;
    width: 575px;
    background: #fff;
    -moz-box-shadow: 0 0 6px 2px #8a8a8a;
    -webkit-box-shadow: 0 0 6px 2px #8a8a8a;
    box-shadow: 0 0 6px 2px #8a8a8a;
    -moz-border-radius: 2px;
    -webkit-border-radius: 2px;
    border-radius: 3px;
}
.form_row_user {
    margin-bottom: 6px;
}
#no_user {
    width: 130px;
    vertical-align: middle;
    margin-left: auto;
    margin-right: auto;
    display: inherit;
    margin-top: 30px;
}
.popup_bottom_user2 h2 {
    color: #1ebdb0;
    font-size: 14px;
    font-family: "Open Sans", sans-serif;
    text-transform: uppercase;
    font-weight: 700;
    text-align: center;
    margin-top: 15px;
}
.popup_bottom_user2 p {
    color: #525252;
    font-size: 12px;
    font-family: "Open Sans", sans-serif;
    font-weight: 400;
    text-align: center;
    margin-top: 10px;
    padding-left: 100px;
    padding-right: 100px;
}
.popup_bottom_user2 .btn_yellow {
    margin-bottom: 15px;
    margin-top: 74px;
    float: right;
    margin-right: 15px;
    margin-left: 10px;
}
.popup_bottom_user2 .btn_green {
    margin-bottom: 15px;
    margin-top: 13px;
    float: left;
    margin-left: 189px;
}
.header_profil_infos {
    float: left;
    margin: 6px 10px 0 10px;
}
.profil_arrow {
    width: 8px;
    height: 5px;
    position: relative;
    float: right;
    margin-top: 2px;
}
.header_profil_infos span {
    margin-top: -3px;
}
.profil_wrraper {
    padding: 7px 10px 0 20px;
}
.popup_top_absence h2 {
    color: #fff;
    font-size: 18px;
    font-weight: 700;
    text-transform: uppercase;
    float: left;
    margin-left: 10px;
    margin-top: 50px;
    display: inline-block;
}
.popup_top_absence p {
    color: #fff;
    font-size: 12px;
    font-weight: 400;
    float: left;
    margin-left: 10px;
    padding-right: 40px;
    display: inline-block;
}
.popup_top_absence {
    height: 184px;
    background-color: #fda71c;
    border-radius: 3px 3px 0 0;
    border-bottom: 5px solid #e8950f;
}
.popup_top_content {
    float: right;
    display: inline-block;
    width: 69%;
}
.popup_bottom_absence {
    height: 233px;
    background-color: #fff;
    border-radius: 0 0 3px 3px;
}
.popup_top_absence img {
    margin-left: 5%;
    margin-top: 18px;
    float: left;
    display: inline-block;
}
.popup_top_absence .popup_close {
    float: right;
}
.popup_top_absence .popup_close img {
    margin-left: -16px;
    margin-top: 5px;
}
.popup_absence {
    position: absolute;
    top: 90px;
    left: 50%;
    margin-left: -288px;
    z-index: 9999;
    height: 545px;
    width: 575px;
    background: #fff;
    -moz-box-shadow: 0 0 6px 2px #8a8a8a;
    -webkit-box-shadow: 0 0 6px 2px #8a8a8a;
    box-shadow: 0 0 6px 2px #8a8a8a;
    -moz-border-radius: 2px;
    -webkit-border-radius: 2px;
    border-radius: 3px;
}
.form_row_user {
    margin-bottom: 6px;
}
.absence_info {
    height: 105px;
    width: 100%;
    border-bottom: 1px solid #8d8d8d;
}
.absence_info_header {
    height: 35px;
    width: 100%;
    background-color: #f8f8f8;
}
.absence_info_header h3 {
    color: #4a4a4a;
    font-size: 12px;
    font-weight: 700;
    float: left;
    margin-left: 10px;
    margin-top: 8px;
}
.info_header_time {
    float: right;
}
.info_header_time span {
    color: #525252;
    font-size: 12px;
    font-weight: 400;
    float: left;
    margin-left: 5px;
    margin-top: 8px;
}
.info_header_time img {
    float: left;
    margin-top: 9px;
}
.info_header_time a {
    height: 26px;
    width: 26px;
    border: 2px solid #ddd;
    border-radius: 50%;
    float: right;
    margin-top: 3px;
    margin-left: 10px;
    margin-right: 6px;
    background-image: url(../images/popup_absence_view.png);
    background-repeat: no-repeat;
    background-position-x: 4px;
    background-position-y: 6px;
    transition: all ease-in-out 0.2s;
}
.info_header_time a:hover {
    height: 26px;
    width: 26px;
    border: 2px solid #1ebdb0;
    background-color: #1ebdb0;
    border-radius: 50%;
    float: right;
    margin-top: 3px;
    margin-left: 10px;
    margin-right: 6px;
    background-image: url(../images/popup_absence_view_hover.png);
    background-repeat: no-repeat;
    background-position-x: 4px;
    background-position-y: 6px;
    transition: all ease-in-out 0.2s;
}
.absence_info p {
    color: #525252;
    font-size: 12px;
    font-weight: 400;
    float: left;
    margin-left: 10px;
    margin-top: 8px;
    text-align: left;
    padding-right: 20px;
}
.absence_info a {
    color: #1ebdb0;
    font-size: 12px;
    font-weight: 400;
    float: left;
    margin-left: 10px;
    margin-top: 3px;
    text-align: left;
    padding-right: 20px;
}
.popup_bottom_absence .btn_green {
    float: right;
    margin-top: 10px;
    margin-right: 8px;
}
.popup_bottom_absence .btn_yellow {
    float: right;
    margin-top: 10px;
    margin-right: 8px;
}
.bloc_espece:hover {
    background-color: #19baae;
    transition: all ease-in-out 0.2s;
    color: #fff;
    background-image: url(../images/espece2.png);
    background-repeat: no-repeat;
    background-position-x: center;
    background-position-y: 31px;
}
.bloc_espece {
    height: 120px;
    display: inline-block;
    width: 100%;
    text-align: center;
    font: 12px "Open Sans", sans-serif;
    color: #494949;
    font-weight: 700;
    padding-top: 70px;
    background-image: url(../images/espece.png);
    background-repeat: no-repeat;
    background-position-x: center;
    background-position-y: 31px;
}
.bloc_cheque:hover {
    background-color: #f79954;
    transition: all ease-in-out 0.2s;
    color: #fff;
    background-image: url(../images/cheque2.png);
    background-repeat: no-repeat;
    background-position-x: center;
    background-position-y: 31px;
}
.bloc_cheque {
    height: 120px;
    display: inline-block;
    width: 100%;
    text-align: center;
    font: 12px "Open Sans", sans-serif;
    color: #494949;
    font-weight: 700;
    padding-top: 70px;
    background-image: url(../images/cheque.png);
    background-repeat: no-repeat;
    background-position-x: center;
    background-position-y: 31px;
}
.bloc_carte:hover {
    background-color: #18b1d4;
    transition: all ease-in-out 0.2s;
    color: #fff;
    background-image: url(../images/carte2.png);
    background-repeat: no-repeat;
    background-position-x: center;
    background-position-y: 31px;
}
.bloc_carte {
    height: 120px;
    display: inline-block;
    width: 100%;
    text-align: center;
    font: 12px "Open Sans", sans-serif;
    color: #494949;
    font-weight: 700;
    padding-top: 70px;
    background-image: url(../images/carte.png);
    background-repeat: no-repeat;
    background-position-x: center;
    background-position-y: 31px;
}
.bloc_lettre:hover {
    background-color: #e03e4d;
    transition: all ease-in-out 0.2s;
    color: #fff;
    background-image: url(../images/lettre2.png);
    background-repeat: no-repeat;
    background-position-x: center;
    background-position-y: 31px;
}
.bloc_lettre {
    height: 120px;
    display: inline-block;
    width: 100%;
    text-align: center;
    font: 12px "Open Sans", sans-serif;
    color: #494949;
    font-weight: 700;
    padding-top: 70px;
    background-image: url(../images/lettre.png);
    background-repeat: no-repeat;
    background-position-x: center;
    background-position-y: 31px;
}
.bloc_avoir:hover {
    background-color: #8ec549;
    transition: all ease-in-out 0.2s;
    color: #fff;
    background-image: url(../images/avoir2.png);
    background-repeat: no-repeat;
    background-position-x: center;
    background-position-y: 31px;
}
.bloc_avoir {
    height: 120px;
    display: inline-block;
    width: 100%;
    text-align: center;
    font: 12px "Open Sans", sans-serif;
    color: #494949;
    font-weight: 700;
    padding-top: 70px;
    background-image: url(../images/avoir.png);
    background-repeat: no-repeat;
    background-position-x: center;
    background-position-y: 31px;
}
.bloc_autre:hover {
    background-color: #9477ca;
    transition: all ease-in-out 0.2s;
    color: #fff;
    background-image: url(../images/autre2.png);
    background-repeat: no-repeat;
    background-position-x: center;
    background-position-y: 31px;
}
.bloc_autre {
    height: 120px;
    display: inline-block;
    width: 100%;
    text-align: center;
    font: 12px "Open Sans", sans-serif;
    color: #494949;
    font-weight: 700;
    padding-top: 70px;
    background-image: url(../images/autre.png);
    background-repeat: no-repeat;
    background-position-x: center;
    background-position-y: 31px;
}
.cb:hover {
    display: inline-block;
    width: 35px;
    height: 27px;
    background-position: -37px -249px;
}
#topnav > li > a:hover {
    color: #037d74;
    transition: all ease-in-out 0.2s;
}
#topnav > li > a {
    color: #fff;
    transition: all ease-in-out 0.2s;
}
.plan-filter label,
.plan-search label {
    color: #fff;
}
.plan-filter {
    margin: 1px;
}
.tag_yes {
    padding: 3px 12px 3px 12px;
    border-radius: 3px;
    background-color: #28b9ac;
    color: #fff;
    display: inline-block;
}
.tag_no {
    padding: 3px 10px 3px 10px;
    border-radius: 3px;
    background-color: #db544c;
    color: #fff;
    display: inline-block;
}
.popup_top_parrainge {
    height: 184px;
    background-image: url(../images/banner_parrainage.png);
    background-repeat: no-repeat;
    border-radius: 3px 3px 0 0;
}
.popup_bottom_parrainge {
    height: 172px;
    padding-top: 24px;
    background-color: #fff;
    border-radius: 0 0 3px 3px;
}
.popup_top_parrainge .popup_close {
    float: right;
}
.popup_top_parrainge .popup_close img {
    margin-left: -13px;
    margin-top: -6px;
}
.popup_bottom_parrainge h3 {
    color: #1b7ba2;
    font-size: 15px;
    font-weight: 700;
    text-align: center;
    padding-left: 18px;
    padding-right: 18px;
}
.popup_parrainge {
    position: absolute;
    top: 90px;
    left: 50%;
    margin-left: -186px;
    z-index: 9999;
    height: 359px;
    width: 370px;
    background: #fff;
    -moz-box-shadow: 0 0 6px 2px #8a8a8a;
    -webkit-box-shadow: 0 0 6px 2px #8a8a8a;
    box-shadow: 0 0 6px 2px #8a8a8a;
    -moz-border-radius: 2px;
    -webkit-border-radius: 2px;
    border-radius: 3px;
}
.popup_bottom_parrainge h2 {
    color: #00beb0;
    font-size: 23px;
    font-weight: 700;
    text-align: center;
    text-transform: uppercase;
}
.pop_btn {
    font-family: "Open Sans", sans-serif;
    font-size: 12px;
    color: #fff;
    text-transform: uppercase;
    font-weight: 700;
    padding: 8px 10px 8px 10px;
    border-radius: 3px;
    background-color: #f4ac2f;
    text-align: center;
    margin-top: 18px;
    display: inline-block;
    margin-left: 96px;
    transition: all ease-in-out 0.2s;
}
.pop_btn:hover {
    font-family: "Open Sans", sans-serif;
    font-size: 12px;
    color: #fff;
    text-transform: uppercase;
    font-weight: 700;
    padding: 8px 10px 8px 10px;
    border-radius: 3px;
    background-color: #1abaae;
    text-align: center;
    margin-top: 18px;
    display: inline-block;
    margin-left: 96px;
    transition: all ease-in-out 0.2s;
}
.popup_top_groups {
    height: 184px;
    background-image: url(../images/banner_groups.png);
    background-repeat: no-repeat;
    border-radius: 3px 3px 0 0;
}
.popup_top_groups .popup_close img {
    margin-left: -13px;
    margin-top: -6px;
}
.popup_top_alerte {
    height: 48px;
    background-color: #e75540;
    border-radius: 3px 3px 0 0;
}
.popup_top_alerte h3 {
    font-family: "Open Sans", sans-serif;
    font-size: 16px;
    font-weight: 700;
    color: #fff;
    text-align: left;
    float: left;
    display: inline-block;
    margin-left: 14px;
    margin-top: 10px;
}
.popup_alert_footer {
    width: 100%;
    height: 52px;
    border-radius: 0 0 3px 3px;
    background-color: #f0f0f0;
    margin-bottom: -67px;
    display: inline-block;
}
.popup_bottom_alerte {
    height: 152px;
    padding-top: 20px;
    background-color: #fff;
    border-radius: 0 0 3px 3px;
}
.popup_top_alerte img {
    margin-left: 25%;
    margin-top: 20px;
}
.popup_top_alerte .popup_close {
    float: right;
}
.popup_top_alerte .popup_close img {
    margin-left: -13px;
    margin-top: -6px;
}
.popup_bottom_alerte h3 {
    color: #525252;
    font-size: 15px;
    font-weight: 700;
    text-align: center;
    text-transform: uppercase;
}
.popup_bottom_alerte p {
    color: #525252;
    font-size: 14px;
    font-weight: 400;
    text-align: left;
    margin-top: 5px;
    padding-left: 10px;
    padding-right: 10px;
}
.popup_alerte {
    position: absolute;
    top: 90px;
    left: 46%;
    margin-left: -186px;
    z-index: 9999;
    height: 152px;
    width: 520px;
    background: #fff;
    -moz-box-shadow: 0 0 6px 2px #8a8a8a;
    -webkit-box-shadow: 0 0 6px 2px #8a8a8a;
    box-shadow: 0 0 6px 2px #8a8a8a;
    -moz-border-radius: 2px;
    -webkit-border-radius: 2px;
    border-radius: 3px;
}
.popup_alert_footer .btn_green {
    margin-right: 13px;
    margin-top: 13px;
    padding: 0 44px;
}
.popup_alert_footer .btn_yellow {
    margin-right: 10px;
    margin-top: 13px;
    float: right;
}
.help_accord {
    height: 40px;
    width: 100%;
    background-color: #36adee;
    border: 1px solid #fff;
}
.help_bloc .grid4 {
    margin-bottom: 0;
}
.help_bloc .bloc {
    margin-bottom: 0;
}
.help_accord h3 {
    font-family: "Open Sans", sans-serif;
    font-size: 14px;
    color: #fff;
    font-weight: 700;
    margin-top: 8px;
    display: inline-block;
}
.help_accord_icon {
    display: inline-block;
    width: 27px;
    height: 27px;
    background-image: url(../images/sprite_5.png);
    background-repeat: no-repeat;
    background-position: -267px -633px;
    margin-top: 6px;
    margin-left: 6px;
    float: left;
    margin-right: 10px;
}
.help_plus_icon {
    display: inline-block;
    width: 18px;
    height: 18px;
    background-image: url(../images/sprite_5.png);
    background-repeat: no-repeat;
    background-position: -298px -632px;
    margin-top: 9px;
    margin-left: 6px;
    float: right;
    margin-right: 10px;
}
.help_hide_icon {
    display: inline-block;
    width: 18px;
    height: 18px;
    background-image: url(../images/sprite_5.png);
    background-repeat: no-repeat;
    background-position: -316px -632px;
    margin-top: 9px;
    margin-left: 6px;
    float: right;
    margin-right: 12px;
}
.help_info {
    background-color: #299ad7;
    width: 100%;
    height: 108px;
    float: left;
    font-family: "Open Sans", sans-serif;
    font-size: 12px;
    text-align: left;
    font-weight: 400;
    display: none;
}
.help_info p {
    width: 75%;
    color: #fff;
    margin-top: 20px;
    display: inline-block;
}
.help_info img {
    float: left;
    margin-left: 30px;
    margin-top: 5px;
    margin-right: 20px;
}
.play_icon {
    display: inline-block;
    width: 57px;
    height: 57px;
    background-image: url(../images/sprite_5.png);
    background-repeat: no-repeat;
    background-position: -261px -669px;
    margin-top: 6px;
    margin-left: 6px;
    float: right;
    margin-top: 13px;
}
.help_info span {
    font-family: "Open Sans", sans-serif;
    font-size: 13px;
    font-weight: 400;
    color: #fff;
    text-transform: uppercase;
    margin-left: 12px;
    margin-top: 5px;
}
.help_title {
    width: 100%;
    height: 40px;
    cursor: pointer;
}
.help_play {
    width: 60px;
    display: inline-block;
    float: right;
    margin-right: 30px;
}
.popup_top_video {
    height: 48px;
    background-color: #00b7ff;
    border-radius: 3px 3px 0 0;
}
.popup_top_video h3 {
    font-family: "Open Sans", sans-serif;
    font-size: 16px;
    font-weight: 700;
    color: #fff;
    text-align: left;
    float: left;
    display: inline-block;
    margin-left: 14px;
    margin-top: 10px;
}
.popup_video_footer {
    width: 100%;
    height: 52px;
    border-radius: 0 0 3px 3px;
    background-color: #f0f0f0;
    margin-bottom: -46px;
    display: inline-block;
}
.popup_bottom_video {
    height: 152px;
    padding-top: 20px;
    background-color: #fff;
    border-radius: 0 0 3px 3px;
}
.popup_top_video img {
    margin-left: 25%;
    margin-top: 20px;
}
.popup_top_video .popup_close {
    float: right;
}
.popup_top_video .popup_close img {
    margin-left: -13px;
    margin-top: -6px;
}
.popup_bottom_video h3 {
    color: #525252;
    font-size: 15px;
    font-weight: 700;
    text-align: center;
    text-transform: uppercase;
}
.popup_bottom_video p {
    color: #525252;
    font-size: 14px;
    font-weight: 400;
    text-align: left;
    margin-top: 5px;
    padding-left: 10px;
    padding-right: 10px;
}
.popup_bottom_video span {
    color: #00beb0;
    font-size: 14px;
    font-weight: 700;
    float: right;
    margin-top: 14px;
    padding-left: 10px;
    padding-right: 10px;
    margin-right: 70px;
}
.popup_video {
    position: absolute;
    top: 90px;
    z-index: 9999;
    height: 451px;
    width: 600px;
    background: #fff;
    -moz-box-shadow: 0 0 6px 2px #8a8a8a;
    -webkit-box-shadow: 0 0 6px 2px #8a8a8a;
    box-shadow: 0 0 6px 2px #8a8a8a;
    -moz-border-radius: 2px;
    -webkit-border-radius: 2px;
    border-radius: 3px;
    left: 50%;
    margin-left: -300px;
}
.popup_video_footer .btn_green {
    margin-right: 13px;
    margin-top: 13px;
    padding: 0 44px;
}
.popup_video_footer .btn_yellow {
    margin-right: 10px;
    margin-top: 13px;
    float: right;
}
.popup_video_footer .btn_utile {
    margin-right: 10px;
    margin-top: 13px;
    float: right;
}
.popup_bottom_video .video {
    margin-left: 7px;
    display: inline-block;
    float: left;
}
.popup_bottom_video .ul_list {
    float: left;
    margin-left: 10px;
    display: block;
    width: 270px;
    margin-bottom: 20px;
}
.ul_list li {
    font-size: 13px;
}
.help_bloc_bottom .faq_list a {
    font-size: 13px;
    color: #444 !important;
    transition: all ease-in-out 0.2s;
}
.help_bloc_bottom .faq_list a:hover {
    font-size: 13px;
    color: #1bbbaf !important;
    transition: all ease-in-out 0.2s;
}
.faq_list li {
    margin-bottom: 10px;
}
.faq_list {
    float: left;
    margin-left: 15px;
    display: block;
    width: 250px;
    margin-bottom: 20px;
    list-style-image: url(../images/list.png);
}
.ul_point li {
    font-size: 13px;
}
.popup_bottom_video .ul_point {
    float: left;
    margin-left: 26px;
    display: block;
    width: 250px;
    margin-bottom: 20px;
    list-style-image: url(../images/list.png);
}
.popup_bottom_video p {
    font-size: 13px;
    width: 270px;
    float: left;
}
.popup_video_footer p {
    width: 300px;
    margin-top: 15px;
}
.btn_utile {
    background-color: #e75540 !important;
    border: 1px solid #e75540 !important;
    display: inline-block;
    padding: 0 30px;
    height: 25px;
    color: #fff;
    text-align: center;
    line-height: 24px;
    font-weight: 600;
    cursor: pointer;
    -moz-border-radius: 2px;
    -webkit-border-radius: 2px;
    border-radius: 2px;
    float: left;
}
.btn_utile:hover {
    background-color: #e14832 !important;
    border: 1px solid #e14832 !important;
}
.succes_cercle {
    width: 130px;
    height: 130px;
    border-radius: 50%;
    background-color: #1abaae;
    vertical-align: middle;
    display: inherit;
    margin-left: auto;
    margin-right: auto;
    margin-top: 50px;
}
.succes_icon {
    display: inline-block;
    width: 63px;
    height: 66px;
    background-image: url(../images/sprite_5.png);
    background-repeat: no-repeat;
    background-position: -380px -554px;
    margin-left: 37px;
    margin-top: 33px;
}
#succes_msg {
    font-family: "Open Sans", sans-serif;
    font-size: 14px;
    font-weight: 700;
    color: #1abaae;
    text-transform: uppercase;
    margin-bottom: 10px;
}
.popup_form_parrainage a {
    background-color: #1abaae;
    font-family: "Open Sans", sans-serif;
    font-size: 14px;
    font-weight: 700;
    color: #fff;
    text-align: center;
    padding: 8px 18px 8px 18px;
    border-radius: 3px;
    width: 87px;
    vertical-align: middle;
    margin-right: auto;
    margin-left: auto;
    display: inherit;
}
.btns_bottom {
    height: 44px;
    width: 400px;
    position: fixed;
    margin-left: 87.9%;
    bottom: -60px;
    z-index: 100;
    display: none;
}
.btns_bottom a {
    display: inline-block;
}
.btn_options {
    width: 44px;
    height: 44px;
    border-radius: 50%;
    background-color: #f6ac32;
    box-shadow: 0 1px 3px 0 #bcbcbc;
    -webkit-box-shadow: 0 1px 3px 0 #bcbcbc;
    -moz-box-shadow: 0 1px 3px 0 #bcbcbc;
    -o-box-shadow: 0 1px 3px 0 #bcbcbc;
    transition: all ease-in-out 0.2s;
    cursor: pointer;
    border: 2px solid #fff;
    float: left;
}
.btn_options:hover {
    width: 44px;
    height: 44px;
    border-radius: 50%;
    background-color: #e9a027;
    box-shadow: 0 1px 8px 0 #bcbcbc;
    -webkit-box-shadow: 0 1px 8px 0 #bcbcbc;
    -moz-box-shadow: 0 1px 8px 0 #bcbcbc;
    -o-box-shadow: 0 1px 8px 0 #bcbcbc;
    transition: all ease-in-out 0.2s;
    cursor: pointer;
    border: 2px solid #fff;
    float: left;
}
.btn_top {
    width: 44px;
    height: 44px;
    border-radius: 50%;
    background-color: #00b7ff;
    box-shadow: 0 1px 3px 0 #bcbcbc;
    -webkit-box-shadow: 0 1px 3px 0 #bcbcbc;
    -moz-box-shadow: 0 1px 3px 0 #bcbcbc;
    -o-box-shadow: 0 1px 3px 0 #bcbcbc;
    transition: all ease-in-out 0.2s;
    cursor: pointer;
    border: 2px solid #fff;
    margin-left: 5px;
}
.btn_top:hover {
    width: 44px;
    height: 44px;
    border-radius: 50%;
    background-color: #03adf0;
    box-shadow: 0 1px 8px 0 #bcbcbc;
    -webkit-box-shadow: 0 1px 8px 0 #bcbcbc;
    -moz-box-shadow: 0 1px 8px 0 #bcbcbc;
    -o-box-shadow: 0 1px 8px 0 #bcbcbc;
    transition: all ease-in-out 0.2s;
    cursor: pointer;
    border: 2px solid #fff;
    margin-left: 5px;
}
.question_icon {
    display: inline-block;
    width: 24px;
    height: 30px;
    background-image: url(../images/sprite_5.png);
    background-repeat: no-repeat;
    background-position: -389px -539px;
    margin-left: 9px;
    margin-top: 8px;
}
.top_icon {
    display: inline-block;
    width: 24px;
    height: 30px;
    background-image: url(../images/sprite_5.png);
    background-repeat: no-repeat;
    background-position: -390px -570px;
    margin-left: 9px;
    margin-top: 2px;
}
.close_icon {
    display: inline-block;
    width: 24px;
    height: 19px;
    background-image: url(../images/sprite_5.png);
    background-repeat: no-repeat;
    background-position: -316px -662px;
    margin-left: 19px;
    margin-top: 17px;
}
.help_wrapper {
    width: 313px;
    height: 400px;
    border-radius: 5px;
    background-color: #fff;
    box-shadow: 0 2px 14px -2px #bcbcbc;
    -webkit-box-shadow: 0 2px 14px -2px #bcbcbc;
    -moz-box-shadow: 0 2px 14px -2px #bcbcbc;
    -o-box-shadow: 0 2px 14px -2px #bcbcbc;
    position: fixed;
    z-index: 9999;
    right: 29px;
    bottom: 0;
    margin-bottom: 90px;
    display: none;
}
.help_bloc_header {
    width: 100%;
    height: auto;
    border-radius: 5px 5px 0 0;
    background-color: #c557d4;
    padding-top: 15px;
    padding-left: 10px;
    padding-bottom: 20px;
}
.help_bloc_header p {
    color: #fff;
}
.help_bloc_header h3 {
    font-weight: 700;
    font-family: "Open Sans", sans-serif;
    color: #fff;
    font-size: 14px;
    text-align: left;
}
.help_bloc_bottom {
    padding: 12px;
}
.help_bloc_bottom .ul_point {
    float: left;
    margin-left: 15px;
    display: block;
    width: 250px;
    margin-bottom: 20px;
    list-style-image: url(../images/list.png);
    margin-top: 20px;
}
.help_bloc_bottom img {
    width: 121px;
    vertical-align: middle;
    margin-right: auto;
    margin-left: auto;
    display: inherit;
    margin-top: 30px;
    margin-bottom: 12px;
}
.help_bloc_bottom p {
    font-family: "Open Sans", sans-serif;
    font-size: 14px;
    font-weight: 700;
    color: #585858;
    text-align: center;
    display: inherit;
    margin-bottom: 12px;
}
.help_bloc_bottom span {
    font-family: "Open Sans", sans-serif;
    font-size: 19px;
    font-weight: 700;
    color: #00beb0;
    text-align: center;
    display: inherit;
}
.btn_video_help {
    font-size: 12px;
    color: #fff;
    text-align: center;
    font-weight: 400;
    background-color: #f6ac32;
    border-radius: 3px;
    padding: 5px 65px 5px 65px;
    bottom: -10px;
    margin-left: 33px;
    position: absolute;
    margin-bottom: 12px;
    transition: all ease-in-out 0.2s;
}
.btn_video_help:hover {
    font-size: 12px;
    color: #fff;
    text-align: center;
    font-weight: 400;
    background-color: #f69600;
    border-radius: 3px;
    padding: 5px 65px 5px 65px;
    bottom: 0;
    margin-left: 33px;
    position: absolute;
    margin-bottom: 12px;
    transition: all ease-in-out 0.2s;
}
.btn_notifications {
    width: 44px;
    height: 44px;
    border-radius: 50%;
    background-color: #f0f0f0;
    box-shadow: 0 1px 3px 0 #bcbcbc;
    -webkit-box-shadow: 0 1px 3px 0 #bcbcbc;
    -moz-box-shadow: 0 1px 3px 0 #bcbcbc;
    -o-box-shadow: 0 1px 3px 0 #bcbcbc;
    transition: all ease-in-out 0.2s;
    cursor: pointer;
    border: 2px solid #fff;
    float: left;
    margin-right: 5px;
}
.btn_notifications:hover {
    width: 44px;
    height: 44px;
    border-radius: 50%;
    background-color: #dedddd;
    box-shadow: 0 1px 8px 0 #bcbcbc;
    -webkit-box-shadow: 0 1px 8px 0 #bcbcbc;
    -moz-box-shadow: 0 1px 8px 0 #bcbcbc;
    -o-box-shadow: 0 1px 8px 0 #bcbcbc;
    transition: all ease-in-out 0.2s;
    cursor: pointer;
    border: 2px solid #fff;
    float: left;
    margin-right: 5px;
}
.btn_notifications span {
    font-weight: 700;
    font-family: "Open Sans", sans-serif;
    color: #bebebe;
    text-align: center;
    margin-top: 6px;
    display: inherit;
    font-size: 18px;
}
.popup_top_statistiques {
    height: 48px;
    background-color: #00beb0;
    border-radius: 3px 3px 0 0;
}
.popup_top_statistiques h3 {
    font-family: "Open Sans", sans-serif;
    font-size: 16px;
    font-weight: 700;
    color: #fff;
    text-align: left;
    float: left;
    display: inline-block;
    margin-left: 14px;
    margin-top: 10px;
}
.popup_statistiques_footer {
    width: 100%;
    height: 52px;
    border-radius: 0 0 3px 3px;
    background-color: #f0f0f0;
    margin-bottom: -46px;
    display: inline-block;
}
.popup_bottom_statistiques {
    height: 152px;
    padding-top: 20px;
    background-color: #fff;
    border-radius: 0 0 3px 3px;
}
.popup_top_statistiques img {
    margin-left: 25%;
    margin-top: 20px;
}
.popup_top_statistiques .popup_close {
    float: right;
}
.popup_top_statistiques .popup_close img {
    margin-left: -13px;
    margin-top: -6px;
}
.popup_bottom_statistiques h3 {
    color: #525252;
    font-size: 15px;
    font-weight: 700;
    text-align: center;
    text-transform: uppercase;
}
.popup_bottom_statistiques p {
    color: #525252;
    font-size: 14px;
    font-weight: 400;
    text-align: left;
    margin-top: 5px;
    padding-left: 10px;
    padding-right: 10px;
}
.popup_statistiques {
    position: absolute;
    top: 90px;
    z-index: 9999;
    height: 583px;
    width: 600px;
    background: #fff;
    -moz-box-shadow: 0 0 6px 2px #8a8a8a;
    -webkit-box-shadow: 0 0 6px 2px #8a8a8a;
    box-shadow: 0 0 6px 2px #8a8a8a;
    -moz-border-radius: 2px;
    -webkit-border-radius: 2px;
    border-radius: 3px;
    left: 50%;
    margin-left: -300px;
}
.popup_bottom_statistiques table td {
    text-align: center;
}
.td_mois {
    text-align: left !important;
    padding-left: 12px !important;
}
.popup_bottom_statistiques table {
    margin-top: -10px;
}
.popup_bottom_statistiques .btn_yellow {
    float: right;
    margin-top: 9px;
    margin-right: 10px;
}
.checkbox2 input {
    display: none;
    cursor: pointer;
}
.checkbox2 .checked {
    width: 160px;
    height: 25px;
    display: inline-block;
    background: #f8f8f8;
    border: 2px solid #f7ad1c;
    vertical-align: middle;
    -moz-border-radius: 2px;
    -webkit-border-radius: 2px;
    border-radius: 2px;
    cursor: pointer;
}
.checkbox2 p {
    font-weight: 700;
    color: #f7ad1c;
    position: absolute;
    margin-top: 3px;
    z-index: 99;
    margin-left: 37px;
}
.checkbox2 input:checked + .checked {
    background: #f7ad1c;
    border: 1px solid #f7ad1c;
    position: relative;
}
.checkbox2 input:checked + .checked:after {
    content: "";
    display: block;
    width: 13px;
    height: 13px;
    background: transparent url(../images/sprite_5.png) -341px -666px no-repeat;
    position: absolute;
    top: 5px;
    left: 3px;
    z-index: 99;
}
.checkzone {
    height: 16px;
    width: 16px;
    border-radius: 3px;
    border: 2px solid #f7ad1c;
    margin-top: 4px;
    margin-left: 4px;
    position: absolute;
    z-index: 99;
    background-color: #f8f8f8;
}
.checkzone_after {
    height: 16px;
    width: 16px;
    border-radius: 3px;
    border: 2px solid #fff;
    margin-top: 4px;
    margin-left: 4px;
    position: absolute;
    z-index: 99;
    background-color: #fff;
}
.succes_icon_popup {
    display: inline-block;
    width: 19px;
    height: 19px;
    background-image: url(../images/sprite_5.png);
    background-repeat: no-repeat;
    background-position: -341px -679px;
    margin-left: 19px;
    margin-top: 2px;
    margin-right: 4px;
    float: left;
}
.btn_statis {
    float: left;
    margin-top: 17px;
}
.text_checkbox:hover {
    color: #f4ac2f;
}
.text-nicelabel + label:hover {
    border: 3px solid #f4ac2f;
    border-radius: 3px;
}
.back_btn_help {
    background-color: #f6ac32;
    border-radius: 5px;
    height: 30px;
    width: 80px;
    float: left;
    position: absolute;
    z-index: -1;
    margin-left: -30px;
    margin-top: 18px;
    box-shadow: 0 2px 14px -2px #bcbcbc;
    -webkit-box-shadow: 0 2px 14px -2px #bcbcbc;
    -moz-box-shadow: 0 2px 14px -2px #bcbcbc;
    -o-box-shadow: 0 2px 14px -2px #bcbcbc;
    transition: all ease-in-out 0.2s;
}
.back_btn_help:hover {
    background-color: #f69600;
    border-radius: 5px;
    height: 30px;
    width: 80px;
    float: left;
    position: absolute;
    z-index: -1;
    margin-left: -30px;
    margin-top: 18px;
    box-shadow: 0 2px 14px -2px #bcbcbc;
    -webkit-box-shadow: 0 2px 14px -2px #bcbcbc;
    -moz-box-shadow: 0 2px 14px -2px #bcbcbc;
    -o-box-shadow: 0 2px 14px -2px #bcbcbc;
    transition: all ease-in-out 0.2s;
}
.back_btn_icon {
    display: inline-block;
    width: 11px;
    height: 15px;
    background-image: url(../images/sprite_5.png);
    background-repeat: no-repeat;
    background-position: -322px -684px;
    margin-left: 12px;
    margin-top: 8px;
    margin-right: 4px;
    float: left;
}
.popup_top_alimentation {
    height: 184px;
    background-color: #00beb0;
    border-radius: 3px 3px 0 0;
}
.popup_bottom_alimentation {
    height: 185px;
    padding-top: 10px;
    background-color: #fff;
    border-radius: 0 0 3px 3px;
}
.popup_top_alimentation img {
    margin-left: 30%;
    margin-top: 20px;
}
.popup_top_alimentation .popup_close {
    float: right;
}
.popup_top_alimentation .popup_close img {
    margin-left: -13px;
    margin-top: -6px;
}
.popup_bottom_alimentation h3 {
    color: #525252;
    font-size: 15px;
    font-weight: 700;
    text-align: center;
    text-transform: uppercase;
}
.popup_bottom_alimentation p {
    color: #525252;
    font-size: 12px;
    font-weight: 400;
    text-align: center;
    margin-top: 5px;
    margin-left: 10px;
    margin-right: 10px;
}
.popup_bottom_alimentation form {
    width: 80%;
    vertical-align: middle;
    margin-right: auto;
    margin-left: auto;
    margin-top: 10px;
}
.popup_bottom_alimentation label {
    color: #525252;
    font-size: 12px;
    font-weight: 700;
}
.popup_bottom_alimentation .selectbox {
    margin-top: 8px;
    padding-left: 5px;
}
.popup_bottom_alimentation .input {
    margin-top: 8px;
}
.popup_bottom_alimentation .btn_green {
    margin-bottom: 15px;
    margin-top: 5px;
}
.popup_alimentation {
    position: absolute;
    top: 90px;
    left: 50%;
    margin-left: -186px;
    z-index: 9999;
    height: 378px;
    width: 370px;
    background: #fff;
    -moz-box-shadow: 0 0 6px 2px #8a8a8a;
    -webkit-box-shadow: 0 0 6px 2px #8a8a8a;
    box-shadow: 0 0 6px 2px #8a8a8a;
    -moz-border-radius: 2px;
    -webkit-border-radius: 2px;
    border-radius: 3px;
}
.form_row_alimentation {
    margin-bottom: 6px;
}
.codebar_input {
    background-image: url(../images/code.png);
    background-repeat: no-repeat;
    background-position-x: 98%;
    background-position-y: 4px;
}
.popup_footer_alimentation {
    height: 40px;
    max-width: 100%;
    background-color: #f0f0f0;
    margin-top: 25px;
    border-radius: 0 0 3px 3px;
}
.popup_footer_alimentation a {
    margin-right: 10px;
    margin-top: 7px !important;
    float: right;
}
.checkbox_popup {
    float: left;
    margin-top: 10px;
    margin-left: 10px;
    font-weight: 400 !important;
}
.sauvegarde_auto {
    height: 50px;
    width: 170px;
    float: right;
    display: inline-block;
}
.sauvegarde_auto p {
    margin-top: 10px;
}
.sauvegarde_icon {
    display: inline-block;
    width: 14px;
    height: 15px;
    background-image: url(../images/sprite_5.png);
    background-repeat: no-repeat;
    background-position: -319px -702px;
    margin-left: 12px;
    margin-top: 13px;
    margin-right: 4px;
    float: left;
}
.encours_icon {
    display: inline-block;
    width: 20px;
    height: 20px;
    background-image: url(../images/sprite_5.png);
    background-repeat: no-repeat;
    background-position: -341px -700px;
    margin-left: 6px;
    margin-top: 9px;
    margin-right: 4px;
    float: left;
}
@-webkit-keyframes rotating {
    from {
        -ms-transform: rotate(0);
        -moz-transform: rotate(0);
        -webkit-transform: rotate(0);
        -o-transform: rotate(0);
        transform: rotate(0);
    }
    to {
        -ms-transform: rotate(360deg);
        -moz-transform: rotate(360deg);
        -webkit-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}
@keyframes rotating {
    from {
        -ms-transform: rotate(0);
        -moz-transform: rotate(0);
        -webkit-transform: rotate(0);
        -o-transform: rotate(0);
        transform: rotate(0);
    }
    to {
        -ms-transform: rotate(360deg);
        -moz-transform: rotate(360deg);
        -webkit-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}
.rotating {
    -webkit-animation: rotating 2s linear infinite;
    -moz-animation: rotating 2s linear infinite;
    -ms-animation: rotating 2s linear infinite;
    -o-animation: rotating 2s linear infinite;
    animation: rotating 2s linear infinite;
}
.popup_top_produit {
    height: 161px;
    background-color: #00beb0;
    border-radius: 3px 3px 0 0;
}
.popup_top_produit h3 {
    font-family: "Open Sans", sans-serif;
    font-size: 16px;
    font-weight: 700;
    color: #fff;
    text-align: left;
    float: left;
    display: inline-block;
    margin-left: 14px;
    margin-top: 29px;
    padding-bottom: 10px;
}
.popup_top_produit p {
    font-family: "Open Sans", sans-serif;
    font-size: 13px;
    font-weight: 400;
    color: #fff;
    text-align: left;
    float: left;
    display: block;
    margin-left: 175px;
    margin-top: 76px;
    position: absolute;
    width: 350px;
}
.popup_top_produit ul li {
    padding-bottom: 22px;
    margin-left: 2px;
}
.popup_top_produit ul {
    position: absolute;
    margin-top: -19px;
}
.popup_produit_footer {
    width: 100%;
    height: 52px;
    border-radius: 0 0 3px 3px;
    background-color: #f0f0f0;
    margin-bottom: -46px;
    display: inline-block;
    position: absolute;
    margin-left: -50px;
    margin-top: 13px;
}
.popup_bottom_produit {
    height: 152px;
    padding-top: 20px;
    background-color: #fff;
    border-radius: 0 0 3px 3px;
}
.popup_top_produit .popup_close {
    float: right;
}
.popup_top_produit .popup_close img {
    margin-left: -13px;
    margin-top: -6px;
}
.popup_bottom_produit h3 {
    color: #525252;
    font-size: 15px;
    font-weight: 700;
    text-align: center;
    text-transform: uppercase;
}
.popup_bottom_produit p {
    color: #525252;
    font-size: 14px;
    font-weight: 400;
    text-align: left;
    margin-top: 5px;
    padding-left: 10px;
    padding-right: 10px;
}
.popup_bottom_produit span {
    color: #00beb0;
    font-size: 14px;
    font-weight: 700;
    float: right;
    margin-top: 14px;
    padding-left: 10px;
    padding-right: 10px;
    margin-right: 70px;
}
.popup_produit {
    position: absolute;
    top: 53px;
    z-index: 9999;
    height: 470px;
    width: 600px;
    background: #fff;
    -moz-box-shadow: 0 0 6px 2px #8a8a8a;
    -webkit-box-shadow: 0 0 6px 2px #8a8a8a;
    box-shadow: 0 0 6px 2px #8a8a8a;
    -moz-border-radius: 2px;
    -webkit-border-radius: 2px;
    border-radius: 3px;
    left: 50%;
    margin-left: -300px;
}
.popup_produit_footer .btn_green {
    margin-right: 13px;
    margin-top: 13px;
    padding: 0 18px;
    font-size: 12px;
}
.popup_bottom_produit p {
    font-size: 13px;
    width: 270px;
    float: left;
}
.popup_produit_footer p {
    width: 400px;
    margin-top: 15px;
}
.popup_produit_img {
    height: 140px;
    width: 140px;
    border-radius: 50%;
    border: 5px solid #009f94;
    float: left;
    margin-top: 11px;
    margin-left: 22px;
    background-color: #fff;
}
.popup_produit_img img {
    width: 90px;
    vertical-align: middle;
    margin-right: auto;
    margin-left: auto;
    display: inherit;
    padding-top: 11px;
    border-radius: 50%;
    height: 130px;
}
.form_popup_produit {
    width: 500px;
    vertical-align: middle;
    margin-left: auto;
    margin-right: auto;
    margin-top: 22px;
}
.form_popup_produit input {
    width: 70% !important;
    float: right;
}
.form_popup_produit label {
    font-weight: 700;
}
.row_popup_produit {
    margin-bottom: 20px;
}
.row_popup_produit .popup_input_date {
    background-position-x: 98% !important;
    background-position-y: 2px !important;
    border: 3px solid #e13317;
}
.form_popup_produit .selectbox {
    width: 70% !important;
    float: right;
    -webkit-appearance: none;
    padding-left: 5px;
}
.attention_icon {
    display: inline-block;
    width: 30px;
    height: 30px;
    background-image: url(../images/sprite_5.png);
    background-repeat: no-repeat;
    background-position: -364px -630px;
    margin-left: 382px;
    margin-top: -3px;
    margin-right: 4px;
    float: right;
    position: absolute;
}
.clignote_tr {
    animation: pulse 0.5s infinite;
    animation-direction: alternate;
}
@keyframes pulse {
    0% {
        background-color: #fff;
    }
    100% {
        background-color: #f7ad1c;
    }
}
.btn_alerte_peremption {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: 1px solid #f0f0f0;
    display: inline-block;
    background-color: #fff;
}
.alerte_peremption_icon {
    display: inline-block;
    width: 8px;
    height: 16px;
    background-image: url(../images/sprite_5.png);
    background-repeat: no-repeat;
    background-position: -364px -666px;
    margin-left: 7px;
    margin-top: 2px;
    margin-right: 4px;
    float: left;
}
.form_inventaire {
    display: inline-block;
    width: 30%;
    float: right;
    margin-right: 5px;
}
.inventaire_header span {
    float: left;
    margin-top: 20px;
    font-weight: 700;
}
.inventaire_header form {
    float: right;
    width: 81%;
}
.btns li {
    display: inline-block;
    float: left;
}
.btns {
    display: none;
    height: 44px;
    width: 234px;
    position: fixed;
    bottom: 20px;
    z-index: 100;
    float: left;
    margin-left: -272px;
}
.btns a {
    transition: all ease-in-out 0.2s;
}
.btn_aide {
    display: inline-block;
    width: 95px;
    height: 44px;
    border-radius: 50px 50px 50px 50px;
    background-color: #fff;
    box-shadow: 0 1px 3px 0 #bcbcbc;
    -webkit-box-shadow: 0 1px 3px 0 #bcbcbc;
    -moz-box-shadow: 0 1px 3px 0 #bcbcbc;
    -o-box-shadow: 0 1px 3px 0 #bcbcbc;
    bottom: 20px;
    z-index: 100;
    transition: all ease-in-out 0.2s;
    cursor: pointer;
}
.aide_icon {
    display: inline-block;
    width: 31px;
    height: 31px;
    background-image: url(../images/sprite_5.png);
    background-repeat: no-repeat;
    background-position: -418px -538px;
    margin-left: 7px;
    margin-top: 7px;
    margin-right: 4px;
    float: left;
}
.btn_aide span {
    color: #505050;
    font-weight: 700;
    font-family: "Open Sans", sans-serif;
    font-size: 14px;
    margin-top: 11px;
    float: left;
    margin-left: 5px;
}
.btn_contact {
    display: inline-block;
    width: 120px;
    height: 44px;
    border-radius: 50px 50px 50px 50px;
    background-color: #fff;
    box-shadow: 0 1px 3px 0 #bcbcbc;
    -webkit-box-shadow: 0 1px 3px 0 #bcbcbc;
    -moz-box-shadow: 0 1px 3px 0 #bcbcbc;
    -o-box-shadow: 0 1px 3px 0 #bcbcbc;
    bottom: 20px;
    z-index: 100;
    transition: all ease-in-out 0.2s;
    cursor: pointer;
    margin-right: 8px;
}
.btn_contact span {
    color: #505050;
    font-weight: 700;
    font-family: "Open Sans", sans-serif;
    font-size: 14px;
    margin-top: 11px;
    float: left;
    margin-left: 5px;
}
.contact_icon {
    display: inline-block;
    width: 31px;
    height: 31px;
    background-image: url(../images/sprite_5.png);
    background-repeat: no-repeat;
    background-position: -453px -538px;
    margin-left: 7px;
    margin-top: 7px;
    margin-right: 4px;
    float: left;
}
.formulaire_wrapper {
    width: 313px;
    height: 400px;
    border-radius: 5px;
    background-color: #fff;
    box-shadow: 0 2px 14px -2px #bcbcbc;
    -webkit-box-shadow: 0 2px 14px -2px #bcbcbc;
    -moz-box-shadow: 0 2px 14px -2px #bcbcbc;
    -o-box-shadow: 0 2px 14px -2px #bcbcbc;
    position: fixed;
    z-index: 9999;
    right: 29px;
    bottom: 0;
    margin-bottom: 90px;
    display: none;
}
.formulaire_bloc_header {
    width: 100%;
    height: 50px;
    border-radius: 5px 5px 0 0;
    background-color: #62d400;
    padding-top: 15px;
    padding-left: 10px;
    margin-top: -23px;
    display: inline-block;
    padding-bottom: 53px;
}
.formulaire_bloc_header p {
    color: #fff;
}
.formulaire_bloc_header h3 {
    font-weight: 700;
    font-family: "Open Sans", sans-serif;
    color: #fff;
    font-size: 14px;
    text-align: left;
}
.formulaire_bloc_bottom {
    padding: 12px;
}
.form_contact_row {
    margin-bottom: 8px;
}
.form_contact_row select {
    width: 100%;
    height: 40px;
    border-radius: 3px;
    background-color: #fff;
    border: 2px solid #d6e7e7;
    color: #4f5361;
    font-family: "Open Sans", sans-serif;
    font-size: 12px;
    background-image: url(../images/select.png);
    background-repeat: no-repeat;
    -webkit-appearance: none;
    background-position-x: 97%;
    background-position-y: 14px;
    cursor: pointer;
    padding-left: 8px;
}
.form_contact_row textarea {
    width: 100%;
    height: 80px;
    border-radius: 3px;
    background-color: #fff;
    border: 2px solid #d6e7e7;
    color: #7c839a;
    font-family: "Open Sans", sans-serif;
    font-size: 12px;
    padding-left: 8px;
    padding-top: 5px;
}
.form_contact_row button {
    width: 100%;
    height: 40px;
    border-radius: 3px;
    background-color: #f6ac32;
    border: 2px solid #f6ac32;
    color: #fff;
    font-family: "Open Sans", sans-serif;
    font-size: 13px;
    padding-left: 8px;
    padding-top: 1px;
    text-align: center;
    font-weight: 700;
    cursor: pointer;
    transition: all ease-in-out 0.2s;
}
.form_contact_row button:hover {
    width: 100%;
    height: 40px;
    border-radius: 3px;
    background-color: #e8a028;
    border: 2px solid #e8a028;
    color: #fff;
    font-family: "Open Sans", sans-serif;
    font-size: 13px;
    padding-left: 8px;
    padding-top: 1px;
    text-align: center;
    font-weight: 700;
    cursor: pointer;
    transition: all ease-in-out 0.2s;
}
#file_import {
    border: 2px dashed #d6e7e7;
    position: relative;
    height: 60px;
    border-radius: 3px;
}
#file_import span {
    color: #7c839a;
    font-family: "Open Sans", sans-serif;
    font-size: 12px;
    text-align: center;
    margin-top: 18px;
    font-weight: 700;
    display: inherit;
}
#scroll {
    animation: translate 2s ease infinite;
    z-index: 1;
    float: left;
    position: relative;
}
@-webkit-keyframes translate {
    50% {
        transform: translateY(8px);
    }
}
.dash_post {
    margin-bottom: 10px;
    float: left;
    width: 100%;
}
.dash_post_header {
    width: 100%;
    display: block;
    float: left;
    margin-bottom: 10px;
}
.dash_post_header img {
    float: left !important;
    margin-right: 10px !important;
    display: inline-block !important;
    margin-top: 0 !important;
    width: 52px !important;
    height: 54px !important;
}
.dash_post_header h4 {
    font-family: "Open Sans", sans-serif;
    color: #494949;
    font-size: 13px;
    font-weight: 700;
    margin-top: 5px;
}
.dash_post_header p {
    margin-top: 5px !important;
    float: left;
    display: block;
    color: #828282 !important;
    font-size: 12px !important;
}
.dash_post p {
    font-size: 14px;
    color: #494949;
    font-family: "Open Sans", sans-serif;
    font-weight: 400;
    text-align: left;
    float: left;
    margin-top: 10px;
}
.post_sep {
    height: 1px;
    width: 98%;
    background-color: #dedede;
    margin-top: 20px;
    float: left;
}
.dash_post img {
    border-radius: 5px;
    display: block;
    margin-top: 10px;
    width: 100%;
    vertical-align: middle;
    display: inherit;
    margin-left: auto;
    margin-right: auto;
    height: auto;
}
.dash_post_header a {
    float: right;
    margin-top: -10px;
}
.dash_bloc {
    float: left;
    margin-bottom: 0;
}
.dash_bloc ul {
    float: left;
    display: inline-block;
    height: 30px;
}
.dash_bloc li {
    float: left;
    display: inline-block;
    margin-right: 10px;
}
.dash_bloc p {
    font-size: 14px;
    color: #494949;
    font-family: "Open Sans", sans-serif;
    font-weight: 400;
    text-align: left;
    float: left;
    margin-top: 14px;
}
.dash_bloc .views_icon {
    display: inline-block;
    width: 37px;
    height: 19px;
    background-image: url(../images/sprite_5.png);
    background-repeat: no-repeat;
    background-position: -268px -477px;
    margin-top: 15px;
    margin-right: 3px;
    float: left;
    margin-left: 0;
    background-size: 400px;
}
.dash_bloc .clicks_icon {
    display: inline-block;
    width: 37px;
    height: 29px;
    background-image: url(../images/sprite_5.png);
    background-repeat: no-repeat;
    background-position: -302px -472px;
    margin-top: 10px;
    margin-right: 3px;
    float: left;
    margin-left: 0;
    background-size: 400px;
}
.dashboard-bloc .views_icon {
    display: inline-block;
    width: 37px;
    height: 19px;
    background-image: url(../images/sprite_5.png);
    background-repeat: no-repeat;
    background-position: -268px -477px;
    margin-top: 15px;
    margin-right: 3px;
    float: left;
    margin-left: 0;
    background-size: 400px;
}
.dashboard-bloc .clicks_icon {
    display: inline-block;
    width: 37px;
    height: 29px;
    background-image: url(../images/sprite_5.png);
    background-repeat: no-repeat;
    background-position: -302px -472px;
    margin-top: 10px;
    margin-right: 3px;
    float: left;
    margin-left: 0;
    background-size: 400px;
}
.dash_bloc_screen {
    background-color: rgba(000, 000, 000, 0.5) !important;
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 9;
    margin-left: -10px;
    margin-top: -10px;
    padding-top: 150px;
    display: none;
}
.dash_news li {
    float: left;
    margin-bottom: 10px;
}
.dash_bloc_screen ul {
    width: 143px;
    vertical-align: middle;
    margin-left: auto;
    margin-right: auto;
    display: inherit;
    float: none;
}
.dash_bloc_screen ul li {
    width: 50%;
    margin-right: 0;
}
.dash_bloc_screen ul .dash_icon {
    width: 45px;
    height: 45px;
    border-radius: 50%;
    background-color: #fff;
    -webkit-box-shadow: 0 1px 3px 0 #bcbcbc;
    box-shadow: 0 1px 6px 0 #382e2e;
    vertical-align: middle;
    margin-right: auto;
    margin-left: auto;
    display: inherit;
}
.dash_icon .views_icon {
    margin-left: 4px;
    margin-top: 14px;
}
.dash_icon .clicks_icon {
    margin-left: 1px;
    margin-top: 8px;
}
.dash_bloc_screen p {
    color: #fff;
    text-align: center;
    display: inherit;
    float: none;
}
.dash_bloc_screen .btn_more {
    height: 70px;
    width: 410px;
    border-radius: 4px;
    background-color: #f5ac33;
    vertical-align: middle;
    margin-left: auto;
    margin-right: auto;
    display: inherit;
    font-family: "Open Sans", sans-serif;
    color: #fff;
    font-size: 23px;
    text-align: center;
    padding-top: 16px;
    margin-bottom: 20px;
}
.btn_load {
    float: left;
    width: 100%;
    display: inherit;
}
.bloc_radius {
    border-radius: 6px;
    height: 222px !important;
    min-height: 222px !important;
}
.dash_bloc_footer {
    height: 40px;
    bottom: 0;
    border-top: 1px solid #e5ecf6;
}
.dash_bloc_footer h3 {
    text-align: center;
    display: inherit;
    font-size: 16px;
    font-weight: 700;
    color: #75798d;
    margin-top: 10px;
}
.dash_bloc_top {
    height: 77%;
}
.dash_bloc_top h2 {
    font-size: 66px;
    font-family: "Open Sans", sans-serif;
    font-weight: 700;
    text-align: center;
    display: inherit;
    color: #9a9fbf;
    margin-top: 10px;
}
.dash_bloc_top p {
    text-align: center;
    color: #75798d;
    font-size: 14px;
    font-family: "Open Sans", sans-serif;
}
.non_valider {
    background-color: #db544c !important;
}
.non_valider h2 {
    font-size: 66px;
    font-family: "Open Sans", sans-serif;
    font-weight: 700;
    text-align: center;
    display: inherit;
    color: #fff;
    margin-top: 10px;
}
.non_valider p {
    text-align: center;
    color: #fff;
    font-size: 14px;
    font-family: "Open Sans", sans-serif;
}
.non_valider h3 {
    text-align: center;
    display: inherit;
    font-size: 16px;
    font-weight: 700;
    color: #fff;
    margin-top: 10px;
}
.non_valider .dash_bloc_footer {
    height: 40px;
    bottom: 0;
    border-top: 1px solid #fff;
}
.popup_top_interaction1 {
    height: 212px;
    background-color: #48a0dc;
    border-radius: 3px 3px 0 0;
}
.popup_bottom_interaction1 {
    height: auto;
    padding-top: 10px;
    background-color: #2881bd;
    border-radius: 0 0 3px 3px;
    display: inline-block;
    padding-bottom: 10px;
}
.popup_top_interaction1 img {
    margin-left: 20px;
    margin-top: 35px;
}
.popup_interaction_p p {
    font-size: 14px;
    color: #fff;
    font-family: "Open Sans", sans-serif;
    font-weight: 400;
}
.popup_interaction_p strong {
    font-size: 14px;
    color: #fff;
    font-family: "Open Sans", sans-serif;
    font-weight: 700;
}
.popup_top_interaction1 .popup_close {
    float: right;
}
.popup_top_interaction1 .popup_close img {
    margin-left: -13px;
    margin-top: -6px;
}
.popup_bottom_interaction1 h3 {
    color: #fff;
    font-size: 15px;
    font-weight: 700;
    text-align: left;
    text-transform: uppercase;
    margin-left: 20px;
    margin-top: 9px;
    margin-bottom: 6px;
    float: left;
}
.popup_bottom_interaction1 p {
    color: #fff;
    font-size: 12px;
    font-weight: 400;
    text-align: center;
    margin-top: 5px;
    margin-left: 10px;
    margin-right: 10px;
    margin-bottom: 15px;
}
.popup_interaction1 {
    position: absolute;
    top: 90px;
    left: 50%;
    margin-left: -306px;
    z-index: 9999;
    height: 507px;
    width: 620px;
    background: #fff;
    -moz-box-shadow: 0 0 6px 2px #8a8a8a;
    -webkit-box-shadow: 0 0 6px 2px #8a8a8a;
    box-shadow: 0 0 6px 2px #8a8a8a;
    -moz-border-radius: 2px;
    -webkit-border-radius: 2px;
    border-radius: 3px;
}
.popup_bottom_interaction1 .popup_molecule {
    background-color: #186ea8;
    width: 90%;
    border-radius: 3px;
    height: 32px;
    margin-left: 19px;
    margin-bottom: 10px;
    background-image: url(../images/molecule.png);
    background-repeat: no-repeat;
    background-position-x: 8px;
    background-position-y: 5px;
}
.popup_bottom_interaction1 .popup_molecule span {
    color: #fff;
    font-size: 12px;
    font-weight: 700;
    text-align: left;
    margin-left: 35px;
    margin-top: 5px;
    float: left;
}
.popup_produits {
    float: right;
    width: 376px;
    height: 158px;
    margin-top: 30px;
    margin-right: 20px;
}
.popup_produits .popup_interaction1_p {
    width: 100%;
    height: 45px;
    border-radius: 3px;
    background-color: #2881bd;
    margin-bottom: 8px;
    padding-top: 2px;
    padding-left: 12px;
}
.interactionplus1_icon {
    display: inline-block;
    width: 32px;
    height: 32px;
    background-image: url(../images/sprite_5.png);
    background-repeat: no-repeat;
    background-position: -490px -523px;
    margin-top: 6px;
    margin-left: -15px;
    float: left;
    margin-top: -18px;
    position: absolute;
}
.popup_bottom_interaction1 .sep {
    width: 100%;
    height: 1px;
    background-color: #1b6da4;
    margin-top: 12px;
    margin-bottom: 6px;
    display: inline-block;
}
.risk_nature {
    width: 100%;
    padding-left: 20px;
    padding-right: 20px;
}
.risk_nature strong {
    font-weight: 700;
    font-size: 14px;
    font-family: "Open Sans", sans-serif;
    float: left;
    width: 100%;
    color: #fff;
    text-align: left;
}
.risk_nature p {
    font-weight: 400;
    font-size: 14px;
    font-family: "Open Sans", sans-serif;
    float: left;
    width: 100%;
    color: #fff;
    text-align: left;
    margin-left: 0 !important;
    margin-top: 0;
    margin-bottom: 0;
}
.conduite {
    width: 100%;
    padding-left: 20px;
    padding-right: 20px;
}
.conduite strong {
    font-weight: 700;
    font-size: 14px;
    font-family: "Open Sans", sans-serif;
    float: left;
    width: 100%;
    color: #fff;
    text-align: left;
}
.conduite p {
    font-weight: 400;
    font-size: 14px;
    font-family: "Open Sans", sans-serif;
    float: left;
    width: 100%;
    color: #fff;
    text-align: left;
    margin-left: 0 !important;
    margin-top: 0;
    margin-bottom: 0;
}
.popup_interaction1_footer {
    height: 45px;
    width: 100%;
    background-color: #1b6da4;
    display: inline-block;
    bottom: 0;
    margin-bottom: -13px;
    margin-top: 20px;
}
.popup_interaction1_footer .btn_yellow {
    margin-bottom: 15px;
    margin-top: 10px;
    float: right;
    margin-right: 20px;
}
.popup_interaction1_footer p {
    float: left;
    font-family: "Open Sans", sans-serif;
    font-size: 13px;
    color: #fff;
    font-weight: 400;
    margin-top: 12px !important;
    margin-left: 20px !important;
}
.popup_interaction1_footer a {
    font-family: "Open Sans", sans-serif;
    font-size: 13px;
    color: #fff;
    font-weight: 700;
}
.popup_interaction1_footer a:hover {
    font-family: "Open Sans", sans-serif;
    font-size: 13px;
    color: #fff;
    font-weight: 700;
    text-decoration: underline;
}
.popup_jauge {
    float: right;
    margin-right: 20px;
    height: 27px;
    width: 142px;
    border-radius: 3px;
    border: 2px solid #fff;
    background-color: #fff;
    display: inline-block;
    margin-top: 4px;
    padding: 2px;
}
.jauge_n1 {
    width: 32px;
    height: 19px;
    border-radius: 3px;
    background-color: #fdd466;
    margin-right: -1px;
    display: inline-block;
}
.jauge_n2 {
    width: 32px;
    height: 19px;
    border-radius: 3px;
    background-color: #efab08;
    margin-right: -1px;
    display: inline-block;
}
.jauge_n3 {
    width: 32px;
    height: 19px;
    border-radius: 3px;
    background-color: #ef8008;
    margin-right: -1px;
    display: inline-block;
}
.jauge_n4 {
    width: 32px;
    height: 19px;
    border-radius: 3px;
    background-color: #e83e09;
    margin-right: -1px;
    display: inline-block;
}
.jauge_disable {
    width: 32px;
    height: 19px;
    border-radius: 3px;
    background-color: rgba(000, 000, 000, 0.3);
    margin-right: -1px;
    display: inline-block;
}
.popup_top_interaction2 {
    height: 212px;
    background-color: #9373b7;
    border-radius: 3px 3px 0 0;
}
.popup_bottom_interaction2 {
    height: auto;
    padding-top: 10px;
    background-color: #6b4694;
    border-radius: 0 0 3px 3px;
    display: inline-block;
    padding-bottom: 10px;
}
.popup_top_interaction2 img {
    margin-left: 20px;
    margin-top: 35px;
}
.popup_interaction_p p {
    font-size: 14px;
    color: #fff;
    font-family: "Open Sans", sans-serif;
    font-weight: 400;
}
.popup_interaction_p strong {
    font-size: 14px;
    color: #fff;
    font-family: "Open Sans", sans-serif;
    font-weight: 700;
}
.popup_top_interaction2 .popup_close {
    float: right;
}
.popup_top_interaction2 .popup_close img {
    margin-left: -13px;
    margin-top: -6px;
}
.popup_bottom_interaction2 h3 {
    color: #fff;
    font-size: 15px;
    font-weight: 700;
    text-align: left;
    text-transform: uppercase;
    margin-left: 20px;
    margin-top: 9px;
    margin-bottom: 6px;
    float: left;
}
.popup_bottom_interaction2 p {
    color: #fff;
    font-size: 12px;
    font-weight: 400;
    text-align: center;
    margin-top: 5px;
    margin-left: 10px;
    margin-right: 10px;
    margin-bottom: 15px;
}
.popup_interaction2 {
    position: absolute;
    top: 90px;
    left: 50%;
    margin-left: -306px;
    z-index: 9999;
    height: 507px;
    width: 620px;
    background: #fff;
    -moz-box-shadow: 0 0 6px 2px #8a8a8a;
    -webkit-box-shadow: 0 0 6px 2px #8a8a8a;
    box-shadow: 0 0 6px 2px #8a8a8a;
    -moz-border-radius: 2px;
    -webkit-border-radius: 2px;
    border-radius: 3px;
}
.popup_bottom_interaction2 .popup_molecule {
    background-color: #186ea8;
    width: 90%;
    border-radius: 3px;
    height: 32px;
    margin-left: 19px;
    margin-bottom: 10px;
    background-image: url(../images/molecule.png);
    background-repeat: no-repeat;
    background-position-x: 8px;
    background-position-y: 5px;
}
.popup_bottom_interaction2 .popup_molecule span {
    color: #fff;
    font-size: 12px;
    font-weight: 700;
    text-align: left;
    margin-left: 35px;
    margin-top: 5px;
    float: left;
}
.popup_produits {
    float: right;
    width: 376px;
    height: 158px;
    margin-top: 30px;
    margin-right: 20px;
}
.popup_produits .popup_interaction2_p {
    width: 100%;
    height: 45px;
    border-radius: 3px;
    background-color: #593086;
    margin-bottom: 8px;
    padding-top: 2px;
    padding-left: 12px;
}
.interactionplus2_icon {
    display: inline-block;
    width: 32px;
    height: 32px;
    background-image: url(../images/sprite_5.png);
    background-repeat: no-repeat;
    background-position: -521px -523px;
    margin-top: 6px;
    margin-left: -15px;
    float: left;
    margin-top: -18px;
    position: absolute;
}
.popup_bottom_interaction2 .sep {
    width: 100%;
    height: 1px;
    background-color: #593086;
    margin-top: 12px;
    margin-bottom: 6px;
    display: inline-block;
}
.risk_nature {
    width: 100%;
    padding-left: 20px;
    padding-right: 20px;
}
.risk_nature strong {
    font-weight: 700;
    font-size: 14px;
    font-family: "Open Sans", sans-serif;
    float: left;
    width: 100%;
    color: #fff;
    text-align: left;
}
.risk_nature p {
    font-weight: 400;
    font-size: 14px;
    font-family: "Open Sans", sans-serif;
    float: left;
    width: 100%;
    color: #fff;
    text-align: left;
    margin-left: 0 !important;
    margin-top: 0;
    margin-bottom: 0;
}
.conduite {
    width: 100%;
    padding-left: 20px;
    padding-right: 20px;
}
.conduite strong {
    font-weight: 700;
    font-size: 14px;
    font-family: "Open Sans", sans-serif;
    float: left;
    width: 100%;
    color: #fff;
    text-align: left;
}
.conduite p {
    font-weight: 400;
    font-size: 14px;
    font-family: "Open Sans", sans-serif;
    float: left;
    width: 100%;
    color: #fff;
    text-align: left;
    margin-left: 0 !important;
    margin-top: 0;
    margin-bottom: 0;
}
.popup_interaction2_footer {
    height: 45px;
    width: 100%;
    background-color: #593086;
    display: inline-block;
    bottom: 0;
    margin-bottom: -13px;
    margin-top: 20px;
}
.popup_interaction2_footer .btn_yellow {
    margin-bottom: 15px;
    margin-top: 10px;
    float: right;
    margin-right: 20px;
}
.popup_interaction2_footer p {
    float: left;
    font-family: "Open Sans", sans-serif;
    font-size: 13px;
    color: #fff;
    font-weight: 400;
    margin-top: 12px !important;
    margin-left: 20px !important;
}
.popup_interaction2_footer a {
    font-family: "Open Sans", sans-serif;
    font-size: 13px;
    color: #fff;
    font-weight: 700;
}
.popup_interaction2_footer a:hover {
    font-family: "Open Sans", sans-serif;
    font-size: 13px;
    color: #fff;
    font-weight: 700;
    text-decoration: underline;
}
.popup_top_interaction3 {
    height: 212px;
    background-color: #fcd768;
    border-radius: 3px 3px 0 0;
}
.popup_bottom_interaction3 {
    height: auto;
    padding-top: 10px;
    background-color: #ecb71a;
    border-radius: 0 0 3px 3px;
    display: inline-block;
    padding-bottom: 10px;
}
.popup_top_interaction3 img {
    margin-left: 20px;
    margin-top: 35px;
}
.popup_interaction_p p {
    font-size: 14px;
    color: #fff;
    font-family: "Open Sans", sans-serif;
    font-weight: 400;
}
.popup_interaction_p strong {
    font-size: 14px;
    color: #fff;
    font-family: "Open Sans", sans-serif;
    font-weight: 700;
}
.popup_top_interaction3 .popup_close {
    float: right;
}
.popup_top_interaction3 .popup_close img {
    margin-left: -13px;
    margin-top: -6px;
}
.popup_bottom_interaction3 h3 {
    color: #fff;
    font-size: 15px;
    font-weight: 700;
    text-align: left;
    text-transform: uppercase;
    margin-left: 20px;
    margin-top: 9px;
    margin-bottom: 6px;
    float: left;
}
.popup_bottom_interaction3 p {
    color: #fff;
    font-size: 12px;
    font-weight: 400;
    text-align: center;
    margin-top: 5px;
    margin-left: 10px;
    margin-right: 10px;
    margin-bottom: 15px;
}
.popup_interaction3 {
    position: absolute;
    top: 90px;
    left: 50%;
    margin-left: -306px;
    z-index: 9999;
    height: 507px;
    width: 620px;
    background: #fff;
    -moz-box-shadow: 0 0 6px 2px #8a8a8a;
    -webkit-box-shadow: 0 0 6px 2px #8a8a8a;
    box-shadow: 0 0 6px 2px #8a8a8a;
    -moz-border-radius: 2px;
    -webkit-border-radius: 2px;
    border-radius: 3px;
}
.popup_bottom_interaction3 .popup_molecule {
    background-color: #186ea8;
    width: 90%;
    border-radius: 3px;
    height: 32px;
    margin-left: 19px;
    margin-bottom: 10px;
    background-image: url(../images/molecule.png);
    background-repeat: no-repeat;
    background-position-x: 8px;
    background-position-y: 5px;
}
.popup_bottom_interaction3 .popup_molecule span {
    color: #fff;
    font-size: 12px;
    font-weight: 700;
    text-align: left;
    margin-left: 35px;
    margin-top: 5px;
    float: left;
}
.popup_produits {
    float: right;
    width: 376px;
    height: 158px;
    margin-top: 30px;
    margin-right: 20px;
}
.popup_produits .popup_interaction3_p {
    width: 100%;
    height: 45px;
    border-radius: 3px;
    background-color: #e6af0d;
    margin-bottom: 8px;
    padding-top: 2px;
    padding-left: 12px;
}
.interactionplus3_icon {
    display: inline-block;
    width: 32px;
    height: 32px;
    background-image: url(../images/sprite_5.png);
    background-repeat: no-repeat;
    background-position: -551px -523px;
    margin-top: 6px;
    margin-left: -15px;
    float: left;
    margin-top: -18px;
    position: absolute;
}
.popup_bottom_interaction3 .sep {
    width: 100%;
    height: 1px;
    background-color: #cf9c04;
    margin-top: 12px;
    margin-bottom: 6px;
    display: inline-block;
}
.risk_nature {
    width: 100%;
    padding-left: 20px;
    padding-right: 20px;
}
.risk_nature strong {
    font-weight: 700;
    font-size: 14px;
    font-family: "Open Sans", sans-serif;
    float: left;
    width: 100%;
    color: #fff;
    text-align: left;
}
.risk_nature p {
    font-weight: 400;
    font-size: 14px;
    font-family: "Open Sans", sans-serif;
    float: left;
    width: 100%;
    color: #fff;
    text-align: left;
    margin-left: 0 !important;
    margin-top: 0;
    margin-bottom: 0;
}
.conduite {
    width: 100%;
    padding-left: 20px;
    padding-right: 20px;
}
.conduite strong {
    font-weight: 700;
    font-size: 14px;
    font-family: "Open Sans", sans-serif;
    float: left;
    width: 100%;
    color: #fff;
    text-align: left;
}
.conduite p {
    font-weight: 400;
    font-size: 14px;
    font-family: "Open Sans", sans-serif;
    float: left;
    width: 100%;
    color: #fff;
    text-align: left;
    margin-left: 0 !important;
    margin-top: 0;
    margin-bottom: 0;
}
.popup_interaction3_footer {
    height: 45px;
    width: 100%;
    background-color: #ca9803;
    display: inline-block;
    bottom: 0;
    margin-bottom: -14px;
    margin-top: 20px;
    border-radius: 0 0 3px 3px;
}
.popup_interaction3_footer .btn_yellow {
    margin-bottom: 15px;
    margin-top: 10px;
    float: right;
    margin-right: 20px;
}
.popup_interaction3_footer p {
    float: left;
    font-family: "Open Sans", sans-serif;
    font-size: 13px;
    color: #fff;
    font-weight: 400;
    margin-top: 12px !important;
    margin-left: 20px !important;
}
.popup_interaction3_footer a {
    font-family: "Open Sans", sans-serif;
    font-size: 13px;
    color: #fff;
    font-weight: 700;
}
.popup_interaction3_footer a:hover {
    font-family: "Open Sans", sans-serif;
    font-size: 13px;
    color: #fff;
    font-weight: 700;
    text-decoration: underline;
}
.popup_top_interaction4 {
    height: 212px;
    background-color: #ec5652;
    border-radius: 3px 3px 0 0;
}
.popup_bottom_interaction4 {
    height: auto;
    padding-top: 10px;
    background-color: #de322e;
    border-radius: 0 0 3px 3px;
    display: inline-block;
    padding-bottom: 10px;
}
.popup_top_interaction4 img {
    margin-left: 20px;
    margin-top: 35px;
}
.popup_interaction_p p {
    font-size: 14px;
    color: #fff;
    font-family: "Open Sans", sans-serif;
    font-weight: 400;
}
.popup_interaction_p strong {
    font-size: 14px;
    color: #fff;
    font-family: "Open Sans", sans-serif;
    font-weight: 700;
}
.popup_top_interaction4 .popup_close {
    float: right;
}
.popup_top_interaction4 .popup_close img {
    margin-left: -13px;
    margin-top: -6px;
}
.popup_bottom_interaction4 h3 {
    color: #fff;
    font-size: 15px;
    font-weight: 700;
    text-align: left;
    text-transform: uppercase;
    margin-left: 20px;
    margin-top: 9px;
    margin-bottom: 6px;
    float: left;
}
.popup_bottom_interaction4 p {
    color: #fff;
    font-size: 12px;
    font-weight: 400;
    text-align: center;
    margin-top: 5px;
    margin-left: 10px;
    margin-right: 10px;
    margin-bottom: 15px;
}
.popup_interaction4 {
    position: absolute;
    top: 90px;
    left: 50%;
    margin-left: -306px;
    z-index: 9999;
    height: 507px;
    width: 620px;
    background: #fff;
    -moz-box-shadow: 0 0 6px 2px #8a8a8a;
    -webkit-box-shadow: 0 0 6px 2px #8a8a8a;
    box-shadow: 0 0 6px 2px #8a8a8a;
    -moz-border-radius: 2px;
    -webkit-border-radius: 2px;
    border-radius: 3px;
}
.popup_bottom_interaction4 .popup_molecule {
    background-color: #186ea8;
    width: 90%;
    border-radius: 3px;
    height: 32px;
    margin-left: 19px;
    margin-bottom: 10px;
    background-image: url(../images/molecule.png);
    background-repeat: no-repeat;
    background-position-x: 8px;
    background-position-y: 5px;
}
.popup_bottom_interaction4 .popup_molecule span {
    color: #fff;
    font-size: 12px;
    font-weight: 700;
    text-align: left;
    margin-left: 35px;
    margin-top: 5px;
    float: left;
}
.popup_produits {
    float: right;
    width: 376px;
    height: 158px;
    margin-top: 30px;
    margin-right: 20px;
}
.popup_produits .popup_interaction4_p {
    width: 100%;
    height: 45px;
    border-radius: 3px;
    background-color: #de322e;
    margin-bottom: 8px;
    padding-top: 2px;
    padding-left: 12px;
}
.interactionplus4_icon {
    display: inline-block;
    width: 32px;
    height: 32px;
    background-image: url(../images/sprite_5.png);
    background-repeat: no-repeat;
    background-position: -582px -523px;
    margin-top: 6px;
    margin-left: -15px;
    float: left;
    margin-top: -18px;
    position: absolute;
}
.popup_bottom_interaction4 .sep {
    width: 100%;
    height: 1px;
    background-color: #be1d19;
    margin-top: 12px;
    margin-bottom: 6px;
    display: inline-block;
}
.risk_nature {
    width: 100%;
    padding-left: 20px;
    padding-right: 20px;
}
.risk_nature strong {
    font-weight: 700;
    font-size: 14px;
    font-family: "Open Sans", sans-serif;
    float: left;
    width: 100%;
    color: #fff;
    text-align: left;
}
.risk_nature p {
    font-weight: 400;
    font-size: 14px;
    font-family: "Open Sans", sans-serif;
    float: left;
    width: 100%;
    color: #fff;
    text-align: left;
    margin-left: 0 !important;
    margin-top: 0;
    margin-bottom: 0;
}
.conduite {
    width: 100%;
    padding-left: 20px;
    padding-right: 20px;
}
.conduite strong {
    font-weight: 700;
    font-size: 14px;
    font-family: "Open Sans", sans-serif;
    float: left;
    width: 100%;
    color: #fff;
    text-align: left;
}
.conduite p {
    font-weight: 400;
    font-size: 14px;
    font-family: "Open Sans", sans-serif;
    float: left;
    width: 100%;
    color: #fff;
    text-align: left;
    margin-left: 0 !important;
    margin-top: 0;
    margin-bottom: 0;
}
.popup_interaction4_footer {
    height: 45px;
    width: 100%;
    background-color: #be1d19;
    display: inline-block;
    bottom: 0;
    margin-bottom: -14px;
    margin-top: 20px;
    border-radius: 0 0 3px 3px;
}
.popup_interaction4_footer .btn_yellow {
    margin-bottom: 15px;
    margin-top: 10px;
    float: right;
    margin-right: 20px;
}
.popup_interaction4_footer p {
    float: left;
    font-family: "Open Sans", sans-serif;
    font-size: 13px;
    color: #fff;
    font-weight: 400;
    margin-top: 12px !important;
    margin-left: 20px !important;
}
.popup_interaction4_footer a {
    font-family: "Open Sans", sans-serif;
    font-size: 13px;
    color: #fff;
    font-weight: 700;
}
.popup_interaction4_footer a:hover {
    font-family: "Open Sans", sans-serif;
    font-size: 13px;
    color: #fff;
    font-weight: 700;
    text-decoration: underline;
}
.grid_remise {
    margin-top: -25px;
    height: 60px;
}
.sep span {
    width: 97%;
    position: relative;
    height: 1px;
    background: #bbb;
    left: 0;
    display: inline-block;
    margin-left: 15px;
}
.reste_title strong {
    font-weight: 700;
    font-family: "Open Sans", sans-serif;
    font-size: 15px;
    color: #00beb0 !important;
    text-transform: uppercase;
    font-weight: 700;
    margin-top: 10px;
    margin-left: 8px;
}
.monnaie_row strong {
    font-weight: 700;
    font-family: "Open Sans", sans-serif;
    font-size: 14px;
    color: #5d5d5d !important;
    text-transform: capitalize;
    font-weight: 700;
    margin-top: 10px;
    margin-left: 8px;
}
.footer_table {
    background: #e9e9e9;
    border-top: 1px solid #8d8d8d;
    color: #494949 !important;
}
.footer_table:hover {
    background: #e9e9e9 !important;
    border-top: 1px solid #8d8d8d;
    color: #494949 !important;
}
.footer_table:hover td {
    color: #494949 !important;
}
.credit_bloc {
    width: 97.8%;
    height: 136px;
    border-radius: 3px;
    background-color: #f8f8f8;
    margin-top: 10px;
    margin-left: 10px;
    padding: 19px;
}
.credit_bloc .bloc_details {
    width: 24%;
    height: 100px;
    border: 1px solid #bbb;
    border-radius: 3px;
    display: inline-block;
    background-color: #fff;
    margin-right: 13%;
}
.credit_bloc .bloc_details_top {
    height: 65%;
    float: left;
    display: inline-block;
    width: 100%;
}
.credit_bloc .bloc_details_bottom {
    height: 35%;
    float: left;
    width: 100%;
    background-color: #f1f1f1;
    border-radius: 0 0 3px 3px;
    border-top: 1px solid #bbb;
}
.credit_bloc .bloc_details_top span {
    font-weight: 700;
    font-family: "Open Sans", sans-serif;
    font-size: 22px;
    color: #10cc83;
    text-align: center;
    margin-top: 16px;
    display: inherit;
}
.credit_bloc .bloc_details_bottom p {
    font-weight: 400;
    font-family: "Open Sans", sans-serif;
    font-size: 14px;
    color: #5d5d5d;
    text-align: center;
    margin-top: 5px;
    display: inherit;
}
.icon_moin {
    display: inline-block;
    width: 31px;
    height: 31px;
    background-image: url(../images/sprite_5.png);
    background-repeat: no-repeat;
    background-position: -492px -555px;
    margin-top: 32px;
    position: absolute;
    float: left;
    margin-left: -7.9%;
}
.icon_egal {
    display: inline-block;
    width: 31px;
    height: 31px;
    background-image: url(../images/sprite_5.png);
    background-repeat: no-repeat;
    background-position: -524px -555px;
    margin-top: 32px;
    position: absolute;
    float: left;
    margin-left: -8.4%;
}
.popup_jauge2 {
    float: right;
    margin-right: 20px;
    height: 40px;
    width: 142px;
    border-radius: 3px;
    border: 2px solid #fff;
    background-color: #fff;
    display: inline-block;
    margin-top: 0;
    padding: 2px;
}
.jauge2 {
    display: inline-block;
    width: 31px;
    height: 31px;
    background-image: url(../images/sprite_5.png);
    background-repeat: no-repeat;
    background-position: -526px -491px;
    margin-top: 2px;
    position: relative;
    float: left;
    display: inline-block;
    margin-right: 2px;
}
.plan-empty2 {
    min-height: 470px;
    padding: 70px 20px;
    text-align: center;
}
.plan-empty2 p {
    font-size: 16px;
    font-weight: 800;
    text-transform: uppercase;
    font-family: "Open Sans", sans-serif;
    color: #42afe2 !important;
}
.plan-empty2 span {
    display: inline-block;
    padding: 40px 42px;
    margin: 62px 0;
    border-radius: 50%;
    border: 4px solid #898989;
}
.empty-search-ico2 {
    display: inherit;
    width: 163px;
    height: 163px;
    background-image: url(../images/sprite_5.png);
    background-repeat: no-repeat;
    background-position: -5px -775px;
    margin-top: 32px;
    position: relative;
    vertical-align: middle;
    margin-left: auto;
    margin-right: auto;
}
.vente_champs_cb {
    width: 94% !important;
}
.vente_champs_cb .selectbox {
    width: 20%;
    float: left;
}
.vente_champs_cb .input_douchette {
    width: 79%;
    float: right;
}
.vente_icon {
    display: inline-block;
    width: 27px;
    height: 27px;
    background-image: url(../images/sprite_5.png);
    background-repeat: no-repeat;
    background-position: -454px -608px;
    position: relative;
    float: left;
}
.vente_fleche {
    display: inline-block;
    width: 51px;
    height: 40px;
    background-image: url(../images/sprite_5.png);
    background-repeat: no-repeat;
    background-position: -491px -587px;
    position: relative;
    margin-left: -70px;
}
.btn_loadmore {
    height: 40px;
    width: 100%;
    background-color: #fff;
    position: relative;
    display: inline-block;
    font-family: "Open Sans", sans-serif;
    font-size: 14px;
    color: #494949;
    text-align: center;
    padding-top: 9px;
    transition: all ease-in-out 0.2s;
}
.btn_loadmore:hover {
    height: 40px;
    width: 100%;
    background-color: #f0f0f0;
    position: relative;
    display: inline-block;
    font-family: "Open Sans", sans-serif;
    font-size: 14px;
    color: #494949;
    text-align: center;
    padding-top: 9px;
    transition: all ease-in-out 0.2s;
}
.loadmore_icon {
    display: inline-block;
    width: 17px;
    height: 13px;
    background-image: url(../images/sprite_5.png);
    background-repeat: no-repeat;
    background-position: -558px -555px;
    position: relative;
    margin-left: 5px;
}
.no_padding_popup {
    padding: 0 !important;
}
.popup_table_footer:hover {
    background-color: #f8f8f8 !important;
}
.remise_bloc {
    border: 2px solid #f6ac32 !important;
}
.remise_total {
    background-color: #eee !important;
}
.remise_total td:hover {
    background-color: #eee !important;
    color: #494949 !important;
}
.remise_total td {
    color: #494949 !important;
}
.popup_top_menu {
    height: 184px;
    background-color: #d0eef5;
    border-radius: 3px 3px 0 0;
}
.popup_bottom_menu {
    height: 185px;
    padding-top: 10px;
    background-color: #fff;
    border-radius: 0 0 3px 3px;
}
.popup_top_menu img {
    margin-top: -1px;
}
.popup_top_menu .popup_close {
    float: right;
    position: absolute;
    margin-left: 506px;
    margin-top: -38px;
}
.popup_top_menu .popup_close img {
    margin-left: -13px;
    margin-top: 42px;
}
.popup_bottom_menu h3 {
    color: #525252;
    font-size: 15px;
    font-weight: 700;
    text-align: center;
    text-transform: uppercase;
    margin-top: 12px;
}
.popup_bottom_menu p {
    color: #525252;
    font-size: 12px;
    font-weight: 400;
    text-align: center;
    margin-top: 5px;
    margin-left: 10px;
    margin-right: 10px;
}
.popup_bottom_menu form {
    width: 80%;
    vertical-align: middle;
    margin-right: auto;
    margin-left: auto;
    margin-top: 10px;
}
.popup_bottom_menu label {
    color: #525252;
    font-size: 12px;
    font-weight: 700;
}
.popup_bottom_menu .selectbox {
    margin-top: 8px;
    padding-left: 5px;
}
.popup_bottom_menu .input {
    margin-top: 8px;
}
.popup_bottom_menu .btn_green {
    margin-bottom: 15px;
    margin-top: 5px;
}
.popup_menu {
    position: absolute;
    top: 90px;
    left: 50%;
    margin-left: -266px;
    z-index: 99999;
    height: 378px;
    width: 509px;
    background: #fff;
    -moz-box-shadow: 0 0 6px 2px #6c7b7d;
    -webkit-box-shadow: 0 0 6px 2px #6c7b7d;
    box-shadow: 0 0 6px 2px #6c7b7d;
    -moz-border-radius: 2px;
    -webkit-border-radius: 2px;
    border-radius: 3px;
}
.form_row_menu {
    margin-bottom: 6px;
}
.popup_footer_menu {
    height: 40px;
    max-width: 100%;
    background-color: #f0f0f0;
    margin-top: 39px;
    border-radius: 0 0 3px 3px;
}
.popup_footer_menu a {
    margin-right: 10px;
    margin-top: 7px !important;
    float: right;
}
.checkbox_popup {
    float: left;
    margin-top: 10px;
    margin-left: 10px;
    font-weight: 400 !important;
}
.popup_footer_menu span {
    float: left;
    margin-left: 10px;
    margin-top: 9px;
    font-weight: 700;
    color: #929191;
    font-size: 14px;
}
.tel_icon {
    display: inline-block;
    width: 24px;
    height: 26px;
    background-image: url(../images/sprite_5.png);
    background-repeat: no-repeat;
    background-position: -585px -557px;
    position: relative;
    cursor: pointer;
    float: left;
    margin-right: 10px;
}
.product_photo {
    width: 110px !important;
    height: 110px !important;
    border-radius: 50%;
    border: 1px solid #a2a2b4;
}
.contact_succes {
    width: 121px;
    vertical-align: middle;
    display: inherit;
    margin-left: auto;
    margin-right: auto;
    margin-top: 30px;
    margin-bottom: 12px;
}
.succes_bloc_icon {
    width: 56px;
    height: 56px;
    background-image: url(../images/sprite_5.png);
    background-repeat: no-repeat;
    background-position: -180px -765px;
    vertical-align: middle;
    display: inherit;
    margin-left: auto;
    margin-right: auto;
    margin-top: 10px;
}
#succes_bloc_msg {
    font-family: "Open Sans", sans-serif;
    font-size: 14px;
    font-weight: 700;
    color: #585858;
    text-align: center;
    display: inherit;
    margin-bottom: 12px;
}
.tooltip_zoom {
    position: absolute;
    z-index: 999999;
    margin-left: 30px;
    display: none;
}
.tooltip_zoom img {
    border: 10px solid rgba(000, 000, 000, 0.5);
    border-radius: 8px !important;
    max-width: 450px;
    background-color: #fff;
}
.tooltip_zoom .chargement_tooltip {
    padding: 120px;
}
#peremp_strong {
    font-weight: 700;
    color: #e75540;
}
#peremp_strong_black {
    font-weight: 700;
    color: #888;
}
#multiprix_strong {
    font-weight: 700;
    color: #f7ad1c;
}
.peremp_icon {
    width: 20px;
    height: 18px;
    background-image: url(../images/sprite_5.png);
    background-repeat: no-repeat;
    background-position: -265px -735px;
    float: left;
}
.peremp_icon_black {
    width: 20px;
    height: 18px;
    background-image: url(../images/sprite_5.png);
    background-repeat: no-repeat;
    background-position: -265px -779px;
    float: left;
}
.multiprix_icon {
    width: 20px;
    height: 18px;
    background-image: url(../images/sprite_5.png);
    background-repeat: no-repeat;
    background-position: -285px -735px;
    float: left;
    margin-top: 2px;
}
.checkbox_footer {
    float: left;
}
.product_default_photo {
    width: 111px !important;
}
.bloc_subscription {
    width: 100%;
    height: 73px;
}
.bloc_subscription_icon {
    height: 46px;
    width: 46px;
    border-radius: 50%;
    background-color: #f6f6f6;
    float: left;
    margin-left: 12px;
    margin-top: 14px;
    display: inline-block;
}
.bloc_subscription h4 {
    color: #4a4a4a;
    font-family: "Open Sans", sans-serif;
    text-transform: uppercase;
    font-weight: 400;
    font-size: 15px;
    margin-top: 17px;
    float: left;
    margin-left: 10px;
    width: 60%;
}
.bloc_subscription span {
    color: #00beb0;
    font-family: "Open Sans", sans-serif;
    text-transform: uppercase;
    font-weight: 400;
    font-size: 13px;
    margin-top: 0;
    float: left;
    margin-left: 10px;
}
.bloc_subscription strong {
    color: #00beb0;
}
.subscription_icon {
    display: inline-block;
    width: 32px;
    height: 32px;
    background-image: url(../images/sprite_5.png);
    background-repeat: no-repeat;
    background-position: -551px -591px;
    margin-top: 6px;
    margin-left: 7px;
    float: left;
    margin-top: 7px;
    position: absolute;
}
.paiement_actif_icon {
    display: inline-block;
    width: 26px;
    height: 26px;
    background-image: url(../images/sprite_5.png);
    background-repeat: no-repeat;
    background-position: -421px -636px;
    margin-top: 6px;
    float: left;
    position: absolute;
}
.paiement_nonactif_icon {
    display: inline-block;
    width: 26px;
    height: 26px;
    background-image: url(../images/sprite_5.png);
    background-repeat: no-repeat;
    background-position: -448px -636px;
    margin-top: 6px;
    float: left;
    position: absolute;
}
.bloc_subscription_statut {
    float: right;
    position: absolute;
    margin-left: 90%;
    margin-top: 16px;
}
.bloc_rest_paiement {
    height: 170px;
    margin-bottom: 0;
}
.bloc_rest_paiement h2 {
    color: #00beb0;
    font-family: "Open Sans", sans-serif;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 18px;
    margin-top: 17px;
    float: left;
    margin-left: 10px;
}
.bloc_rest_paiement p {
    color: #4a4a4a;
    font-family: "Open Sans", sans-serif;
    font-weight: 400;
    font-size: 14px;
    margin-top: 44px;
    float: left;
    margin-left: 10px;
    position: absolute;
}
.bloc_rest_paiement_ul {
    margin-top: 70px;
}
.bloc_rest_paiement_ul li {
    float: left;
    display: inline-block;
    margin-right: 25px;
    height: 35px;
}
.bloc_rest_paiement_ul p {
    position: relative;
    margin-left: 0;
}
.bloc_rest_paiement_ul strong {
    margin-right: 10px;
}
.mode_paiement_icon {
    display: inline-block;
    width: 36px;
    height: 28px;
    background-image: url(../images/sprite_5.png);
    background-repeat: no-repeat;
    background-position: -419px -668px;
    margin-top: 40px;
    margin-left: 7px;
    float: left;
}
.date_icon {
    display: inline-block;
    width: 36px;
    height: 28px;
    background-image: url(../images/sprite_5.png);
    background-repeat: no-repeat;
    background-position: -456px -668px;
    margin-top: 40px;
    margin-left: 7px;
    float: left;
}
.bloc_rest_paiement_total {
    float: right;
    width: 210px;
}
.total_icon {
    display: inline-block;
    width: 32px;
    height: 30px;
    background-image: url(../images/sprite_5.png);
    background-repeat: no-repeat;
    background-position: -490px -666px;
    margin-top: 8px;
    margin-left: 7px;
    float: left;
}
.bloc_rest_paiement_total h3 {
    font-family: "Open Sans", sans-serif;
    font-weight: 700;
    font-size: 25px;
    color: #494949;
    float: left;
    margin-top: 16px;
    margin-left: 10px;
}
.no_padding {
    padding: 0 !important;
}
.history_paiement tbody td:last-child {
    color: #00beb0;
}
.bloc_paiement_actif {
    height: 270px;
}
.bloc_paiement_actif h2 {
    font-family: "Open Sans", sans-serif;
    font-size: 24px;
    text-transform: uppercase;
    color: #494949;
    text-align: center;
    display: inherit;
    margin-top: 27px;
}
.bloc_paiement_actif h3 {
    font-family: "Open Sans", sans-serif;
    font-size: 15px;
    text-transform: uppercase;
    color: #00beb0;
    text-align: center;
    display: inherit;
    font-weight: 700;
}
.bloc_paiement_actif ul {
    margin-top: 20px;
}
.bloc_paiement_actif li p {
    font-size: 14px;
}
.bloc_paiement_actif li {
    margin-bottom: 10px;
}
.bloc_paiement_actif a {
    display: inherit;
    width: 228px;
    vertical-align: middle;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    margin-top: 20px;
}
.tag_paiement_actif {
    font-size: 12px;
    color: #fff;
    font-weight: 700;
    background-color: #66cd2e;
    border-radius: 3px;
    padding: 5px 12px 5px 12px;
    float: left;
}
.notif_expiration1 {
    background-color: #f7ad1c;
}
.notif_expiration2 {
    background-color: #df303f;
}
.notif_licence_expiration {
    width: 960px;
    vertical-align: middle;
    margin-left: auto;
    margin-right: auto;
    display: inherit;
    padding-top: 14px;
    text-align: center;
}
.notif_licence_expiration p {
    color: #fff !important;
    font-weight: 700;
    text-align: center;
    display: inline-block;
    margin-right: 20px;
}
.notif_licence_expiration a {
    color: #4a4a4a;
    background-color: #fff;
    border-radius: 3px;
    padding: 6px 12px 6px 12px;
    border: 2px solid #fff;
    transition: all ease-in-out 0.2s;
}
.notif_licence_expiration a:hover {
    color: #fff;
    background-color: rgba(255, 255, 255, 0);
    border: 2px solid #fff;
    border-radius: 3px;
    padding: 6px 12px 6px 12px;
    transition: all ease-in-out 0.2s;
}
.bloc_paiement_empty {
    height: 270px;
}
.bloc_paiement_empty p {
    font-size: 14px;
    color: #494949;
    text-align: center;
    display: inherit;
}
.bloc_paiement_empty a {
    font-size: 14px;
    color: #11a0f8;
    text-align: center;
    display: inherit;
    font-weight: 700;
    margin-top: 20px;
    border: 1px dashed #11a0f8;
    padding: 12px 20px 13px 20px;
    border-radius: 3px;
    width: 55%;
    vertical-align: middle;
    margin-left: auto;
    margin-right: auto;
    transition: all ease-in-out 0.2s;
}
.bloc_paiement_empty a:hover {
    font-size: 14px;
    color: #11a0f8;
    text-align: center;
    display: inherit;
    font-weight: 700;
    margin-top: 20px;
    border: 1px dashed #11a0f8;
    padding: 12px 20px 13px 20px;
    border-radius: 3px;
    width: 55%;
    background-color: #ddeff9;
    vertical-align: middle;
    margin-left: auto;
    margin-right: auto;
    transition: all ease-in-out 0.2s;
}
.paiement_empty_icon {
    width: 79px;
    height: 66px;
    background-image: url(../images/sprite_5.png);
    background-repeat: no-repeat;
    background-position: -534px -637px;
    margin-top: 40px;
    margin-left: auto;
    margin-right: auto;
    display: inherit;
    vertical-align: middle;
}
.mode_paiement_bloc {
    height: 174px;
    margin-bottom: 0;
}
.mode_paiement_bloc h2 {
    font-size: 24px;
    color: #494949;
    text-transform: uppercase;
    font-weight: 400;
    text-align: center;
    margin-top: 27px;
}
.mode_paiement_bloc h3 {
    font-size: 15px;
    color: #00beb0;
    text-transform: uppercase;
    font-weight: 700;
    text-align: center;
}
.mode_paiement_bloc a {
    text-align: center;
    display: inherit;
    width: 160px;
    vertical-align: middle;
    margin-left: auto;
    margin-right: auto;
    margin-top: 10px;
}
.mode_paiement_bloc label {
    font-weight: 700;
    font-family: "Open Sans", sans-serif;
    font-size: 12px;
    color: #a4a4a4;
    text-transform: uppercase;
    position: absolute;
    margin-left: 39%;
    margin-top: -11px;
}
.steps {
    float: left;
    padding: 20px;
}
.steps li {
    display: block;
    width: 100%;
    margin-bottom: 20px;
}
.steps li p {
    font-size: 14px;
    font-family: "Open Sans", sans-serif;
    font-weight: 400;
    text-align: left;
    color: #494949;
}
.step_number {
    height: 26px;
    width: 26px;
    border-radius: 50%;
    background-color: #00beb0;
    color: #fff;
    text-align: center;
    padding-top: 4px;
    font-weight: 700;
    float: left;
    display: inline-block;
    margin-right: 10px;
    margin-top: -3px;
}
.step_details {
    float: left;
    margin-left: 36px;
    margin-top: 15px;
    width: 100%;
}
.step_details li {
    width: 100%;
    display: block;
    margin-bottom: 10px;
    font-size: 14px;
}
.steps li a {
    font-size: 14px;
    font-family: "Open Sans", sans-serif;
    font-weight: 400;
    color: #00beb0;
    text-decoration: none;
    transition: all ease-in-out 0.2s;
}
.steps li a:hover {
    font-size: 14px;
    font-family: "Open Sans", sans-serif;
    font-weight: 400;
    color: #00beb0;
    text-decoration: underline;
    transition: all ease-in-out 0.2s;
}
.cb_icon {
    display: inline-block;
    width: 33px;
    height: 23px;
    background-image: url(../images/sprite_5.png);
    background-repeat: no-repeat;
    background-position: -420px -704px;
    margin-top: 8px;
    margin-left: 3px;
    float: left;
}
.visa_icon {
    display: inline-block;
    width: 33px;
    height: 23px;
    background-image: url(../images/sprite_5.png);
    background-repeat: no-repeat;
    background-position: -453px -704px;
    margin-top: 8px;
    margin-left: 3px;
    float: left;
}
.mastercard_icon {
    display: inline-block;
    width: 36px;
    height: 23px;
    background-image: url(../images/sprite_5.png);
    background-repeat: no-repeat;
    background-position: -488px -704px;
    margin-top: 8px;
    margin-left: 3px;
    float: left;
}
.mode_paiement_bloc ul li {
    display: inline-block;
}
.mode_paiement_bloc ul {
    width: 120px;
    vertical-align: middle;
    margin-left: auto;
    margin-right: auto;
    margin-top: 4px;
}
.popup_top_licence {
    height: 184px;
    background-color: #f4ab41;
    border-radius: 3px 3px 0 0;
}
.popup_bottom_licence {
    height: 172px;
    padding-top: 10px;
    background-color: #fff;
    border-radius: 0 0 3px 3px;
}
.popup_top_licence img {
    margin-left: 30%;
    margin-top: 20px;
}
.popup_top_licence .popup_close {
    float: right;
}
.popup_top_licence .popup_close img {
    margin-left: -13px;
    margin-top: -6px;
}
.popup_bottom_licence h3 {
    color: #525252;
    font-size: 15px;
    font-weight: 700;
    text-align: center;
    text-transform: uppercase;
}
.popup_bottom_licence p {
    color: #525252;
    font-size: 12px;
    font-weight: 400;
    text-align: center;
    margin-top: 5px;
    margin-left: 10px;
    margin-right: 10px;
}
.popup_bottom_licence form {
    width: 80%;
    vertical-align: middle;
    margin-right: auto;
    margin-left: auto;
    margin-top: 10px;
}
.popup_bottom_licence label {
    color: #525252;
    font-size: 12px;
    font-weight: 700;
}
.popup_bottom_licence .selectbox {
    margin-top: 8px;
    padding-left: 5px;
}
.popup_bottom_licence .input {
    margin-top: 8px;
}
.popup_bottom_licence .btn_green {
    margin-bottom: 15px;
    margin-top: 5px;
}
.popup_licence {
    position: absolute;
    top: 90px;
    left: 50%;
    margin-left: -186px;
    z-index: 9999;
    height: 358px;
    width: 370px;
    background: #fff;
    -moz-box-shadow: 0 0 6px 2px #8a8a8a;
    -webkit-box-shadow: 0 0 6px 2px #8a8a8a;
    box-shadow: 0 0 6px 2px #8a8a8a;
    -moz-border-radius: 2px;
    -webkit-border-radius: 2px;
    border-radius: 3px;
}
.form_row_licence {
    margin-bottom: 6px;
}
.codebar_input {
    background-image: url(../images/code.png);
    background-repeat: no-repeat;
    background-position-x: 98%;
    background-position-y: 4px;
}
.popup_footer_licence {
    height: 40px;
    max-width: 100%;
    background-color: #f0f0f0;
    margin-top: 25px;
    border-radius: 0 0 3px 3px;
}
.popup_bottom_licence a {
    font-size: 12px;
    font-family: "Open Sans", sans-serif;
    font-weight: 400;
    color: #fff;
    background-color: #00beb0;
    border-radius: 3px;
    padding: 8px 12px 8px 12px;
    width: 154px;
    vertical-align: middle;
    display: inherit;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    margin-top: 15px;
    transition: all ease-in-out 0.2s;
}
.popup_bottom_licence a:hover {
    font-size: 12px;
    font-family: "Open Sans", sans-serif;
    font-weight: 400;
    color: #fff;
    background-color: #05ab9f;
    border-radius: 3px;
    padding: 8px 12px 8px 12px;
    width: 154px;
    vertical-align: middle;
    display: inherit;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    margin-top: 15px;
    transition: all ease-in-out 0.2s;
}
.popup_wrapper .popup_top {
    height: 184px;
    background-color: #00beb0;
    border-radius: 3px 3px 0 0;
}
.popup_wrapper .popup_bottom {
    height: 185px;
    padding-top: 10px;
    background-color: #fff;
    border-radius: 0 0 3px 3px;
}
.popup_wrapper .popup_top img {
    margin-left: 30%;
    margin-top: 20px;
}
.popup_wrapper .popup_top .popup_close {
    float: right;
}
.popup_wrapper .popup_top .popup_close img {
    margin-left: -13px;
    margin-top: -6px;
}
.popup_wrapper .popup_bottom h3 {
    color: #525252;
    font-size: 15px;
    font-weight: 700;
    text-align: center;
    text-transform: uppercase;
}
.popup_wrapper .popup_bottom p {
    color: #525252;
    font-size: 12px;
    font-weight: 400;
    text-align: center;
    margin-top: 5px;
    margin-left: 10px;
    margin-right: 10px;
}
.popup_wrapper .popup_bottom form {
    width: 95%;
    vertical-align: middle;
    margin-right: auto;
    margin-left: auto;
}
.popup_wrapper .popup_bottom label {
    color: #525252;
    font-size: 12px;
    font-weight: 700;
}
.popup_wrapper .popup_bottom .selectbox {
    margin-top: 8px;
    padding-left: 5px;
}
.popup_wrapper .popup_bottom .input {
    margin-top: 8px;
}
.popup_wrapper .popup_bottom .btn_green {
    margin-bottom: 15px;
    margin-top: 5px;
}
.popup_wrapper {
    position: absolute;
    top: 90px;
    left: 50%;
    margin-left: -217px;
    z-index: 9999;
    height: 378px;
    width: 427px;
    background: #fff;
    -moz-box-shadow: 0 0 6px 2px #8a8a8a;
    -webkit-box-shadow: 0 0 6px 2px #8a8a8a;
    box-shadow: 0 0 6px 2px #8a8a8a;
    -moz-border-radius: 2px;
    -webkit-border-radius: 2px;
    border-radius: 3px;
}
.popup_wrapper .popup_footer {
    height: 40px;
    max-width: 100%;
    background-color: #f0f0f0;
    margin-top: 219px;
    border-radius: 0 0 3px 3px;
}
.popup_wrapper .popup_footer a {
    margin-right: 10px;
    margin-top: 7px !important;
    float: right;
}
.popup_wrapper .checkbox_popup {
    float: left;
    margin-top: 10px;
    margin-left: 10px;
    font-weight: 400 !important;
}
.popup_multiprix_top {
    height: 133px !important;
}
.popup_multiprix_top .popup_photo {
    float: left !important;
    margin-left: 10px !important;
    height: 110px;
    width: 110px;
    border-radius: 50%;
    background-color: #fff;
    border: 4px solid #199f94;
    margin-top: 13px !important;
    margin-right: 8px;
    float: left;
    display: inline-block;
}
.popup_multiprix_top h2 {
    font-family: "Open Sans", sans-serif;
    font-size: 15px;
    font-weight: 700;
    color: #fff;
    text-transform: uppercase;
    margin-bottom: 2px;
}
.popup_multiprix_top p {
    font-family: "Open Sans", sans-serif;
    font-size: 15px;
    font-weight: 400;
    color: #fff;
}
.popup_multiprix_top .popup_title {
    float: right;
    width: 69%;
    margin-top: 25px;
}
.radio_option {
    width: 100%;
    height: 63px;
    border-radius: 5px;
    border: 2px solid #efefef;
    background-color: #fbfbfb;
    text-transform: uppercase;
    padding-left: 20px;
    padding-top: 19px;
    float: left;
    margin-bottom: 10px;
}
.popup_wrapper .popup_form {
    width: 95%;
}
.radio_selected {
    border: 2px solid #17baad;
}
.popup_wrapper .radio_option ul {
    width: 100%;
    display: inline-block;
}
.popup_wrapper .radio_option li {
    float: left;
    display: inline-block;
}
.popup_wrapper .radio_option li p {
    font-family: "Open Sans", sans-serif;
    font-size: 15px;
    color: #494949;
    font-weight: 400;
    text-transform: uppercase;
    float: left;
    margin-top: -1px;
}
.popup_wrapper .radio_option li strong {
    font-family: "Open Sans", sans-serif;
    font-size: 15px;
    color: #494949;
    font-weight: 900;
    text-transform: uppercase;
}
.popup_wrapper .radio_option li .tag_new {
    font-family: "Open Sans", sans-serif;
    font-size: 14px;
    color: #fff;
    background-color: #f6ac32;
    font-weight: 400;
    float: left;
    padding: 1px 9px 5px 8px;
    border-radius: 3px;
    text-transform: capitalize;
    margin-top: -2px;
    margin-left: 10px;
}
.popup_wrapper .radio_action {
    float: right !important;
    margin-right: 10px;
}
.popup_wrapper .radio_choice_btn a {
    padding: 0 13px;
    position: absolute;
    right: 81px;
    margin-top: -2px !important;
    text-transform: capitalize;
    display: none;
}
.popup_wrapper .radio:hover .checked {
    border: 2px solid #00beb0;
}
.notifications {
    width: 58px;
    height: 100%;
    background-color: #00a89b;
    float: left;
    display: inline-block;
    margin-right: 20px;
    margin-left: 20px;
}
.notifications span {
    font-family: "Open Sans", sans-serif;
    font-size: 18px;
    font-weight: 700;
    text-align: center;
    display: inherit;
    margin-top: 2px;
    margin-bottom: 0;
    color: #036b63;
}
.notifications p {
    font-family: "Open Sans", sans-serif;
    font-size: 12px;
    font-weight: 400;
    text-align: center;
    display: inherit;
    color: #036b63;
}
.notifications_active {
    background-color: #e61276;
}
.notifications_active span {
    color: #fff;
}
.notifications_active p {
    color: #fff;
}
.notifications_liste tr {
    width: 100%;
    float: left;
    display: inline-block;
    height: auto;
    padding-left: 15px;
    padding-top: 10px;
    padding-bottom: 10px;
    border-bottom: 1px solid #c6c4c4;
}
.notifications_liste tr a {
    width: 100%;
    float: left;
    display: inline-block;
    height: auto;
}
.notifications_content {
    width: 80%;
    float: left;
    display: inline-block;
}
.notifications_time {
    width: 20%;
    float: right;
    display: inline-block;
}
.notifications_content h4 {
    font-family: "Open Sans", sans-serif;
    font-size: 14px;
    color: #707070;
    float: left;
    font-weight: 400;
}
.notifications_content strong {
    color: #00beb0;
    font-weight: 700;
    font-family: "Open Sans", sans-serif;
    font-size: 14px;
}
.notifications_content p {
    font-family: "Open Sans", sans-serif;
    font-size: 12px;
    color: #707070;
    float: left;
    font-weight: 400;
}
.notifications_time p {
    float: right;
    display: inline-block;
    margin-right: 15px;
}
.notifications_time_icon {
    display: inline-block;
    width: 17px;
    height: 17px;
    background-image: url(../images/sprite_5.png);
    background-repeat: no-repeat;
    background-position: -317px -717px;
    margin-top: 0;
    margin-right: 4px;
    float: left;
}
.notification_inactive {
    background-color: #ececec;
}
.liste_notifications {
    height: 620px;
}
.btn_notifications_active {
    background-color: #e61276;
}
.btn_notifications_active:hover {
    background-color: #e30060;
}
.btn_notifications_active span {
    color: #fff;
}
.notifications_wrapper {
    width: 313px;
    height: 400px;
    border-radius: 5px;
    background-color: #fff;
    box-shadow: 0 2px 14px -2px #bcbcbc;
    -webkit-box-shadow: 0 2px 14px -2px #bcbcbc;
    -moz-box-shadow: 0 2px 14px -2px #bcbcbc;
    -o-box-shadow: 0 2px 14px -2px #bcbcbc;
    position: fixed;
    z-index: 9999;
    right: 29px;
    bottom: 0;
    margin-bottom: 90px;
    display: none;
}
.notifications_bloc_header {
    width: 100%;
    height: auto;
    border-radius: 5px 5px 0 0;
    background-color: #e61276;
    padding-top: 15px;
    padding-left: 10px;
    padding-bottom: 20px;
}
.notifications_bloc_header p {
    color: #fff;
}
.notifications_bloc_header h3 {
    font-weight: 700;
    font-family: "Open Sans", sans-serif;
    color: #fff;
    font-size: 14px;
    text-align: left;
}
.notification_li {
    width: 100%;
    height: auto;
    float: left;
    display: inline-block;
    padding-left: 12px;
    padding-top: 10px;
    padding-bottom: 10px;
    border-bottom: 1px solid #c6c4c4;
}
.notification_li h4 {
    font-family: "Open Sans", sans-serif;
    font-size: 14px;
    color: #707070;
    float: left;
    font-weight: 400;
    width: 100%;
}
.notification_li strong {
    font-family: "Open Sans", sans-serif;
    font-size: 14px;
    color: #00beb0;
    font-weight: 700;
}
.notification_li p {
    font-family: "Open Sans", sans-serif;
    font-size: 12px;
    color: #707070;
    font-weight: 400;
    float: left;
    margin-top: 8px;
}
.notification_li a {
    font-family: "Open Sans", sans-serif;
    font-size: 12px;
    color: #707070;
    font-weight: 400;
    float: right;
    margin-top: 3px;
    display: inline-block;
    padding: 5px 12px 5px 12px;
    border-radius: 3px;
    border: 1px solid #c8c8c8;
    margin-right: 10px;
    transition: all ease-in-out 0.2s;
}
.notification_li a:hover {
    font-family: "Open Sans", sans-serif;
    font-size: 12px;
    color: #fff;
    font-weight: 400;
    float: right;
    margin-top: 3px;
    display: inline-block;
    padding: 5px 12px 5px 12px;
    border-radius: 3px;
    border: 1px solid #00beb0;
    margin-right: 10px;
    background-color: #00beb0;
    transition: all ease-in-out 0.2s;
}
.btn_next:hover {
    background-color: #ff9c58;
    transition: all ease-in-out 0.2s;
    color: #fff;
}
.btn_next {
    height: 92px;
    display: inline-block;
    width: 100%;
    text-align: center;
    font: 12px "Open Sans", sans-serif;
    color: #494949;
    font-weight: 700;
}
.btn_next h4 {
    font-family: "Open Sans", sans-serif;
    font-size: 18px;
    font-weight: 700;
    color: #00beb0;
    text-align: center;
    text-transform: uppercase;
    margin-top: 23px;
}
.btn_next:hover h4 {
    color: #fff;
}
.btn_next:hover p {
    color: #fff;
}
.next_icon {
    display: inline-block;
    width: 65px;
    height: 65px;
    background-image: url(../images/sprite_5.png);
    background-repeat: no-repeat;
    background-position: -540px -709px;
    margin-top: -52px;
    margin-right: 18px;
    float: right;
}
.btn_next:hover .next_icon {
    display: inline-block;
    width: 65px;
    height: 65px;
    background-image: url(../images/sprite_5.png);
    background-repeat: no-repeat;
    background-position: -540px -773px;
    margin-top: -52px;
    margin-right: 18px;
    float: right;
}
.grid2_nopadding {
    margin-left: 0 !important;
    margin-right: 0 !important;
}
.grid2_right {
    float: right;
}
.fournisseur_statut {
    height: 64px;
    background-color: #ff7953;
}
.fournisseur_statut p {
    color: #fff;
    font-size: 14px;
    font-family: "Open Sans", sans-serif;
    text-align: left;
    font-weight: 400;
}
.founisseur_paiement_icon {
    display: inline-block;
    width: 63px;
    height: 63px;
    background-image: url(../images/sprite_5.png);
    background-repeat: no-repeat;
    background-position: -91px -449px;
    margin-top: -6px;
    margin-right: 13px;
    float: left;
    margin-left: -5px;
    background-size: 337px;
}
.traceability_statut {
    height: 70px;
    padding-left: 11px;
}
.traceability_statut h3 {
    font-weight: 700;
    font-family: "Open Sans", sans-serif;
    font-size: 13px;
    color: #494949;
    margin-top: 5px;
    margin-bottom: 3px;
    float: left;
    width: 85%;
}
.traceability_statut p {
    float: left;
    width: 85%;
}
.traceability_gris {
    background-color: #f1f1f1;
}
.traceability_gris h3 {
    color: #d1d1d1 !important;
    font-weight: 700;
    font-style: italic;
    margin-top: 5px;
    margin-bottom: 3px;
    float: left;
    width: 80%;
}
.traceability_gris p {
    color: #d1d1d1 !important;
    font-style: italic;
    float: left;
}
.traceability_blue {
    background-color: #29bdf7;
}
.traceability_blue h3,
.traceability_green h3,
.traceability_purple h3,
.traceability_red h3,
.traceability_yellow h3 {
    font-weight: 700;
    font-family: "Open Sans", sans-serif;
    font-size: 13px;
    color: #fff;
    margin-top: 5px;
    margin-bottom: 3px;
    float: left;
    width: 80%;
}
.traceability_blue p,
.traceability_green p,
.traceability_purple p,
.traceability_red p,
.traceability_yellow p {
    color: #fff !important;
    float: left;
}
.traceability_yellow {
    background-color: #ffb300;
}
.traceability_green {
    background-color: #75db7e;
}
.traceability_purple {
    background-color: #5a49d7;
}
.traceability_red {
    background-color: #fc154a;
}
.traceability_number {
    width: 21px;
    height: 21px;
    border-radius: 50px;
    background-color: #00beb0;
    float: right;
    border: 3px solid #fff;
    margin-top: -12px;
    margin-right: 8px;
}
.traceability_blue .traceability_number {
    background-color: #29bdf7;
}
.traceability_yellow .traceability_number {
    background-color: #ffb300;
}
.traceability_green .traceability_number {
    background-color: #75db7e;
}
.traceability_purple .traceability_number {
    background-color: #5a49d7;
}
.traceability_red .traceability_number {
    background-color: #fc154a;
}
.traceability_gris .traceability_number {
    background-color: #e0e0e0;
    border: 3px solid #f1f1f1;
    height: 23px;
    width: 23px;
    margin-right: 6px;
}
.traceability_number span {
    font-family: "Open Sans", sans-serif;
    font-size: 11px;
    color: #fff;
    font-weight: 700;
    text-align: center;
    display: inherit;
    margin-top: -1px;
}
.traceability_gris .traceability_number span {
    color: #a9a9a9 !important;
    margin-top: 0;
}
.traceability_check {
    display: inline-block;
    width: 23px;
    height: 23px;
    background-image: url(../images/sprite_5.png);
    background-repeat: no-repeat;
    background-position: -369px -369px;
    margin-top: 3px;
    margin-right: 13px;
    float: left;
    margin-left: 0;
}
.traceability_process {
    width: 2px;
    height: 65px;
    background-color: #fff;
    position: absolute;
    right: 27px;
    top: 42px;
    z-index: 999;
}
.traceability_process_gris {
    width: 0;
    height: 62px;
    position: absolute;
    right: 27px;
    top: 46px;
    z-index: 999;
    border: 1px dashed #e0e0e0;
}
.scroll_content {
    height: 275px;
    overflow-y: auto;
}
.profil_price_bloc {
    height: 114px;
    background-image: url(../images/money_icone.png);
    background-repeat: no-repeat;
    background-position-x: right;
    background-position-y: 20px;
    background-size: 131px;
}
.profil_price_bloc .total {
    font-size: 30px !important;
}
.profil_price_bloc .colored_content {
    min-height: 60px;
    margin: 0;
}
.bloc_fidelite ul {
    float: left;
    display: inline-block;
    margin-top: 6px;
}
.bloc_fidelite ul li {
    float: left;
    display: inline-block;
    margin-right: 4px;
}
.bloc_fidelite ul li a {
    font-family: "Open Sans", sans-serif;
    font-size: 12px;
    font-weight: 400;
    color: #fff;
    border: 1px solid #fff;
    border-radius: 3px;
    padding: 4px 7px 4px 7px;
    float: left;
}
.bloc_fidelite ul li a:hover {
    font-family: "Open Sans", sans-serif;
    font-size: 12px;
    font-weight: 400;
    color: #57c8f8;
    border: 1px solid #fff;
    background-color: #fff;
    border-radius: 3px;
    padding: 4px 7px 4px 7px;
    float: left;
    transition: all ease-in-out 0.2s;
}
.categories_bloc .stat {
    width: 100%;
    height: 30px;
    border-radius: 3px;
    vertical-align: middle;
    margin-left: auto;
    margin-right: auto;
    display: inherit;
}
.categories_bloc .stat li {
    float: left;
    display: inherit;
    height: 30px;
}
.stat1 {
    background-color: #87c543;
    border-radius: 3px 0 0 3px;
}
.stat2 {
    background-color: #fccc58;
}
.stat3 {
    background-color: #f75f5d;
}
.stat4 {
    background-color: #339ffc;
    border-radius: 0 3px 3px 0;
}
.stat_def {
    float: left;
    display: inherit;
    width: 100%;
}
.stat_def li {
    float: left;
    display: inline-block;
    width: 50%;
    margin-top: 12px;
}
.bull_stat {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    float: left;
    margin-right: 10px;
}
.bull1 {
    background-color: #87c543;
}
.bull2 {
    background-color: #f75f5d;
}
.bull3 {
    background-color: #fccc58;
}
.bull4 {
    background-color: #339ffc;
}
.stat_def p {
    font-family: "Open Sans", sans-serif;
    font-size: 12px;
    color: #494949;
    float: left;
}
.comment_input {
    font-size: 12px;
    font-family: "Open Sans", sans-serif;
    color: #6ab7e8;
    text-align: left;
    font-style: italic;
    margin-top: 5px;
}
.message_help {
    background-color: #5cc9f6;
    height: 50px;
}
.message_help p {
    color: #fff;
    font-size: 14px;
    font-family: "Open Sans", sans-serif;
    margin-top: 3px;
}
.icon_help_message {
    display: inline-block;
    width: 28px;
    height: 28px;
    background-image: url(../images/sprite_5.png);
    background-repeat: no-repeat;
    background-position: 0 -322px;
    margin-top: -3px;
    margin-right: 7px;
    float: left;
    margin-left: 0;
    background-size: 210px;
}
.offre_bloc img {
    float: left;
    display: inline-block;
    width: 100%;
}
.offre_bloc {
    padding: 0;
    padding-bottom: 10px;
}
.offre_bloc h3 {
    font-family: "Open Sans", sans-serif;
    font-size: 14px;
    font-weight: 700;
    color: #565656;
    float: left;
    display: inline-block;
    margin-left: 10px;
    margin-top: 20px;
    width: 94% !important;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.offre_ul {
    margin-top: 27px;
    margin-left: 10px;
    display: inline-block;
    margin-bottom: 27px;
}
.offre_ul li {
    width: 100%;
    display: inline-block;
    margin-bottom: 6px;
}
.offre_ul p {
    color: #828282;
    font-family: "Open Sans", sans-serif;
    font-size: 12px;
}
.offre_ul a {
    color: #1ebdaf;
    font-family: "Open Sans", sans-serif;
    font-size: 12px;
    transition: all ease-in-out 0.2s;
}
.offre_ul a:hover {
    color: #1ebdaf;
    font-family: "Open Sans", sans-serif;
    font-size: 12px;
    text-decoration: underline;
    transition: all ease-in-out 0.2s;
}
.offre_btn {
    font-family: "Open Sans", sans-serif;
    font-weight: 700;
    text-transform: uppercase;
    font-size: 15px;
    background-color: #1ebdaf;
    padding: 8px 30px;
    border-radius: 3px;
    width: 162px;
    vertical-align: middle;
    margin-right: auto;
    margin-left: auto;
    display: inherit;
    color: #fff;
    margin-top: 10px;
    margin-bottom: 10px;
    transition: all ease-in-out 0.2s;
}
.offre_btn:hover {
    font-family: "Open Sans", sans-serif;
    font-weight: 700;
    text-transform: uppercase;
    font-size: 15px;
    background-color: #05ab9f;
    padding: 8px 30px;
    border-radius: 3px;
    width: 162px;
    vertical-align: middle;
    margin-right: auto;
    margin-left: auto;
    display: inherit;
    color: #fff;
    margin-top: 10px;
    margin-bottom: 10px;
    transition: all ease-in-out 0.2s;
}
.icon_offre_labo {
    display: inline-block;
    width: 21px;
    height: 28px;
    background-image: url(../images/sprite_5.png);
    background-repeat: no-repeat;
    background-position: -43px -495px;
    margin-top: -3px;
    margin-right: 10px;
    float: left;
    margin-left: 0;
    background-size: 322px;
}
.icon_offre_products {
    display: inline-block;
    width: 21px;
    height: 28px;
    background-image: url(../images/sprite_5.png);
    background-repeat: no-repeat;
    background-position: -64px -493px;
    margin-top: -3px;
    margin-right: 10px;
    float: left;
    margin-left: 0;
    background-size: 320px;
}
.icon_offre_time {
    display: inline-block;
    width: 21px;
    height: 28px;
    background-image: url(../images/sprite_5.png);
    background-repeat: no-repeat;
    background-position: -76px -438px;
    margin-top: -3px;
    margin-right: 10px;
    float: left;
    margin-left: 0;
    background-size: 285px;
}
.icon_offre_views {
    display: inline-block;
    width: 21px;
    height: 28px;
    background-image: url(../images/sprite_5.png);
    background-repeat: no-repeat;
    background-position: -105px -460px;
    margin-top: -3px;
    margin-right: 10px;
    float: left;
    margin-left: 0;
    background-size: 299px;
}
.offre_bloc span {
    font-size: 13px;
    font-family: "Open Sans", sans-serif;
    color: #fff;
    position: absolute;
    right: 0;
    background-color: #f0396e;
    padding: 9px 10px;
    border-radius: 3px 0 0 5px;
    margin-top: 20px;
}
.offre_expired p {
    color: #f0396e;
    font-weight: 700;
}
.offre_expired .icon_offre_time {
    display: inline-block;
    width: 21px;
    height: 28px;
    background-image: url(../images/sprite_5.png);
    background-repeat: no-repeat;
    background-position: -123px -438px;
    margin-top: -3px;
    margin-right: 10px;
    float: left;
    margin-left: 0;
    background-size: 285px;
}
.msg_offre {
    margin-bottom: 0;
}
.labo_infos {
    width: 99%;
    border-radius: 5px;
    border: 1px solid #d9d9d9;
    padding: 10px;
    display: inherit;
    margin-left: 0.6%;
    margin-right: 0.6%;
    margin-top: 10px;
    margin-bottom: 10px;
    float: left;
}
.labo_photo {
    width: auto;
    height: 103px;
    padding: 10px;
    border-radius: 3px;
    border: 4px solid #fff;
    -moz-box-shadow: 0 1px 3px 0 #bcbcbc;
    -webkit-box-shadow: 0 1px 3px 0 #bcbcbc;
    box-shadow: 0 1px 3px 0 #bcbcbc;
    background-color: #f7f7f7;
    max-width: 202px;
    display: inline-block;
    margin-bottom: 14px;
    float: left;
}
.laboratoire_photo {
    width: auto;
    height: 103px;
    padding: 10px;
    border-radius: 3px;
    border: 4px solid #fff;
    -moz-box-shadow: 0 1px 3px 0 #bcbcbc;
    -webkit-box-shadow: 0 1px 3px 0 #bcbcbc;
    box-shadow: 0 1px 3px 0 #bcbcbc;
    background-color: #f7f7f7;
    max-width: 202px;
    vertical-align: middle;
    margin-left: auto;
    margin-right: auto;
    display: inherit;
    margin-bottom: 14px;
}
.labo_infos h2 {
    font-family: "Open Sans", sans-serif;
    font-weight: 700;
    text-transform: uppercase;
    font-size: 17px;
    color: #6f75a0;
}
.labo_infos h3 {
    font-family: "Open Sans", sans-serif;
    font-weight: 700;
    text-transform: uppercase;
    font-size: 13px;
    color: #6f75a0;
}
.labos_info_photo {
    float: left;
    max-width: 18%;
}
.labo_infos_left {
    width: 44%;
    float: left;
    display: inline-block;
    margin-left: 10px;
}
.labo_infos p {
    font-family: "Open Sans", sans-serif;
    font-size: 12px;
    color: #494949;
    font-weight: 700;
    text-align: left;
    margin-top: 10px;
}
.labo_infos_right {
    width: 36%;
    float: right;
    display: inline-block;
    margin-top: 5px;
}
.labo_infos_right li {
    float: left;
    display: block;
    width: 100%;
    margin-bottom: 10px;
}
.labo_infos_right li strong {
    display: inline-block;
    margin-right: 6px;
    margin-top: 3px;
}
.labo_infos_right li p {
    display: inline-block;
    margin-top: 3px;
}
.icon_offre {
    float: left;
    height: 25px;
    width: 25px;
    border-radius: 50%;
    margin-right: 5px;
}
.c_livraison {
    background-color: #4cc152;
}
.c_paiement {
    background-color: #f4b414;
}
.c_commande {
    background-color: #f2356d;
}
.c_visites {
    background-color: #3291f2;
}
.icon_c_livraison {
    display: inline-block;
    width: 20px;
    height: 15px;
    background-image: url(../images/sprite_5.png);
    background-repeat: no-repeat;
    background-position: -82px -942px;
    position: relative;
    margin-left: 2px;
    margin-top: 3px;
    background-size: 582px;
}
.icon_c_paiement {
    display: inline-block;
    width: 20px;
    height: 15px;
    background-image: url(../images/sprite_5.png);
    background-repeat: no-repeat;
    background-position: -107px -959px;
    position: relative;
    margin-left: 2px;
    margin-top: 3px;
    background-size: 593px;
}
.icon_c_commande {
    display: inline-block;
    width: 20px;
    height: 15px;
    background-image: url(../images/sprite_5.png);
    background-repeat: no-repeat;
    background-position: -127px -932px;
    position: relative;
    margin-left: 4px;
    margin-top: 4px;
    background-size: 576px;
}
.icon_c_views {
    display: inline-block;
    width: 20px;
    height: 15px;
    background-image: url(../images/sprite_5.png);
    background-repeat: no-repeat;
    background-position: -420px -327px;
    position: relative;
    margin-left: 3px;
    margin-top: 4px;
}
.labo_infos_right p {
    font-weight: 400;
}
.no-padding {
    padding: 0 !important;
}
.table_offre {
    border-top: 1px solid #bbb;
    border-bottom: 1px solid #bbb;
}
.gamme_line {
    background-color: #e1e8f1;
}
.gamme_line span {
    color: #646464;
    font-family: "Open Sans", sans-serif;
    font-weight: 700;
    text-transform: uppercase;
    margin-left: 1.7%;
}
.table_offre thead {
    text-transform: uppercase;
}
.table_line {
    height: 70px;
}
.table_line .input {
    width: 65%;
}
.line_selected {
    border: 2px solid #00beb0;
    background-color: #f0fbfa !important;
}
.table tbody .line_selected td:hover {
    color: #494949 !important;
}
.line_product_photo {
    border-radius: 0;
    border: 1px solid #a2a2b4;
    height: 55px;
    width: 55px;
}
.table_offre .table_line td {
    padding: 4px 5px;
}
.btn_view {
    width: 37px;
    height: 37px;
    float: right;
    border-radius: 50%;
    border: 1px solid #00998e;
    background-color: #00beb0;
    margin-right: 10%;
    display: none;
    position: absolute;
}
.table_line p {
    float: left;
    width: 70%;
    display: inline-block;
    margin-top: 2px;
    font-weight: 600;
}
.btn_view_icon {
    display: inline-block;
    width: 26px;
    height: 20px;
    background-image: url(../images/sprite_5.png);
    background-repeat: no-repeat;
    background-position: -80px -958px;
    margin-top: 6px;
    margin-right: 10px;
    float: left;
    margin-left: 3px;
    background-size: 582px;
}
.skillbar {
    position: relative;
    display: block;
    margin-bottom: 15px;
    width: 70%;
    background: #f0f0f0;
    height: 10px;
    border-radius: 3px;
    -moz-border-radius: 3px;
    -webkit-border-radius: 3px;
    -webkit-transition: 0.4s linear;
    -moz-transition: 0.4s linear;
    -ms-transition: 0.4s linear;
    -o-transition: 0.4s linear;
    transition: 0.4s linear;
    -webkit-transition-property: width, background-color;
    -moz-transition-property: width, background-color;
    -ms-transition-property: width, background-color;
    -o-transition-property: width, background-color;
    transition-property: width, background-color;
    margin-top: 24px;
}
.skillbar-title {
    position: absolute;
    top: 0;
    left: 0;
    font-weight: 700;
    font-size: 13px;
    color: #fff;
    background: #6adcfa;
    -webkit-border-top-left-radius: 3px;
    -webkit-border-bottom-left-radius: 4px;
    -moz-border-radius-topleft: 3px;
    -moz-border-radius-bottomleft: 3px;
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
}
.skillbar-title span {
    display: block;
    background: rgba(0, 0, 0, 0.1);
    padding: 0 20px;
    height: 10px;
    line-height: 35px;
    -webkit-border-top-left-radius: 3px;
    -webkit-border-bottom-left-radius: 3px;
    -moz-border-radius-topleft: 3px;
    -moz-border-radius-bottomleft: 3px;
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
}
.skillbar-bar {
    height: 10px;
    width: 0;
    background: #6adcfa;
    border-radius: 3px;
    -moz-border-radius: 3px;
    -webkit-border-radius: 3px;
}
.skill-bar-percent {
    position: absolute;
    top: -25px;
    font-size: 12px;
    color: #4e4e4e;
    float: right;
    font-weight: 700;
    background-color: #f0f0f0;
    border-radius: 3px;
    padding: 2px 10px 4px 10px;
}
.BC_labo {
    float: right;
    margin-left: 440px;
}
.logo_labo {
    height: 86px;
    max-width: 90px;
    border-radius: 5px;
    border: 2px solid #e5e5e5;
    float: left;
    margin-right: 10px;
}
.compte_rendu label {
    font-weight: 600;
}
.row_compte_rendu {
    margin-bottom: 20px;
}
.row_compte_rendu .popup_input_date {
    background-position-x: 99% !important;
    background-position-y: 4px !important;
}
.split_compte_rendu input {
    width: 100%;
    display: inline-block;
}
.form_labels p {
    display: inline-block;
}
.form_labels {
    margin-top: 10px;
}
.remise_btns {
    display: inline-block;
}
.remise_btns a {
    color: #fff;
    font-size: 12px;
    font-weight: 400;
    text-align: center;
    margin-right: 2px;
    margin-left: 2px;
}
.btns_popup_remise li {
    display: inline-block;
}
.btns_popup_remise {
    margin-left: 116px !important;
}
.btns_popup_remise a {
    padding: 0 35px !important;
    margin-right: 5px;
}
.popup_top_remise h2 {
    font-weight: 700;
    font-family: "Open Sans", sans-serif;
    font-size: 14px;
    color: #fff;
    text-transform: uppercase;
    text-align: left;
    margin-left: 10px;
}
.form_remise {
    width: 100%;
}
.form_remise .col {
    width: 30%;
    display: inline-block;
    float: left;
    margin-left: 10px;
    margin-right: 10px;
}
.col label {
    font-weight: 700;
    color: grey;
}
.popup_remise_footer {
    width: 100%;
    height: 52px;
    border-radius: 0 0 3px 3px;
    background-color: #f0f0f0;
    display: inline-block;
    margin-top: 24px;
    margin-bottom: -4px;
}
.popup_remise_footer button {
    float: right;
    margin-top: 13px;
    margin-right: 12px;
}
a.tooltip_remise {
    outline: 0;
}
a.tooltip_remise strong {
    line-height: 30px;
}
a.tooltip_remise:hover {
    text-decoration: none;
}
a.tooltip_remise label {
    text-align: left;
    z-index: 10;
    display: none;
    padding: 8px 16px;
    margin-top: -30px;
    margin-left: 28px;
    width: 260px;
    line-height: 16px;
    padding-top: 4px;
}
a.tooltip_remise:hover label {
    display: inline;
    position: absolute;
    color: #fff;
    border: 1px solid #64ae00;
    background: #75cd00;
    margin-left: -284px;
    margin-top: -36px;
    border-radius: 5px;
    padding-top: 10px;
}
.callout_remise {
    z-index: 20;
    position: absolute;
    top: 68px;
    border: 0;
    left: 258px;
    margin-top: -52px;
}
.remise_icon {
    display: inline-block;
    width: 34px;
    height: 34px;
    background-image: url(../images/sprite_5.png);
    background-repeat: no-repeat;
    background-position: -153px -944px;
    margin-top: -1px;
    margin-right: 10px;
    float: left;
    margin-left: 3px;
    background-size: 582px;
}
.tooltip_input {
    width: 214px;
    height: auto;
    border-radius: 5px;
    background-color: #fef9b7;
    border: 1px solid #c79a4b;
    position: absolute;
    margin-top: -1px;
    padding-left: 10px;
    margin-left: -230px;
    padding-bottom: 5px;
    display: none;
    z-index: 999;
}
.tooltip_input p {
    color: #c79a4b;
    font-family: "Open Sans", sans-serif;
    font-size: 12px;
    font-weight: 700;
    width: 100% !important;
}
.callout_tooltip_input {
    z-index: 20;
    position: absolute;
    top: 89px;
    border: 0;
    right: -9px;
    margin-top: -86px;
}
.tooltip_input li {
    margin-bottom: 4px;
}
.new_line {
    width: 98%;
    vertical-align: middle;
    display: inherit;
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;
    margin-bottom: 20px;
}
.form_remise_row {
    margin-bottom: 8px;
    margin-top: 8px;
    float: left;
}
.labo_info_view {
    height: auto !important;
}
.labo_info_view .labo_infos_left p {
    margin-top: 6px;
}
.count_down {
    width: 100%;
    height: 86px;
    background-color: #f4b414;
    padding-top: 10px;
}
.offre_count_down {
    margin-top: 5px;
}
.offre_count_down li {
    display: inline-block;
    width: 14%;
    margin-right: 10px;
    text-align: center;
}
.offre_count_down li span {
    text-align: center;
    display: inherit;
    font-weight: 700;
    font-family: "Open Sans", sans-serif;
    font-size: 21px;
    color: #fff;
}
.offre_count_down li p {
    text-align: center;
    font-weight: 400;
    font-family: "Open Sans", sans-serif;
    font-size: 12px;
    color: #fff;
    margin-top: -5px;
}
.count_icon {
    display: inline-block;
    width: 63px;
    height: 75px;
    background-repeat: no-repeat;
    background-position: 1px -381px;
    margin-top: -72px;
    margin-right: -4px;
    float: right;
}
.table_line_left {
    float: left;
    display: inline-block;
    width: 100%;
}
.table_line_right {
    height: 40px;
}
.offre_informations {
    height: auto;
    width: 100%;
    padding-bottom: 20px;
    float: left;
    margin-top: 10px;
    margin-left: 6px;
}
.offre_informations_left {
    height: auto;
    width: 50%;
    padding-left: 15px;
    padding-top: 10px;
    display: inline-block;
}
.offre_informations_left h3 {
    font-family: "Open Sans", sans-serif;
    font-weight: 700;
    text-transform: uppercase;
    font-size: 13px;
    color: #6f75a0;
    margin-bottom: 10px;
}
.offre_informations_left p {
    font-family: "Open Sans", sans-serif;
    font-weight: 400;
    font-size: 13px;
    color: #494949;
}
.offre_informations_right {
    width: 45%;
    height: auto;
    border-radius: 3px;
    border: 1px solid #d9d9d9;
    float: right;
    padding: 10px;
    margin-top: 10px;
    margin-right: 10px;
    padding-bottom: 0;
}
.offre_informations_right li p {
    font-family: "Open Sans", sans-serif;
    font-size: 14px;
    font-weight: 700;
    color: #494949;
    float: left;
    margin-left: 8px;
}
.offre_informations_right li {
    display: block;
    width: 100%;
    float: left;
    margin-bottom: 10px;
}
.offre_informations_right li span {
    font-family: "Open Sans", sans-serif;
    font-size: 14px;
    font-weight: 400;
    color: #494949;
    float: right;
    margin-right: 8px;
}
.offre_total {
    background-color: #e1e8f1;
    border-radius: 3px;
    height: 40px;
    padding-top: 8px;
}
.offre_total p {
    font-size: 16px !important;
}
.offre_total span {
    font-size: 16px !important;
    font-weight: 700 !important;
}
.offre_gain p {
    color: #ef396f !important;
}
.offre_gain span {
    color: #ef396f !important;
    font-weight: 700 !important;
}
.table_line:hover {
    background-color: #f0fbfa !important;
}
.table_line:hover td {
    color: #494949 !important;
}
.labo_infos_left li {
    float: left;
    display: block;
    width: 100%;
    margin-bottom: 10px;
}
.labo_infos_left li strong {
    display: inline-block;
    margin-right: 6px;
    margin-top: 3px;
}
.labo_infos_left li p {
    display: inline-block;
    margin-top: 3px;
    font-weight: 400;
}
.table tbody tr:hover td {
    color: #494949 !important;
}
.change_user_bloc {
    height: 300px;
}
.cu_left {
    width: 25%;
    height: 300px;
    float: left;
    display: inline-block;
    background-color: #6186e6;
}
.cu_left img {
    width: 80%;
    vertical-align: middle;
    margin-right: auto;
    margin-left: auto;
    display: inherit;
    margin-top: 50px;
}
.cu_right {
    float: right;
    width: 75%;
}
.cu_right form {
    margin-top: 70px;
    margin-left: 60px;
    float: left;
}
.cu_right h3 {
    font-family: "Open Sans", sans-serif;
    font-size: 16px;
    color: #00beb0;
    font-weight: 700;
    text-transform: uppercase;
    margin-bottom: 10px;
}
.cu_right p {
    font-family: "Open Sans", sans-serif;
    color: #4a4a4a;
    font-size: 14px;
    line-height: auto;
    text-align: left;
}
.cu_right .form_row {
    width: 315px;
    margin-top: 20px;
}
.dash_bloc_tl {
    float: left;
    display: inline-block;
    width: 40%;
    margin-top: 10px;
}
.dash_bloc_tr {
    float: right;
    display: inline-block;
    width: 60%;
    margin-top: 10px;
}
.dash_bloc_tr h2 {
    font-family: "Open Sans", sans-serif;
    font-size: 35px;
    color: #555a79;
    font-weight: 700;
    float: left;
    text-align: left;
    width: 100%;
}
.dash_bloc_tr p {
    font-family: "Open Sans", sans-serif;
    font-size: 15px;
    color: #555a79;
    font-weight: 400;
    float: left;
    text-align: left;
    width: 100%;
}
.central_bloc {
    height: 178px !important;
    min-height: 178px !important;
}
.appels_central_icon {
    display: inline-block;
    width: 76px;
    height: 75px;
    background-image: url(../images/sprite_5.png);
    background-repeat: no-repeat;
    background-position: 1px -1052px;
    margin-top: 14px;
    margin-right: 25px;
    float: right;
}
.mappels_central_icon {
    display: inline-block;
    width: 76px;
    height: 82px;
    background-image: url(../images/sprite_5.png);
    background-repeat: no-repeat;
    background-position: -71px -1052px;
    margin-top: 11px;
    margin-right: 25px;
    float: right;
}
.tratements_central_icon {
    display: inline-block;
    width: 66px;
    height: 82px;
    background-image: url(../images/sprite_5.png);
    background-repeat: no-repeat;
    background-position: -146px -1052px;
    margin-top: 11px;
    margin-right: 25px;
    float: right;
}
.taux_central_icon {
    display: inline-block;
    width: 66px;
    height: 82px;
    background-image: url(../images/sprite_5.png);
    background-repeat: no-repeat;
    background-position: -209px -1052px;
    margin-top: 11px;
    margin-right: 25px;
    float: right;
}
.devis_central_icon {
    display: inline-block;
    width: 83px;
    height: 87px;
    background-image: url(../images/sprite_5.png);
    background-repeat: no-repeat;
    background-position: 1px -1134px;
    margin-top: 16px;
    margin-right: 25px;
    float: right;
}
.bl_central_icon {
    display: inline-block;
    width: 83px;
    height: 87px;
    background-image: url(../images/sprite_5.png);
    background-repeat: no-repeat;
    background-position: -89px -1134px;
    margin-top: 16px;
    margin-right: 25px;
    float: right;
}
.licence_central_icon {
    display: inline-block;
    width: 63px;
    height: 87px;
    background-image: url(../images/sprite_5.png);
    background-repeat: no-repeat;
    background-position: -180px -1134px;
    margin-top: 16px;
    margin-right: 25px;
    float: right;
}
.encaissement_central_icon {
    display: inline-block;
    width: 68px;
    height: 87px;
    background-image: url(../images/sprite_5.png);
    background-repeat: no-repeat;
    background-position: -246px -1134px;
    margin-top: 16px;
    margin-right: 25px;
    float: right;
}
.central_bloc .dash_bloc_top {
    display: inline-block;
    height: 70%;
    width: 100%;
}
.central_bloc .dash_bloc_footer p {
    font-family: "Open Sans", sans-serif;
    font-size: 14px;
    color: #555a79;
    font-weight: 400;
    float: none;
    text-align: center;
    width: 100%;
    margin-top: 10px;
}
.central_bloc .dash_bloc_footer strong {
    font-family: "Open Sans", sans-serif;
    font-size: 14px;
    color: #555a79;
    font-weight: 700;
    float: none;
    text-align: center;
    width: 100%;
    margin-top: 10px;
}
.dbt_all {
    height: 100% !important;
    padding-top: 16px;
}
.dash_bloc_tr strong {
    font-family: "Open Sans", sans-serif;
    font-size: 15px;
    color: #555a79;
    font-weight: 700;
    float: left;
    text-align: left;
    margin-right: 5px;
}
.central_yellow {
    color: #ffae01 !important;
}
.central_green {
    color: #1ebeb0 !important;
}
.central_red {
    color: #fc0551 !important;
}
.dash_bloc_tr ul {
    margin-top: 18px;
}
.dash_bloc_tr li {
    margin-bottom: 8px;
    float: left;
}
.maj_wrapper {
    background-color: #f5f9fc;
    width: 100%;
    height: 100%;
}
.container_maj {
    width: 960px;
    margin-left: auto;
    margin-right: auto;
    display: inherit;
    vertical-align: middle;
}
.container_maj_left {
    width: 50%;
    float: left;
    display: inline-block;
    padding-top: 70px;
}
.container_maj_left img {
    width: 80%;
    vertical-align: middle;
    margin-left: auto;
    margin-right: auto;
    display: inherit;
}
.container_maj_right {
    width: 50%;
    float: right;
    display: inline-block;
    padding-top: 140px;
}
.container_maj_right img {
    width: 200px;
}
.container_maj_right h3 {
    font-family: "Open Sans", sans-serif;
    font-weight: 700;
    color: #555a79;
    font-size: 18px;
    text-transform: uppercase;
    margin-top: 15px;
}
.container_maj_right p {
    font-family: "Open Sans", sans-serif;
    font-weight: 400;
    color: #555a79;
    font-size: 14px;
}
.info_header_btn {
    float: right !important;
    height: 25px;
}
.top_options_btns p {
    float: left;
    font-size: 14px;
    margin-top: 2px;
    margin-right: 5px;
}
.top_options_btns strong {
    font-size: 14px;
    margin-top: 2px;
    font-weight: 700;
}
.phone_header_icon {
    display: inline-block;
    width: 30px;
    height: 31px;
    background-image: url(../images/sprite_5.png);
    background-repeat: no-repeat;
    background-position: -202px -377px;
    margin-top: -3px;
    margin-right: 3px;
    float: left;
    margin-left: 0;
    background-size: 302px;
}
.info_header_btn {
    width: 0;
    padding-right: 9px;
}
.dash_profil {
    min-height: 70px !important;
}
.dash_profil_photo {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    border: 3px solid #f1f1f1;
    background-color: #fbfbfb;
    float: left;
    margin-right: 10px;
}
.dash_profil h4 {
    font-family: "Open Sans", sans-serif;
    color: #1ebeb0;
    font-weight: 700;
    font-size: 14px;
    margin-top: 4px;
}
.dash_profil p {
    font-family: "Open Sans", sans-serif;
    font-weight: 400;
    font-size: 13px;
}
.dash_profil_photo img {
    width: 43px;
    height: 43px;
    border-radius: 50%;
    background-color: #fbfbfb;
    float: left;
}
.edit_profil_icon {
    display: inline-block;
    width: 24px;
    height: 25px;
    background-image: url(../images/sprite_5.png);
    background-repeat: no-repeat;
    background-position: -229px -450px;
    margin-top: -3px;
    margin-right: -1px;
    float: right;
    margin-left: -18px;
    background-size: 334px;
}
.dash_profil:hover a {
    display: inline-block;
}
.dash_profil a {
    position: absolute;
    right: 5px;
    top: 6px;
    display: none;
}
.dash_fidny {
    min-height: 70px !important;
}
.dash_fidny img {
    width: 45px;
    float: left;
    margin-left: 4px;
    margin-right: 11px;
}
.dash_fidny h4 {
    font-family: "Open Sans", sans-serif;
    color: #555a79;
    font-weight: 700;
    font-size: 16px;
    margin-top: 4px;
}
.dash_fidny a {
    font-family: "Open Sans", sans-serif;
    font-weight: 400;
    font-size: 13px;
}
.dash_fidny a:hover {
    font-family: "Open Sans", sans-serif;
    font-weight: 400;
    font-size: 13px;
    color: #1ebeb0;
    text-decoration: underline;
}
.dash_left_sidebar h4 {
    font-family: "Open Sans", sans-serif;
    font-size: 14px;
    font-weight: 700;
    float: left;
}
.dash_left_sidebar a {
    font-family: "Open Sans", sans-serif;
    font-size: 13px;
    font-weight: 400;
    float: right;
}
.dash_left_sidebar a:hover {
    font-family: "Open Sans", sans-serif;
    font-size: 13px;
    font-weight: 400;
    float: right;
    color: #1ebeb0;
    text-decoration: underline;
}
.dash_left_sidebar ul {
    float: left;
    width: 100%;
    margin-top: 20px;
}
.dash_left_sidebar ul li {
    float: left;
    width: 100%;
    margin-bottom: 10px;
}
.dash_left_sidebar ul li a {
    float: left;
}
.dash_left_sidebar ul li a:hover {
    float: left;
    font-weight: 400;
    color: #1ebeb0;
    text-decoration: underline;
}
.vente_dash_icon {
    display: inline-block;
    width: 35px;
    height: 31px;
    background-image: url(../images/sprite_5.png);
    background-repeat: no-repeat;
    background-position: -186px -404px;
    margin-top: -6px;
    margin-right: 3px;
    float: left;
    margin-left: 0;
    background-size: 283px;
}
.market-place-icon {
    display: inline-block;
    width: 35px;
    height: 31px;
    background-image: url(../images/sprite_5.png);
    background-repeat: no-repeat;
    background-position: -186px -557px;
    margin-top: -6px;
    margin-right: 3px;
    float: left;
    margin-left: 0;
    background-size: 283px;
}
.bl_dash_icon {
    display: inline-block;
    width: 35px;
    height: 31px;
    background-image: url(../images/sprite_5.png);
    background-repeat: no-repeat;
    background-position: -186px -435px;
    margin-top: -6px;
    margin-right: 3px;
    float: left;
    margin-left: 0;
    background-size: 283px;
}
.commande_dash_icon {
    display: inline-block;
    width: 35px;
    height: 31px;
    background-image: url(../images/sprite_5.png);
    background-repeat: no-repeat;
    background-position: -186px -467px;
    margin-top: -6px;
    margin-right: 3px;
    float: left;
    margin-left: 0;
    background-size: 283px;
}
.banner {
    background-color: #f0f0f0;
    min-height: 300px;
}
.banner h3 {
    font-family: "Open Sans", sans-serif;
    font-size: 18px;
    color: #8a8a8a;
    text-align: center;
    margin-top: 120px;
    display: inherit;
    font-weight: 700;
}
.event_dash_sidebar {
    min-height: 250px !important;
}
.event_dash_sidebar ul {
    width: 100%;
    margin-top: 20px;
}
.event_dash_sidebar ul li {
    width: 100%;
    margin-bottom: 12px;
    height: 65px;
}
.event_dash_sidebar .event_date {
    width: 60px;
    height: 60px;
    border-radius: 5px;
    background-color: #f0f0f0;
    float: left;
}
.event_date_top {
    height: 20px;
    width: 100%;
    background-color: #f16566;
    border-radius: 5px 5px 0 0;
}
.event_date_top span {
    font-family: "Open Sans", sans-serif;
    font-size: 13px;
    color: #fff;
    text-align: center;
    display: inherit;
    padding-top: 1px;
    font-weight: 700;
}
.event_date_bottom h4 {
    font-family: "Open Sans", sans-serif;
    font-size: 18px;
    font-weight: 700;
    text-align: center;
    display: inherit;
    margin-top: 5px;
}
.event_date {
    margin-right: 10px;
}
.event_dash_sidebar li h3 {
    font-family: "Open Sans", sans-serif;
    font-size: 14px;
    font-weight: 700;
}
.place_icon {
    display: inline-block;
    width: 17px;
    height: 27px;
    background-image: url(../images/sprite_5.png);
    background-repeat: no-repeat;
    background-position: -245px -462px;
    margin-top: -5px;
    margin-right: 0;
    float: left;
    margin-left: 0;
    background-size: 307px;
}
.event_dash_sidebar li p {
    font-family: "Open Sans", sans-serif;
    font-size: 13px;
    margin-top: 7px;
}
.dash_bloc_tag {
    height: 28px;
    position: absolute;
    right: 0;
    top: 12px;
    border-radius: 4px 0 0 4px;
}
.dash_bloc_tag span {
    font-family: "Open Sans", sans-serif;
    font-size: 13px;
    text-align: center;
    display: inline-block;
    padding-left: 14px;
    padding-right: 14px;
    color: #fff;
    margin-top: 4px;
}
.tag_event {
    background-color: #f16566;
}
.dash_event_bloc .event_date {
    width: 70px;
    height: 70px;
    border-radius: 5px;
    background-color: #f0f0f0;
    float: left;
    margin-top: 10px;
}
.dash_event_bloc .event_date_top {
    height: 24px;
    width: 100%;
    background-color: #f16566;
    border-radius: 5px 5px 0 0;
}
.dash_event_bloc .event_date_top span {
    font-family: "Open Sans", sans-serif;
    font-size: 13px;
    color: #fff;
    text-align: center;
    display: inherit;
    padding-top: 1px;
    font-weight: 700;
}
.dash_event_bloc .event_date_bottom h4 {
    font-family: "Open Sans", sans-serif;
    font-size: 21px;
    font-weight: 700;
    text-align: center;
    display: inherit;
    margin-top: 5px;
}
.dash_event_bloc h4 {
    font-weight: 700;
    font-family: "Open Sans", sans-serif;
    font-size: 14px;
    margin-top: 10px;
}
.event_infos {
    display: inline-block;
}
.event_infos li {
    height: 20px;
}
.date_icon {
    display: inline-block;
    width: 22px;
    height: 23px;
    background-image: url(../images/sprite_5.png);
    background-repeat: no-repeat;
    background-position: -240px -416px;
    margin-top: 4px;
    margin-right: 5px;
    float: left;
    margin-left: 0;
    background-size: 307px;
}
.event_infos li p {
    margin-top: 4px;
}
.event_infos .place_icon {
    display: inline-block;
    width: 17px;
    height: 27px;
    background-image: url(../images/sprite_5.png);
    background-repeat: no-repeat;
    background-position: -245px -462px;
    margin-top: 0;
    margin-right: 2px;
    float: left;
    margin-left: 14px;
    background-size: 307px;
}
.people_icon {
    display: inline-block;
    width: 22px;
    height: 23px;
    background-image: url(../images/sprite_5.png);
    background-repeat: no-repeat;
    background-position: -240px -441px;
    margin-top: 4px;
    margin-right: 5px;
    float: left;
    margin-left: 15px;
    background-size: 307px;
}
.dash_event_footer {
    float: left;
    width: 90%;
    margin-top: -30px;
}
.dash_event_footer p {
    margin-left: 80px;
    float: left;
}
.dash_event_yes {
    border-radius: 3px 0 0 3px;
    margin-right: 0;
    margin-left: 30px;
    margin-top: 8px;
}
.dash_event_yes:hover {
    border-radius: 3px 0 0 3px;
    margin-right: 0;
    margin-left: 30px;
    margin-top: 8px;
    background-color: #1ebeb0;
    color: #fff;
    border: 1px solid #1ebeb0;
}
.dash_event_maybe {
    border-radius: 0;
    margin-right: 0;
    margin-left: -3.8px;
    margin-top: 8px;
}
.dash_event_maybe:hover {
    border-radius: 0;
    margin-right: 0;
    margin-left: -3.8px;
    margin-top: 8px;
    background-color: #f4ab41;
    color: #fff;
    border: 1px solid #f4ab41;
}
.dash_event_no {
    border-radius: 0 3px 3px 0;
    margin-right: 0;
    margin-left: -3.8px;
    margin-top: 8px;
}
.dash_event_no:hover {
    border-radius: 0 3px 3px 0;
    margin-right: 0;
    margin-left: -3.8px;
    margin-top: 8px;
    background-color: #ef6669;
    color: #fff;
    border: 1px solid #ef6669;
}
.tag_stats {
    background-color: #f2ab3e;
}
.dash_stats_bloc ul {
    border-radius: 5px;
    display: inline-block;
    margin-top: 10px;
    width: 100%;
    vertical-align: middle;
    display: inherit;
    margin-left: auto;
    margin-right: auto;
    height: 130px;
    background-color: #fcfcfc;
    border-radius: 5px;
    border: 1px solid #e5e5e5;
    float: left;
}
.dash_stats_bloc ul li {
    width: 33%;
    display: inline-block;
    float: left;
    border-left: 1px solid #e5e5e5;
    height: 100%;
}
.dash_stats_bloc ul li:first-child {
    width: 33%;
    display: inline-block;
    float: left;
    border-left: none;
    height: 100%;
}
.dash_stats_bloc li h3 {
    font-family: "Open Sans", sans-serif;
    font-weight: 700;
    font-size: 25px;
    color: #555a79;
    text-align: center;
    margin-top: 34px;
}
.dash_stats_bloc li p {
    font-family: "Open Sans", sans-serif;
    font-weight: 400;
    font-size: 14px;
    text-align: center;
    display: inherit;
    float: none;
    margin-top: 0;
}
.stat_li {
    width: 100%;
    margin-bottom: 0 !important;
    float: left;
}
.dash_stats_bloc_footer {
    float: left;
    margin-top: 10px;
}
.security_stats_icon {
    display: inline-block;
    width: 35px;
    height: 36px;
    background-image: url(../images/sprite_5.png);
    background-repeat: no-repeat;
    background-position: -318px -653px;
    margin-top: 2px;
    margin-right: 5px;
    float: left;
    margin-left: 0;
    background-size: 407px;
}
.tag_maj {
    background-color: #7c55b2;
}
.tag_notice {
    background-color: #64b8d0;
}
a.tooltip_horaires 2 {
    outline: 0;
}
a.tooltip_horaires2 strong {
    line-height: 30px;
}
a.tooltip_horaires2:hover {
    text-decoration: none;
}
a.tooltip_horaires2 label {
    text-align: left;
    z-index: 10;
    display: none;
    padding: 8px 16px;
    margin-top: -30px;
    margin-left: 28px;
    width: 260px;
    line-height: 16px;
    padding-top: 4px;
}
a.tooltip_horaires2:hover label {
    display: inline;
    position: absolute;
    color: #111;
    border: 1px solid #cecece;
    background: #fff;
    margin-left: -252px;
    margin-top: 38px;
    padding: 0 !important;
}
.callout {
    z-index: 20;
    position: absolute;
    top: 104px;
    border: 0;
    left: 31px;
}
a.tooltip_horaires2 label {
    border-radius: 5px;
    box-shadow: 3px 4px 19px -6px rgba(0, 0, 0, 0.75);
}
.dropdown_header {
    width: 100%;
    height: 30px;
    background-color: #f5f5f5;
    margin-left: 0 !important;
    border-radius: 5px 5px 0 0;
    border-bottom: 1px solid #cecece;
}
.dropdown_header h4 {
    font-family: "Open Sans", sans-serif;
    font-size: 14px;
    font-weight: 700;
    text-align: left;
    margin-top: 5px;
    margin-left: 12px;
}
.info_header_btn label p {
    padding-left: 12px;
    line-height: 20px;
}
.dropdown_footer {
    width: 100%;
    height: 30px;
    background-color: #fff;
    margin-left: 0 !important;
    border-radius: 5px 5px 0 0;
    border-top: 1px solid #cecece;
    float: left;
}
.dropdown_footer p {
    font-family: "Open Sans", sans-serif;
    font-size: 14px;
    font-weight: 400;
    text-align: left;
    margin-top: 5px;
    margin-left: -14px;
}
.dropdown_dash_msg_icon {
    display: inline-block;
    width: 25px;
    height: 23px;
    background-image: url(../images/sprite_5.png);
    background-repeat: no-repeat;
    background-position: -240px -519px;
    margin-top: 4px;
    margin-right: 5px;
    float: left;
    margin-left: 10px;
    background-size: 307px;
}
.tooltip_horaires2 .callout {
    z-index: 20;
    position: absolute;
    top: -13px;
    border: 0;
    left: 231px;
    width: 19px;
}
.dash_products {
    width: 100%;
    margin-top: 10px;
    margin-bottom: 10px;
    float: left;
}
.products_dash_icon {
    display: inline-block;
    width: 35px;
    height: 31px;
    background-image: url(../images/sprite_5.png);
    background-repeat: no-repeat;
    background-position: -186px -499px;
    margin-top: -6px;
    margin-right: 3px;
    float: left;
    margin-left: 0;
    background-size: 283px;
}
.dash_products p {
    width: 75%;
    float: left;
}
.dash_numb {
    background-color: #d5d5d5;
    color: #858585;
    font-weight: 700;
    padding: 4px 12px 4px 12px;
    border-radius: 50px 50px 50px 50px;
    float: right;
}
.vente_success {
    height: 77px;
}
.vente_success h3 {
    font-size: 25px;
    font-weight: 700;
    text-align: center;
    display: inherit;
    color: #1ebeb0;
    padding-top: 6px;
}
.vente_success_icon {
    display: inline-block;
    width: 55px;
    height: 51px;
    background-image: url(../images/sprite_5.png);
    background-repeat: no-repeat;
    background-position: 4px -559px;
    margin-top: 5px;
    margin-right: 3px;
    margin-bottom: -18px;
    margin-left: 0;
    background-size: 283px;
}
.succes_h4 {
    font-size: 14px;
    font-weight: 700;
}
.other_products_icon {
    display: inline-block;
    width: 35px;
    height: 31px;
    background-image: url(../images/sprite_5.png);
    background-repeat: no-repeat;
    background-position: -407px -907px;
    margin-top: -6px;
    margin-right: 3px;
    float: left;
    margin-left: 0;
    background-size: 513px;
}
.other_products {
    float: left;
    margin-top: 15px;
    width: 100%;
}
.other_products li {
    width: 49%;
    float: left;
    height: 128px;
}
.other_products li:nth-child(odd) {
    width: 49%;
    float: left;
    height: 128px;
    margin-right: 13px;
}
.other_product_photo {
    float: left;
    width: 40%;
}
.other_product_photo img {
    width: 80%;
    vertical-align: middle;
    margin-left: auto;
    margin-right: auto;
    display: inherit;
    margin-top: 10px;
}
.other_product_detail {
    float: right;
    width: 60%;
    height: 128px;
    background-color: #f8f8f8;
    border-left: 1px solid #e4e4e4;
}
.other_product_detail a {
    font-weight: 700;
    width: 100%;
    margin-top: 5px;
    margin-left: 5px;
    float: left;
    transition: all ease-in-out 0.2s;
}
.other_product_detail a:hover {
    font-weight: 700;
    width: 100%;
    margin-top: 5px;
    margin-left: 5px;
    float: left;
    color: #1ebeb0;
    transition: all ease-in-out 0.2s;
}
.reduction_tag {
    background-color: #1ebeb0;
    border-radius: 4px;
    padding: 2px 5px 2px 5px;
    color: #fff;
    font-weight: 700;
    font-size: 12px;
    margin-left: 5px;
    margin-top: 5px;
    float: left;
    display: block;
    margin-right: 130px;
    margin-bottom: 6px;
}
.other_product_detail .old_price {
    float: left;
    text-decoration: line-through;
    margin-left: 5px;
    margin-right: 15px;
}
.other_product_detail .price {
    font-weight: 700;
}
.other_product_detail .btn_white {
    width: 90px;
    padding: 0 5px;
    font-weight: 400;
}
.other_product_detail .btn_bleu {
    width: 90px;
    padding: 0 5px;
}
.other_product_detail .btn_white:hover {
    width: 90px;
    padding: 0 5px;
    font-weight: 400;
    color: #494949;
}
.other_product_detail .btn_bleu:hover {
    width: 90px;
    padding: 0 5px;
    color: #fff;
}
.douchette_v2 {
    width: 35% !important;
    float: left !important;
    margin-right: 5px;
}
.select_v2 {
    float: left !important;
    width: 22% !important;
}
.v2_search_star {
    float: right;
    width: 5%;
    height: 25px;
}
.search_star_icon {
    display: inline-block;
    width: 32px;
    height: 31px;
    background-image: url(../images/sprite_5.png);
    background-repeat: no-repeat;
    background-position: -252px -595px;
    margin-top: -2px;
    margin-right: -7px;
    float: right;
    margin-left: 0;
    background-size: 323px;
}
.select_filter_v2 {
    width: 100% !important;
}
.filter_v2 {
    display: none;
    border-bottom: 1px dashed #bababa;
}
.filter_v2 td {
    width: 25%;
}
.filter_v2 td:first-child {
    width: 21% !important;
}
.filter_v2_btn1 {
    width: 30% !important;
}
.filter_v2_btn2 {
    width: 30% !important;
}
.v2_products {
    width: 100%;
    float: left;
}
.v2_products li {
    width: 150px;
    height: 200px;
    float: left;
    margin-right: 7px;
    margin-left: 7px;
    margin-bottom: 15px;
}
.v2_products li .v2_products_a {
    width: 100%;
    height: 200px;
    border: 1px solid #e4e4e4;
    border-radius: 5px;
    float: left;
    cursor: pointer;
}
.v2_products li a:hover .product_detail h4 {
    color: #1ebeb0;
    transition: all ease-in-out 0.2s;
}
.v2_products .photo {
    height: 112px;
    border-bottom: 1px solid #e4e4e4;
}
.v2_products .photo img {
    height: 110px;
    vertical-align: middle;
    margin-right: auto;
    margin-left: auto;
}
.product_detail {
    background-color: #f8f8f8;
    float: left;
    width: 100%;
    height: 86px;
}
.product_detail h4 {
    font-weight: 700;
    font-size: 12px;
    text-align: left;
    padding-left: 4px;
    padding-right: 4px;
    transition: all ease-in-out 0.2s;
}
.v2_products_bottom {
    width: 100%;
    float: left;
    padding-left: 4px;
    padding-right: 4px;
    margin-top: 5px;
}
.v2_products_bottom li {
    float: right;
    width: 60%;
    display: inline-block;
    border: none;
    height: auto;
    margin: 0;
}
.v2_products_bottom li:first-child {
    float: left;
    width: 35%;
    display: inline-block;
    border: none;
    height: auto;
}
.v2_products_bottom li p {
    font-size: 12px;
    color: #494949 !important;
    float: left;
    text-align: left;
    font-weight: 400;
    margin-top: 3px;
}
.tag_price {
    padding: 3px 8px 3px 8px;
    border-radius: 3px;
    background-color: #eaeaea;
    color: #494949;
    display: inline-block;
    float: right;
}
.v2_products_result {
    padding-top: 20px;
    padding-left: 5px;
    padding-right: 5px;
}
.v2_products_pagination {
    width: 100%;
    height: 40px;
    position: absolute;
    left: 0;
    bottom: 0;
}
.v2_products_pagination .checkbox {
    float: left;
    margin-left: 12px;
}
.v2_products_pagination .checked {
    width: 20px;
    height: 20px;
    margin: 0;
    padding: 0;
}
.v2_products_pagination .table_pagination {
    float: right;
    height: 50px;
    margin-top: -50px;
    margin-right: 10px;
}
.v2_products_pagination .table_pagination span {
    height: 24px;
    line-height: 24px;
    border: 1px solid #f0f0f0;
    display: inline-block;
    padding: 0 10px;
    -moz-border-radius: 18px;
    -webkit-border-radius: 18px;
    border-radius: 18px;
    vertical-align: middle;
    margin-top: 46px;
}
.v2_filter_bloc {
    width: 29%;
    float: left;
}
.v2_filter_bloc:first-child {
    width: 42%;
    float: left;
}
.v2_filter_bloc .input_date {
    float: left;
    display: inline-block;
    width: 60%;
}
.v2_filter_bloc .selectbox {
    float: left;
    display: inline-block;
    width: 60%;
}
.v2_filter_bloc .input_search {
    float: left;
    display: inline-block;
    width: 60%;
}
.v2_filter_bloc label {
    float: left;
    display: inline-block;
    margin-right: 10px;
}
.client_icon {
    display: inline-block;
    width: 32px;
    height: 31px;
    background-image: url(../images/sprite_5.png);
    background-repeat: no-repeat;
    background-position: -237px -660px;
    margin-top: -6px;
    margin-right: 5px;
    float: left;
    margin-left: 0;
    background-size: 352px;
}
.select_vente_type {
    float: left;
    width: 150px;
    margin-left: 20px;
    margin-top: 7px;
}
.v2_products .sprite-table-edit-btn {
    position: absolute;
    top: 25px;
    margin-left: 50px;
    float: right;
}
.sondage_ul {
    width: 100%;
    margin-top: 14px;
    float: left;
}
.sondage_ul li {
    width: 48%;
    height: 38px;
    border: 1px solid #ededed;
    margin-right: 5px;
    margin-left: 5px;
    margin-bottom: 10px;
    border-radius: 5px;
}
.sondage_ul li a {
    width: 100%;
    height: 100%;
    font-family: "Open Sans", sans-serif;
    font-size: 14.5px;
    color: #555a79;
    text-align: center;
    display: inherit;
    background-color: #fcfcfc;
    padding-top: 7px;
    border-radius: 5px;
    transition: all ease-in-out 0.2s;
}
.sondage_ul li a:hover {
    width: 100%;
    height: 100%;
    font-family: "Open Sans", sans-serif;
    font-size: 14.5px;
    color: #555a79;
    text-align: center;
    display: inherit;
    background-color: #eef0f2;
    padding-top: 7px;
    border-radius: 5px;
    transition: all ease-in-out 0.2s;
}
.people_sondage_icon {
    margin-top: 10px;
}
.tag_sondage {
    background-color: #39a9ff;
}
.sondage_resultats_ul {
    background-color: #fcfcfc;
    border: 1px solid #e5e5e5;
    float: left;
    width: 100%;
    border-radius: 5px;
    margin-top: 14px;
}
.sondage_resultats_ul li {
    width: 100%;
    float: left;
    height: auto;
    min-height: 40px;
    margin-bottom: 0;
}
.sondage_r_left {
    width: 45%;
    float: left;
}
.sondage_r_center {
    width: 10%;
    float: left;
}
.sondage_r_right {
    width: 45%;
    float: right;
    height: 100%;
}
.sondage_bar {
    width: 90%;
    vertical-align: middle;
    margin-left: auto;
    margin-right: auto;
    margin-top: 15px;
    background-color: #e5e5e5;
    height: 14px;
}
.sondage_r_left p {
    margin-left: 10px;
    color: #555a79;
    text-align: right;
    float: right;
}
.sondage_r_center span {
    text-align: center;
    font-size: 14px;
    color: #555a79;
    font-weight: 700;
    display: inherit;
    margin-top: 10px;
}
.sondage_percent {
    background-color: #1ebeb0;
    height: 14px;
    float: left;
    width: 30%;
}
.tag_anniversaire {
    background-color: #ed0287;
}
.anniversaire_bloc {
    background-image: url(../images/anniversaire_bg.png);
    width: 100%;
    height: 260px;
    background-size: 669px;
    background-repeat: no-repeat;
    float: left;
    background-position-x: 0;
    background-position-y: -38px;
}
.anniversaire_bloc h3 {
    color: #1ebeb0;
    font-family: "Open Sans", sans-serif;
    font-size: 16px;
    text-transform: uppercase;
    text-align: center;
    display: inherit;
    font-weight: 700;
    margin-top: 170px;
}
.tag_information {
    background-color: #1ebeb0;
}
.dashbord_bloc_image_left {
    float: left;
    width: 32% !important;
    display: inline-block;
}
.bloc_information h3 {
    color: #1ebeb0;
    font-family: "Open Sans", sans-serif;
    font-size: 16px;
    text-transform: uppercase;
    text-align: center;
    display: inherit;
    font-weight: 700;
    margin-top: 20px;
    float: left;
    margin-left: 12px;
}
.bloc_information p {
    margin-left: 10px;
    color: #555a79;
    text-align: left;
    float: left;
    display: inherit;
    width: 60%;
}
.information_bloc_detail {
    width: 100%;
}
.information_bloc_detail li {
    width: 68%;
    margin-bottom: 0;
}
.bloc_information .btn_bleu {
    float: left;
    margin-left: 10px;
    margin-top: 15px;
}
.bloc_pp img {
    margin-bottom: 40px;
    margin-top: -30px;
}
.bloc_pp h3 {
    margin-top: -30px;
}
.tag_offre {
    background-color: #ed5f00;
}
.bloc_offre_left {
    float: left;
    width: 30%;
    height: 100%;
}
.bloc_offre_right {
    float: right;
    width: 70%;
    border-left: 1px solid #e5e5e5;
}
.bloc_offre_left img {
    width: 80%;
    vertical-align: middle;
    margin-left: auto;
    margin-right: auto;
    margin-top: 70px;
}
.bloc_offre_right h3 {
    font-family: "Open Sans", sans-serif;
    font-size: 14px;
    font-weight: 700;
    float: left;
    margin-left: 10px;
    margin-top: 35px;
}
.offre_ul li p {
    margin-top: 0;
}
.bloc_offre_right .btn_bleu {
    margin-left: 12px;
}
.dash_offre_bloc {
    height: 200px !important;
    min-height: 270px !important;
}
.bloc_sponsor {
    width: 100%;
    border-radius: 5px;
    background-color: #fcfcfc;
    border: 1px solid #e5e5e5;
    float: left;
}
.bloc_sponsor_img {
    width: 80px;
    height: 80px;
    border-radius: 5px;
    border: 1px solid #e5e5e5;
    float: left;
    display: inline-block;
    margin-left: 10px;
    margin-top: 10px;
    margin-bottom: 10px;
    margin-right: 10px;
    transition: all ease-in-out 0.2s;
}
.bloc_sponsor_img img {
    width: 80%;
    vertical-align: middle;
    margin-left: auto;
    margin-right: auto;
}
.bloc_sponsor h4 {
    font-family: "Open Sans", sans-serif;
    font-size: 14px;
    font-weight: 700;
    color: #828282;
    margin-top: 25px;
}
.bloc_sponsor .btn_bleu {
    float: right;
    margin-right: 10px;
}
.tag_sponsor {
    background-color: #92bdc8;
}
.bloc_link_left {
    float: left;
    width: 70%;
}
.bloc_link_left h4 {
    font-family: "Open Sans", sans-serif;
    font-weight: lighter;
    text-transform: uppercase;
    margin-top: 10px;
    font-size: 14px;
    margin-top: 25px;
    margin-left: 10px;
}
.bloc_link_left h3 {
    font-family: "Open Sans", sans-serif;
    font-weight: 700;
    margin-top: 0;
    font-size: 14px;
    margin-left: 10px;
}
.bloc_link_left p {
    margin-left: 10px;
    margin-top: 0;
}
.bloc_link {
    width: 100%;
    border-radius: 0 0 5px 5px;
    background-color: #fcfcfc;
    border: 1px solid #e5e5e5;
    float: left;
    margin-top: -20px;
    height: 112px;
}
.dash_bloc_link img {
    border-radius: 5px 5px 0 0;
}
.bloc_link .btn_bleu {
    float: right;
    margin-right: 10px;
    margin-top: 50px;
}
.information_bloc_detail li strong {
    font-size: 14px;
}
.information_bloc_detail li p {
    width: 100%;
}
.dashbord_bloc_image_left {
    margin-bottom: 30px;
}
.photo_sponsorise_bloc:hover {
    opacity: 0.8;
    transition: all ease-in-out 0.2s;
}
.photo_sponsorise_bloc {
    transition: all ease-in-out 0.2s;
}
.bloc_sponsor_img:hover {
    transition: all ease-in-out 0.2s;
    border: 1px solid #1ebeb0;
}
.bloc_link:hover h4 {
    color: #1ebeb0;
    transition: all ease-in-out 0.2s;
}
.bloc_link:hover h3 {
    color: #1ebeb0;
    transition: all ease-in-out 0.2s;
}
.popup_help {
    position: absolute;
    top: 90px;
    left: 46%;
    margin-left: -296px;
    z-index: 9999;
    height: 446px;
    width: 729px;
    background: #fff;
    -moz-box-shadow: 0 0 6px 2px #8a8a8a;
    -webkit-box-shadow: 0 0 6px 2px #8a8a8a;
    box-shadow: 0 0 6px 2px #8a8a8a;
    -moz-border-radius: 2px;
    -webkit-border-radius: 2px;
    border-radius: 3px;
}
.popup_top_help {
    height: 48px;
    background-color: #c45cd2;
    border-radius: 3px 3px 0 0;
}
ol {
    display: block;
    list-style-type: decimal;
    margin-top: 1em;
    margin-bottom: 1em;
    margin-left: 0;
    margin-right: 0;
    padding-left: 20px;
}
.popup_help ol li {
    font-size: 14px;
    margin-bottom: 5px;
}
.popup_bottom_help {
    height: auto;
    padding-top: 20px;
    background-color: #fff;
    border-radius: 0 0 3px 3px;
}
.popup_top_help h3 {
    font-family: "Open Sans", sans-serif;
    font-size: 16px;
    font-weight: 700;
    color: #fff;
    text-align: left;
    float: left;
    display: inline-block;
    margin-left: 14px;
    margin-top: 10px;
}
.popup_help ul li {
    font-size: 18px;
    margin-bottom: 5px;
    padding-left: 1em;
    text-indent: -1.2em;
    color: #39c6b9;
}
.popup_help ul li p {
    color: #494949;
}
.popup_bottom_help ul {
    list-style-type: square;
    list-style-position: outside;
    list-style-image: none;
    color: #000;
    padding: 0;
    margin: 0;
    margin-left: 20px !important;
}
.popup_help p {
    font-size: 14px;
}
.popup_help strong {
    font-size: 14px;
}
.popup_bottom_help {
    padding: 10px;
}
.popup_bottom_help .btn_red {
    display: inherit;
    margin-right: auto;
    margin-left: auto;
    width: 210px;
    margin-top: 40px;
    margin-bottom: 15px;
}
.popup_help .back_btn_help {
    margin-top: 10px;
}
.popup_help_contact p {
    text-align: center;
    margin-top: 30px;
}
.popup_help_contact .popup_bottom_help h3 {
    font-family: "Open Sans", sans-serif;
    font-size: 24px;
    color: #1ebbaf;
    font-weight: 700;
    text-align: center;
    display: inherit;
}
.popup_help_contact .popup_bottom_help img {
    float: none;
    display: inherit;
    vertical-align: middle;
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;
}
.content {
    margin-left: 23px;
}
.bloc_info_modification {
    width: 99%;
    height: 50px;
    background-color: #fbf6de;
    border: 1px solid #9a7641;
    border-radius: 3px;
    margin-bottom: 10px;
    margin-left: 8px;
}
.bloc_info_modification p {
    font-size: 12px;
    font-family: "Open Sans", sans-serif;
    font-weight: 400;
    color: #826132;
    text-align: center;
    margin-top: 13px;
    display: inherit;
    width: 738px;
    vertical-align: middle;
    margin-left: auto;
    margin-right: auto;
}
.bloc_info_modification .info_icon {
    display: inline-block;
    width: 34px;
    height: 34px;
    background-image: url(../images/sprite_2.png);
    background-repeat: no-repeat;
    background-position: -395px -632px;
    margin-top: -5px;
    margin-right: 6px;
    float: left;
}
.bloc_info_modification a {
    font-size: 12px;
    font-family: "Open Sans", sans-serif;
    font-weight: 400;
    color: #9a7641;
    text-align: center;
    margin-right: 10px;
    margin-top: -5px;
    margin-left: 10px;
    border: 1px solid #9a7641;
    background-color: rgba(255, 255, 255, 0);
    padding: 6px 10px 6px 8px;
    border-radius: 2px;
    float: right;
    transition: all ease-in-out 0.2s;
}
.header_bloc_process {
    height: 50px;
    width: 100%;
    background-color: #f2f2f2;
}
.header_bloc_process li {
    float: left;
    width: 33.33%;
    height: 50px;
    border-right: 3px solid #fff;
    background-color: #f2f2f2;
}
.offre_step {
    background-color: #f89a54 !important;
}
.offre_step a {
    width: 100%;
    height: 50px;
    float: left;
}
.offre_step p {
    color: #fff;
}
.step p {
    font-family: "Open Sans", sans-serif;
    font-weight: 700;
    color: #7b7b7b;
    font-size: 14px;
    display: inherit;
    margin-top: 13px;
    padding-left: 20px;
}
.offre_step_icon {
    display: inline-block;
    width: 39px;
    height: 38px;
    background-image: url(../images/sprite_2.png);
    background-repeat: no-repeat;
    background-position: -368px -689px;
    margin-top: -5px;
    margin-right: 6px;
    float: left;
}
.offre_step p span {
    color: #fff;
}
.step span {
    font-family: "Open Sans", sans-serif;
    font-weight: 700;
    color: #7b7b7b;
    font-size: 14px;
    text-align: center;
}
.offre_step .step_arrow {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background-color: #f89a54;
    border: 3px solid #fff;
    float: right;
    margin-right: -12px;
    margin-top: -20px;
    position: relative;
}
.offre_step_arrow_icon {
    display: inline-block;
    width: 10px;
    height: 13px;
    background-image: url(../images/sprite_2.png);
    background-repeat: no-repeat;
    background-position: -565px -490px;
    margin-top: 1px;
    margin-right: 6px;
    float: left;
    margin-left: 5px;
}
.panier_inactif_step_icon {
    display: inline-block;
    width: 39px;
    height: 39px;
    background-image: url(../images/sprite_2.png);
    background-repeat: no-repeat;
    background-position: -406px -727px;
    margin-top: -5px;
    margin-right: 6px;
    float: left;
}
.step_arrow_icon {
    display: inline-block;
    width: 10px;
    height: 13px;
    background-image: url(../images/sprite_2.png);
    background-repeat: no-repeat;
    background-position: -575px -490px;
    margin-top: 1px;
    margin-right: 6px;
    float: left;
    margin-left: 5px;
}
.home_icon {
    display: inline-block;
    width: 27px;
    height: 25px;
    background-image: url(../images/sprite_2.png);
    background-repeat: no-repeat;
    background-position: 0 -777px;
    position: relative;
    margin-left: 7px;
    margin-top: 14px;
    float: left;
}
.fournisseurs_icon {
    display: inline-block;
    width: 31px;
    height: 26px;
    background-image: url(../images/sprite_2.png);
    background-repeat: no-repeat;
    background-position: -28px -777px;
    position: relative;
    margin-left: 7px;
    margin-top: 14px;
    float: left;
}
.produits_icon {
    display: inline-block;
    width: 30px;
    height: 26px;
    background-image: url(../images/sprite_2.png);
    background-repeat: no-repeat;
    background-position: -60px -777px;
    position: relative;
    margin-left: 7px;
    margin-top: 14px;
    float: left;
}
.offres_icon {
    display: inline-block;
    width: 30px;
    height: 26px;
    background-image: url(../images/sprite_2.png);
    background-repeat: no-repeat;
    background-position: -90px -777px;
    position: relative;
    margin-left: 7px;
    margin-top: 14px;
    float: left;
}
.offres_icon {
    display: inline-block;
    width: 27px;
    height: 26px;
    background-image: url(../images/sprite_2.png);
    background-repeat: no-repeat;
    background-position: -90px -777px;
    position: relative;
    margin-left: 8px;
    margin-top: 10px;
    float: left;
}
.bc_icon {
    display: inline-block;
    width: 28px;
    height: 26px;
    background-image: url(../images/sprite_2.png);
    background-repeat: no-repeat;
    background-position: -118px -777px;
    position: relative;
    margin-left: 7px;
    margin-top: 10px;
    float: left;
}
.planning_icon {
    display: inline-block;
    width: 28px;
    height: 26px;
    background-image: url(../images/sprite_2.png);
    background-repeat: no-repeat;
    background-position: -145px -777px;
    position: relative;
    margin-left: 7px;
    margin-top: 14px;
    float: left;
}
.rapports_icon {
    display: inline-block;
    width: 28px;
    height: 26px;
    background-image: url(../images/sprite_2.png);
    background-repeat: no-repeat;
    background-position: -174px -777px;
    position: relative;
    margin-left: 7px;
    margin-top: 14px;
    float: left;
}
.pharmaciens_icon {
    display: inline-block;
    width: 28px;
    height: 26px;
    background-image: url(../images/sprite_2.png);
    background-repeat: no-repeat;
    background-position: -202px -777px;
    position: relative;
    margin-left: 7px;
    margin-top: 14px;
    float: left;
}
.parametres_icon {
    display: inline-block;
    width: 28px;
    height: 26px;
    background-image: url(../images/sprite_2.png);
    background-repeat: no-repeat;
    background-position: -229px -777px;
    position: relative;
    margin-left: 7px;
    margin-top: 14px;
    float: left;
}
.step_arrow {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background-color: #e4e4e4;
    border: 3px solid #fff;
    float: right;
    margin-right: -12px;
    margin-top: -20px;
    position: relative;
}
.menu_open .nav_left span {
    font-family: "Open Sans", sans-serif;
    font-size: 12px;
    color: #525252;
    font-weight: 700;
    margin-left: 10px;
    margin-top: 18px;
    display: inline-block;
}
.menu_arrow {
    display: inline-block;
    width: 8px;
    height: 12px;
    background-image: url(../images/sprite_2.png);
    background-repeat: no-repeat;
    background-position: -306px -783px;
    position: relative;
    margin-top: 20px;
    cursor: pointer;
    float: right;
    margin-right: 10px;
}
.sous_menu {
    display: none;
}
.sous_menu li {
    height: 54px;
    width: 209px;
    border-bottom: 1px solid #d7e3e2;
    background-color: #f2fafa;
    float: left;
}
.sous_menu li a {
    height: 54px;
    font-family: "Open Sans", sans-serif;
    font-size: 12px;
    font-weight: 700;
    display: inline-block;
    color: #819997 !important;
    float: left;
    padding-top: 18px;
    padding-left: 10px;
}
.bc_inactif_step_icon {
    display: inline-block;
    width: 39px;
    height: 39px;
    background-image: url(../images/sprite_2.png);
    background-repeat: no-repeat;
    background-position: -444px -727px;
    margin-top: -5px;
    margin-right: 6px;
    float: left;
}
.bloc_head_left {
    float: left;
    width: 49%;
    height: 108px;
    border-radius: 5px;
    border: 1px solid #d9d9d9;
    padding: 10px;
    margin-bottom: 0;
    margin-left: 0.6%;
    margin-top: 10px;
}
.bloc_head_left h2 {
    font-family: "Open Sans", sans-serif;
    font-weight: 700;
    color: #00beb0;
    text-align: left;
    font-size: 18px;
    margin-bottom: 10px;
}
.bloc_head_left p {
    margin-bottom: 10px;
}
.bloc_head_right {
    float: right;
    width: 49%;
    height: 108px;
    border-radius: 5px;
    border: 1px solid #d9d9d9;
    padding: 10px;
    margin-bottom: 0;
    margin-right: 0.6%;
    margin-top: 10px;
}
.bloc_head_right h2 {
    font-family: "Open Sans", sans-serif;
    font-weight: 700;
    color: #00beb0;
    text-align: left;
    font-size: 18px;
    margin-bottom: 10px;
}
.bloc_head_right p {
    margin-bottom: 10px;
}
.labo_info_view {
    height: auto !important;
}
.gamme_line span {
    font-weight: 600 !important;
}
.top_options_btns .info_icon {
    display: inline-block;
    width: 22px;
    height: 31px;
    background-image: url(../images/sprite_5.png);
    background-repeat: no-repeat;
    background-position: -200px -321px;
    margin-top: -3px;
    margin-right: -1px;
    float: left;
    margin-left: -18px;
    background-size: 259px;
}
.labo_bloc h3 {
    font-family: "Open Sans", sans-serif;
    font-weight: 700;
    text-transform: uppercase;
    font-size: 13px;
    color: #6f75a0;
    text-align: center;
    display: inherit;
}
.labo_bloc h2 {
    font-family: "Open Sans", sans-serif;
    font-weight: 700;
    text-transform: uppercase;
    font-size: 17px;
    color: #6f75a0;
    text-align: center;
    display: inherit;
}
.labo_bloc p {
    font-family: "Open Sans", sans-serif;
    font-weight: 700;
    font-size: 12px;
    color: #494949;
    text-align: center;
    display: inherit;
    margin-top: 10px;
}
.bloc_sidebar {
    padding: 10px;
}
.bloc_sidebar h3 {
    color: #fff;
    font-weight: 700;
    font-family: "Open Sans", sans-serif;
    font-size: 16px;
}
.offre_count_down {
    margin-top: 5px;
}
.offre_count_down li {
    display: inline-block;
    width: 14%;
    margin-right: 10px;
    text-align: center;
}
.offre_count_down li span {
    text-align: center;
    display: inherit;
    font-weight: 700;
    font-family: "Open Sans", sans-serif;
    font-size: 21px;
    color: #fff;
}
.offre_count_down li p {
    text-align: center;
    font-weight: 400;
    font-family: "Open Sans", sans-serif;
    font-size: 12px;
    color: #fff;
    margin-top: -5px;
}
.tab tbody tr:hover td {
    color: #fff !important;
}
.bloc_inside_compteur p {
    color: #9c9c9c;
    font-family: "Open Sans", sans-serif;
    font-size: 10px;
    font-weight: 400;
    text-align: center;
    text-transform: uppercase;
}
.offre_step_inactif a {
    width: 100%;
    height: 50px;
    float: left;
}
.offre_step_inactif_icon {
    display: inline-block;
    width: 39px;
    height: 39px;
    background-image: url(../images/sprite_2.png);
    background-repeat: no-repeat;
    background-position: -365px -727px;
    margin-top: -5px;
    margin-right: 6px;
    float: left;
}
.panier_step {
    background-color: #12b3d6 !important;
}
.panier_step a {
    width: 100%;
    height: 50px;
    float: left;
}
.panier_step_icon {
    display: inline-block;
    width: 39px;
    height: 39px;
    background-image: url(../images/sprite_2.png);
    background-repeat: no-repeat;
    background-position: -406px -688px;
    margin-top: -5px;
    margin-right: 6px;
    float: left;
}
.panier_step p span {
    color: #fff;
}
.panier_step .step_arrow {
    background-color: #12b3d6 !important;
}
.panier_step_inactif a {
    width: 100%;
    height: 50px;
    float: left;
}
.bc_step a {
    width: 100%;
    height: 50px;
    float: left;
}
.bc_step_icon {
    display: inline-block;
    width: 39px;
    height: 38px;
    background-image: url(../images/sprite_2.png);
    background-repeat: no-repeat;
    background-position: -444px -688px;
    margin-top: -5px;
    margin-right: 6px;
    float: left;
}
.bc_step {
    background-color: #00c389 !important;
}
.bc_step span {
    color: #fff;
}
.header_bloc_process li:last-child {
    float: left;
    width: 33.33%;
    height: 50px;
    border-right: none;
}
.menu_toggle_icon:hover {
    display: inline-block;
    width: 24px;
    height: 26px;
    background-image: url(../images/sprite_2.png);
    background-repeat: no-repeat;
    background-position: -283px -777px;
    position: relative;
    margin-left: 4px;
    margin-top: 1px;
}
.close_menu_icon:hover {
    display: none;
    width: 32px;
    height: 32px;
    background-image: url(../images/sprite_2.png);
    background-repeat: no-repeat;
    background-position: -292px -810px;
    position: relative;
    margin-left: 4px;
    margin-top: 34px;
    float: right;
    margin-right: -15px;
    cursor: pointer;
}
.abonnement_infos {
    padding: 15px 10px 20px 10px;
}
.abonnement_infos_left {
    display: inline-block;
    width: 64%;
    float: left;
    padding-bottom: 20px;
}
.abonnement_infos_left ul li {
    margin-bottom: 5px;
    display: block;
}
.abonnement_infos_left ul li {
    font-size: 13px;
}
.abonnement_infos_left ul li:first-child {
    font-family: "Open Sans", sans-serif;
    font-weight: 700;
    color: #00beb0;
    text-transform: uppercase;
    font-size: 20px;
}
.abonnement_infos_left strong {
    color: #ef396f;
    font-size: 14px;
}
.abonnement_infos_right {
    display: inline-block;
    width: 35%;
    float: right;
    padding-bottom: 20px;
}
.abonnement_infos_right ul {
    float: right;
}
.abonnement_infos_right .money {
    width: 45px;
    height: 45px;
    border-radius: 50%;
    background-color: #f3f3f3;
    margin-right: 6px;
    display: inline-block;
    float: left;
}
.abonnement_infos_right .money .piece {
    width: 26px;
    height: 25px;
    position: absolute;
    margin-top: 9px;
    margin-left: 9px;
    background-image: url(../images/piece.png);
}
.abonnement_infos_right .reste_montant {
    display: inline-block;
    padding: 6px 10px;
}
.abonnement_infos_right .reste_montant h1 {
    font-size: 22px;
}
.header_tabs {
    border-bottom: 3px solid #00beb0;
}
.header_tabs li {
    display: inline-block;
    width: 33%;
    text-align: center;
    cursor: pointer;
}
.header_tabs li:last-child {
    float: right;
}
.header_tabs a {
    display: block;
    width: 100%;
    font-weight: 700;
    font-size: 13px;
    padding: 12px;
}
.header_tabs .selected {
    border-top-right-radius: 3px;
    background-color: #00beb0;
}
.header_tabs .selected a {
    color: #fff;
}
.abonnement_content li a {
    width: 100%;
    display: block;
    padding: 12px 20px;
    font-size: 13px;
    border-bottom: 1px solid #f3f3f3;
    position: relative;
    z-index: 1;
}
.abonnement_content li a:not(last-child) {
    border-top: 1px solid #f3f3f3;
}
.abonnement_content > li > a > i {
    margin-right: 10px;
}
.contrat li span {
    position: absolute;
    right: 35px;
    color: #fff;
    padding: 2px 10px;
    border-radius: 3px;
    margin-top: 10px;
}
.contrat li span.actif {
    background-color: red;
}
.contrat li span.inactif {
    background-color: #00beb0;
}
.abonnement_content_text {
    padding: 10px 40px;
}
.abonnement_content_text img {
    margin-top: 20px;
}
.abonnement_content_text span.montant {
    color: #00beb0;
    background-color: #e5e5e561;
    padding: 2px 5px;
    font-weight: 600;
    margin-left: 5px;
}
.abonnement_content_text .number {
    float: left;
    background-color: #00beb0;
    border-radius: 50%;
    width: 2%;
    margin-top: 5px;
}
.abonnement_content_text .number span {
    color: #fff;
    font-weight: 700;
    margin: auto;
    display: table;
}
.abonnement_content .text {
    display: inline-block;
    width: 90%;
    margin-left: 10px;
}
.abonnement_content_text .next {
    float: left;
    padding: 50px 0;
}
.abonnement_content_text .next a {
    padding: 0 22px;
    color: #fff !important;
}
.abonnement_content .text p {
    font-size: 13px;
    line-height: 25px;
}
.abonnement_content .text ul {
    margin: 20px 0;
}
.abonnement_content .text ul li {
    margin-bottom: 10px;
    font-size: 13px;
}
.payment a span {
    position: absolute;
    right: 35px;
    color: #fff;
    padding: 3px 10px;
    background-color: #4cd700;
    border-radius: 3px;
}
.sprite-table-download-btn {
    width: 25px;
    height: 25px;
    background-image: url(../images/download.png);
    margin-left: 10px;
    display: block;
}
.sprite-table-download-btn:hover {
    background-image: url(../images/download-active.png);
}
.payment-img {
    float: right;
    margin-right: 15px;
}
.new-offers {
    background: #e03f4f;
    color: #fff;
    border-radius: 3px;
    font-size: 11px;
    padding: 0 3px;
}
.italic {
    font-style: italic;
}
.dash_left_sidebar ul li a:hover .italic {
    color: #1ebeb0;
}
.payment-failed,
.payment-success {
    width: 100%;
    padding: 15px;
}
.payment-failed .payment-failed-img,
.payment-success .payment-success-img {
    float: left;
    margin-right: 25px;
}
.payment-failed .payment-failed-img .failed,
.payment-success .payment-success-img .success {
    background-repeat: no-repeat;
    height: 95px;
    width: 80px;
    display: block;
}
.payment-success .payment-success-img .success {
    background-image: url(../images/payment_success.png);
}
.payment-failed .payment-failed-img .failed {
    background-image: url(../images/payment_failed.png);
}
.payment-failed .payment-failed-text h4,
.payment-success .payment-success-text h4 {
    font-size: 13px;
    font-weight: 700;
    margin-bottom: 5px;
}
.payment-failed .payment-failed-text p,
.payment-success .payment-success-text p {
    font-size: 12px;
    font-weight: 600;
    margin-bottom: 15px;
}
#topnav > li > a.disabled-link {
    color: #037d74;
}
