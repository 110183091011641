.BeingProcessed__container {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.BeingProcessed__container img {
    height: 190px;
    margin-bottom: 10px;
}
.BeingProcessed__container p {
    font-size: 16px;
    font-weight: bold;
    font-family: "Open Sans", sans-serif;
    display: inline-block;
    vertical-align: top;
    margin-top: 7px;
    margin-bottom: 13px;
}
