.more {
    display: none;
}
/* @media screen and (max-width: 1425px) {
    .nav-header > ul > div:nth-last-child(2) {
        display: none;
    }
    .nav-header > ul > div:nth-last-child(3) {
        display: none;
    }
    .others {
        display: inline-block;
    }
}

@media screen and (max-width: 1334px) {
    .nav-header > ul > div:nth-last-child(4) {
        display: none;
    }
}

@media screen and (max-width: 1270px) {
    .nav-header > ul > div:nth-last-child(5) {
        display: none;
    }
}

@media screen and (max-width: 1193px) {
    .nav-header > ul > div:nth-last-child(6) {
        display: none;
    }
}

@media screen and (max-width: 1105px) {
    .nav-header > ul > div:nth-last-child(7) {
        display: none;
    }
}

@media screen and (max-width: 1021px) {
    .nav-header > ul > div:nth-last-child(8) {
        display: none;
    }
}

@media screen and (max-width: 946px) {
    .nav-header > ul > div:nth-last-child(9) {
        display: none;
    }
}

@media screen and (max-width: 884px) {
    .nav-header > ul > div:nth-last-child(10) {
        display: none;
    }
}
@media screen and (max-width: 787px) {
    .nav-header > ul > div:nth-last-child(11) {
        display: none;
    }
}
@media screen and (max-width: 712px) {
    .nav-header > ul > div:nth-last-child(12) {
        display: none;
    }
} */

.fixed_nav{
    position: fixed !important;
    left: 0 !important;
    right: 0 !important;
    top: 0 !important;
    z-index: 9999999 !important;
}