.PlanningModal_container {
    width: 450px;
}
.PlanningModal_header {
    padding: 15px 10px;
    background-color: #00beb0;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.PlanningModal_header h1 {
    color: #fff;
}
.PlanningModal_header .close {
    border: 0;
    background-color: transparent;
    padding: 5px;
    cursor: pointer;
}
.PlanningModal_body {
    padding: 25px 10px;
    background-color: #fff;
}

.PlanningModal_body a {
    display: flex;
    width: 100%;
    background-color: #fbfbfb;
    justify-content: space-between;
    border: 0;
    border-radius: 3px;
    color: #00beb0;
    padding: 20px;
    font: 14px "Open Sans", sans-serif;
    font-weight: 600;
    cursor: pointer;
}
.PlanningModal_body > :first-child {
    margin-bottom: 8px;
}

.PlanningModal_footer {
    padding: 10px 6px;
    background-color: #eaeff2;
    display: flex;
    justify-content: flex-end;
}
.PlanningModal_footer button {
    background-color: #f4ac2e;
    padding: 5px 15px;
    border: 1px solid #fff;
    border-radius: 3px;
    color: #fff;
    font: 12px "Open Sans", sans-serif;
    font-weight: 600;
    cursor: pointer;
}
